import { Button, Grid, styled } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

import { Card, RowDetail } from 'src/components/common';
import {
  AutoCompleteField,
  InputField,
  SelectField,
} from 'src/components/form';
import useForm, { FormValue } from './useForm';
import history from 'src/libs/history';
import { searchHoods } from 'src/useCase/hoodList';
import { useAppDispatch } from 'src/store';
import {
  optionType,
  optionCategory,
} from '../../../FacebookCreate/Main/Form/useForm';
import ModeIcon from '@mui/icons-material/Mode';

type Props = {
  entity: Response.FacebookEntity | null;
  valueHoods: { label: string; id: string }[];
  loadingHoods: boolean;
  loading: boolean;
};

const FormCreate: React.FC<Props> = (props) => {
  const { entity, valueHoods, loadingHoods } = props;
  const { t } = useTranslation();

  const { formInfo, onSubmit } = useForm({
    entity,
  });

  const { control, handleSubmit, formState } = formInfo;
  const [openHood, setOpenHood] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  return (
    <Card>
      <Wrapper container spacing={0}>
        <RowDetail
          title={'Page Name'}
          value={
            <InputField<FormValue> controller={{ control, name: 'pageName' }} />
          }
        />
        <RowDetail
          title={'Page Id'}
          value={
            <InputField<FormValue> controller={{ control, name: 'pageId' }} />
          }
        />
        <RowDetail
          title={'Page Access Token'}
          value={
            <InputField<FormValue>
              controller={{ control, name: 'pageAccessToken' }}
            />
          }
        />
        <RowDetail
          title={'Type'}
          value={
            <SelectField<FormValue>
              controller={{ control, name: 'type' }}
              options={optionType}
            />
          }
        />
        <RowDetail
          title={'Hoods'}
          value={
            <AutoCompleteField<FormValue>
              controller={{ control, name: 'hoodId' }}
              options={valueHoods || []}
              onOpen={() => setOpenHood(!openHood)}
              callbackOnchange={(v) => {
                dispatch(searchHoods(v));
              }}
              onClose={() => setOpenHood(!openHood)}
              loading={loadingHoods}
              size="small"
            />
          }
        />
        <RowDetail
          title={'User ID'}
          value={
            <InputField<FormValue> controller={{ control, name: 'userId' }} />
          }
        />
        <RowDetail
          title={'Category'}
          value={
            <SelectField<FormValue>
              controller={{ control, name: 'category' }}
              options={optionCategory}
            />
          }
        />
        <Grid container style={{ marginTop: 20 }} spacing={2}>
          <Grid item>
            <LoadingButton
              type="button"
              variant="contained"
              size="small"
              disabled={!formState.isDirty}
              loading={formState.isSubmitting}
              loadingPosition="start"
              startIcon={<ModeIcon />}
              onClick={handleSubmit(onSubmit)}
            >
              {t('button.update')}
            </LoadingButton>
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" onClick={history.back}>
              Back
            </Button>
          </Grid>
        </Grid>
      </Wrapper>
    </Card>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormCreate;
