import React from 'react';

import Container from 'src/containers/UserUpdate';
import { ContentWrapper } from 'src/components/common';
import { t } from 'src/libs/i18n';

const Page: React.FC = () => {
  return (
    <ContentWrapper title={t('title.user_update')}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/user_update/:id' as const;
export default Page;
