import React from 'react';

import Detail from './Detail';

type Props = {
  entity: Response.PostEntity | null;
  loading: boolean;
};

const Main: React.FC<Props> = (props) => {
  return <Detail {...props} />;
};

export default Main;
