import * as Yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { updateEvent } from 'src/useCase/eventDetail';
import { useAppDispatch } from 'src/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);
dayjs.extend(utc);

export type FormValues = {
  name: string;
  url: string;
  category: string;
  icon: string;
  city: string;
  country: string;
  state: string;
  latitude: string;
  longitude: string;
  startDate: string;
  endDate: string;
  status: string;
  hoodId: { label: string; id: string } | any;
  image: string;
  description: string;
};

const useFormDetail = (data: Response.EventDetailResponse | null) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validated.required', { name: 'Link Name' })),
    category: Yup.string().required(
      t('validated.required', { name: 'Category' }),
    ),
    url: Yup.string()
      .required(t('validated.required', { name: 'Link URL' }))
      .url(),
    status: Yup.string().required(t('validated.required', { name: 'Status' })),
    latitude: Yup.string().required(
      t('validated.required', { name: 'latitude' }),
    ),
    longitude: Yup.string().required(
      t('validated.required', { name: 'longitude' }),
    ),
    startDate: Yup.date()
      .required()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    endDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('startDate', (startDate, schema) => {
        if (!startDate) {
          return;
        }
        return schema.min(startDate, t('validated.from_to_date'));
      }),
  });

  const form = useForm<FormValues>({
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (values: FormValues) => {
    const newHoodId = form.watch('hoodId') === null ? '' : values.hoodId.id;
    await dispatch(
      updateEvent({
        id: data?.id as string,
        params: {
          name: values.name,
          url: values.url,
          category: values.category,
          icon: form.watch('icon'),
          image: form.watch('image'),
          status: values.status,
          hoodId: newHoodId,
          latitude: values.latitude as string,
          longitude: values.longitude as string,
          state: values.state,
          startDate: values.startDate
            ? dayjs(values.startDate).tz('UTC', true).toISOString()
            : '',
          endDate: values.endDate
            ? dayjs(values.endDate).tz('UTC', true).toISOString()
            : '',
          city: values.city,
          country: values.country,
          description: values.description,
        },
      }),
    );
  };

  useEffect(() => {
    const latitude =
      data?.location !== null ? data?.location.coordinates?.[1] : '';
    const longitude =
      data?.location !== null ? data?.location.coordinates?.[0] : '';

    const labelHood = data?.hood
      ? {
          label: data?.hood?.name,
          id: data?.hoodId,
        }
      : '';

    form.reset({
      name: data?.name || '',
      url: data?.url || '',
      city: data?.city || '',
      country: data?.country || '',
      state: data?.state || '',
      category: data?.category,
      icon: data?.icon || '',
      status: data?.status,
      hoodId: labelHood || '',
      startDate: data?.startDate || '',
      endDate: data?.endDate || '',
      latitude: latitude as string,
      longitude: longitude as string,
      image: data?.image || '',
      description: data?.description || '',
    });
  }, [data]);

  return { form, onSubmit };
};

export default useFormDetail;
