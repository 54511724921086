import HTTPRequest from '../index';
import { formatDate } from 'src/utils/helpers';
import _ from 'lodash';

const apiPath = '/v1/banners';

export interface GetBannerDetail {
  id: string;
}

export interface CreateBanner {
  bannerId: string;
  bannerInternalFunctionCode: string;
  language: string;
  bannerText: string;
  url: string;
  dateFrom: string;
  dateTo: string;
  hoodId: string;
  city: string;
  country: string;
  state: string;
  hoodCategory: string;
  bannerMobileLeaderBoard: string;
  bannerWebLeaderBoard: string;
  status: string;
  userId: string;
  textColor: string;
  tags?: string[];
}

export interface UpdateBanner extends Omit<CreateBanner, 'hoodId'> {
  id: string;
  hoodId: { label: string; id: string };
}

class BannerRequest extends HTTPRequest {
  getList(params: Payload.AdsList) {
    return this.get<any>(apiPath, { params });
  }

  create(data: CreateBanner) {
    let formData = new FormData();
    formData.append('bannerMobileLeaderBoard', data.bannerMobileLeaderBoard);
    formData.append('bannerWebLeaderBoard', data.bannerWebLeaderBoard);
    formData.append('bannerId', data.bannerId);
    formData.append(
      'bannerInternalFunctionCode',
      data.bannerInternalFunctionCode,
    );
    formData.append('language', data.language);
    formData.append('bannerText', data.bannerText);
    formData.append('url', data.url);
    formData.append('dateTo', formatDate(data.dateTo));
    formData.append('dateFrom', formatDate(data.dateFrom));
    formData.append('hoodId', data.hoodId);
    formData.append('city', data.city);
    formData.append('country', data.country);
    formData.append('state', data.state);
    formData.append('hoodCategory', data.hoodCategory);
    formData.append('status', data.status);
    formData.append('userId', data.userId);
    formData.append('textColor', data.textColor);
    if (!_.isEmpty(data.tags)) {
      // @ts-ignore
      data.tags.map((item) => {
        formData.append('tags[]', item);
      });
    }
    return this.post(`${apiPath}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  delete(id: string) {
    return this.delete(`${apiPath}/${id}`);
  }

  getDetailBanner(id: string) {
    return this.get<any>(`${apiPath}/${id}`);
  }

  update(data: any) {
    let formData = new FormData();
    formData.append('bannerMobileLeaderBoard', data.bannerMobileLeaderBoard);
    formData.append('bannerWebLeaderBoard', data.bannerWebLeaderBoard);
    formData.append('bannerId', data.bannerId);
    formData.append(
      'bannerInternalFunctionCode',
      data.bannerInternalFunctionCode,
    );
    formData.append('language', data.language);
    formData.append('bannerText', data.bannerText);
    formData.append('url', data.url);
    formData.append('dateTo', formatDate(data.dateTo));
    formData.append('dateFrom', formatDate(data.dateFrom));
    if (data?.hoodId?.id) {
      formData.append('hoodId', _.get(data, 'hoodId.id', null));
    }
    if (data?.userId?.id) {
      formData.append('userId', _.get(data, 'userId.id', null));
    }
    formData.append('city', data.city);
    formData.append('country', data.country);
    formData.append('state', data.state);
    formData.append('hoodCategory', data.hoodCategory);
    formData.append('status', data.status);
    formData.append('textColor', data.textColor === null ? '' : data.textColor);
    if (!_.isEmpty(data.tags)) {
      // @ts-ignore
      data.tags.map((item) => {
        formData.append('tags[]', item);
      });
    }
    return this.put(`${apiPath}/${data.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  reportAnalytics(params: any) {
    return this.get(`${apiPath}/${params.type}/analytics`, { params });
  }
}

export default new BannerRequest();
