export * from './common';

export enum USER_SEARCH_TYPE {
  USER_NAME = 'name',
  PHONE = 'phone',
  EMAIL = 'email',
}

export enum USER_SEARCH_PERIOD {
  CREATED_DATE = 'created_date',
  QUITED_DATE = 'quited_date',
}

export enum USER_GENDER {
  MALE = 'male',
  FEMALE = 'female',
}
