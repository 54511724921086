import React from 'react';
import styled from 'styled-components';
import Table from './Table';

type MainProps = {
  loading: boolean;
  entities: Response.EventListEntity[];
};

const Main: React.FC<MainProps> = (props) => {
  return (
    <React.Fragment>
      <MarginBottom />
      <Table {...props} />
    </React.Fragment>
  );
};

const MarginBottom = styled.div`
  margin-bottom: 24px;
`;

export default Main;
