import React, { useMemo } from 'react';

import FormUpdate from './Form';
import { useAppSelector } from 'src/store';

type Props = {
  entity: Response.FacebookEntity | null;
  loading: boolean;
};

const Main: React.FC<Props> = (props) => {
  const valueHood = useAppSelector((state) => state.domain.hood.hoods);

  const getHoods = useMemo(() => {
    return valueHood?.entities.map((v) => {
      return {
        label: String(v.name),
        id: String(v.id),
      };
    });
  }, [valueHood]);

  return (
    <React.Fragment>
      <FormUpdate
        {...props}
        valueHoods={getHoods}
        loadingHoods={valueHood.loading}
      />
    </React.Fragment>
  );
};

export default Main;
