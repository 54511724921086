import React from 'react';

import FormCreate, { Props as PropsDetailForm } from './Form';
import { useAppSelector } from '../../../store';

type Props = {
  entities: Response.HoodEntity[];
  loading: boolean;
};

const Main: React.FC<Props> = ({ entities, loading }) => {
  const dataDetail = useAppSelector((state) => state.domain.event.detail);

  const props: PropsDetailForm = {
    hoods: entities,
    loadingHoods: loading,
    loading: dataDetail.loading,
    entity: dataDetail.entity,
  };

  return (
    <React.Fragment>
      <FormCreate {...props} />
    </React.Fragment>
  );
};

export default Main;
