import qs from 'query-string';

import { useAppSelector } from 'src/store';
import useQueryUrl from './useQueryUrl';
import { PER } from 'src/constants/app';
import history from 'src/libs/history';

type UseTable = {
  hasSelect: boolean;
};

const useTable = <T extends { id: string | number }>(
  { hasSelect }: UseTable = { hasSelect: true },
) => {
  const query = useQueryUrl<App.SortInfo<T> & SearchParams.RequestParams>();
  const pagination = useAppSelector((state) => state.ui.app.pagination);
  const pageSize = query.take ? Number(query.take) : PER.DEFAULT;
  const page = query.page ? Number(query.page) : 1;

  const onPageChange = (page: number) => {
    history.push({
      search: qs.stringify({
        ...query,
        page: page + 1,
      }),
    });
  };

  const onPageSizeChange = (pageSize: number) => {
    history.push({
      search: qs.stringify({ ...query, take: String(pageSize), page: 1 }),
    });
  };

  const tableProps = {
    onPageChange,
    onPageSizeChange,
    pageSize,
    rowCount: pagination?.itemCount,
    rowsPerPageOptions: [10, 50, 100],
    page: page - 1,
  };

  return {
    tableProps,
    pagination,
  };
};

export default useTable;
