import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { useAppDispatch } from 'src/store';
import { createResource } from 'src/useCase/customMaps';
import history from 'src/libs/history';
import { PATH } from 'src/pages/customMaps/CustomMapsList';

export type FormValue = {
  name: string;
  userId: string;
  allowOtherUsers: boolean;
  showOnlyToLocal: boolean;
  marker: string;
  hoodId: string;
  city: string;
  country: string;
  state: string;
  status: string;
};

const useForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const defaultValue: FormValue = {
    name: '',
    userId: '',
    allowOtherUsers: false,
    showOnlyToLocal: false,
    marker: '',
    hoodId: '',
    city: '',
    country: '',
    state: '',
    status: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validated.required', { name: 'Maps name' })),
    status: Yup.mixed().required(t('validated.required', { name: 'Status' })),
  });

  const formik = useFormik<FormValue>({
    initialValues: {
      ...defaultValue,
    },
    validationSchema,
    async onSubmit(values) {
      await dispatch(createResource({ ...values }));
      history.push(PATH);
    },
    validateOnChange: true,
  });

  return { formik };
};

export default useForm;
