import React, { useEffect } from 'react';

import Container from 'src/containers/CustomMaps/Detail';
import { ContentWrapper } from 'src/components/common';
import { useAppDispatch } from 'src/store';
import { useParams } from 'react-router-dom';
import { fetchDetailCustomMaps } from 'src/useCase/customMaps';
import useQueryUrl from 'src/hooks/useQueryUrl';

const Page: React.FC = () => {
  const dispatch = useAppDispatch();
  const query = useQueryUrl();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchDetailCustomMaps(String(id)));
  }, [query]);

  return (
    <ContentWrapper title={'Custom Maps Detail'}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/custom-maps/:id' as const;
export default Page;
