import CustomMapsList, {
  PATH as CUSTOM_MAPS_LIST_PATH,
} from './CustomMapsList';
import CustomMapsCreate, {
  PATH as CUSTOM_MAPS_CREATE_PATH,
} from './CustomMapsCreate';
import CustomMapsDetail, {
  PATH as CUSTOM_MAPS_DETAIL_PATH,
} from './CustomMapsDetail';

export default { CustomMapsList, CustomMapsCreate, CustomMapsDetail };
export const PATH = {
  CUSTOM_MAPS_LIST_PATH,
  CUSTOM_MAPS_CREATE_PATH,
  CUSTOM_MAPS_DETAIL_PATH,
};
