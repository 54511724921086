import { Box, Card, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';

type Props = {
  color: string;
  icon: string;
  title: string;
  total: number | undefined;
};

const AppWidgetSummary: React.FC<PropsWithChildren<Props>> = ({
  title,
  total,
  icon,
  color = 'primary',
  ...other
}) => {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
      }}
      {...other}
    >
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(
              theme.palette[color].dark,
              0,
            )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
        }}
      >
        <Box component={Icon} icon={icon} width={24} height={24} />
      </IconWrapperStyle>

      <Typography variant="h3">{total}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
};

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

export default AppWidgetSummary;
