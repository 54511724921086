import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import eventDetailActions from 'src/store/domain/event/detail';
import eventRequest, { UpdateEventPayload } from 'src/api/requests/event';

export const fetchResources = createAsyncThunk<void, string>(
  'get_event_detail',
  async (id, { dispatch }) => {
    try {
      dispatch(eventDetailActions.updateLoading(true));

      const res = await eventRequest.getDetailEvent(id);

      if (res.data) {
        await dispatch(eventDetailActions.updateEntity(res.data));
      }

      dispatch(eventDetailActions.updateLoading(false));
    } catch (err) {
      dispatch(eventDetailActions.updateLoading(false));
    }
  },
);

export const updateEvent = createAsyncThunk<void, UpdateEventPayload>(
  'update_event',
  async (data, { dispatch }) => {
    try {
      const res = await eventRequest.updateEvent(data);
      if (res.status != 200) {
        toast.error('There was an error updating');
      } else {
        toast.success('Successfully!');
        await dispatch(fetchResources(data.id));
      }
    } catch (err) {
      toast.error('There was an error updating');
    }
  },
);
