import React, { useEffect, useMemo, useState } from 'react';

import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from '@react-google-maps/api';
import _ from 'lodash';

const containerStyle = {
  width: '100%',
  height: '400px',
  margin: '0 auto',
};

interface ShareMapMapProps {
  apiKey: string;
  location: any;
}

const Map = ({ apiKey, location }: ShareMapMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
    libraries: ['places'],
  });

  const [marker, setMarkerLocation] = useState<{ lat: number; lng: number }>(
    location,
  );

  const [map, setMap] = useState<any>(null);

  const onMapLoad = (map) => {
    setMap(map);
  };

  useEffect(() => {
    if (location.lat && location.lng) {
      setMarkerLocation(location);
    }
  }, [location]);
  const isMarker = useMemo(() => {
    return marker.lat && marker.lng;
  }, [marker]);
  if (!isLoaded) {
    return null;
  }
  return (
    <GoogleMap
      center={marker}
      onLoad={onMapLoad}
      options={{
        disableDefaultUI: false,
      }}
      mapContainerStyle={containerStyle}
      zoom={15}
      onClick={(e) => {
        // @ts-ignore
        setMarkerLocation(e?.latLng?.toJSON());
      }}
    >
      {!_.isEmpty(marker) && (
        <Marker
          position={marker}
          icon={{
            url: '/maker.png',
            scaledSize: new window.google.maps.Size(80, 80),
          }}
        />
      )}
    </GoogleMap>
  );
};

export default Map;
