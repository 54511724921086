import HTTPRequest from '../index';
import { formatDate } from 'src/utils/helpers';
import _ from 'lodash';

const apiPath = '/v1/activities';

export interface GetActivityDetail {
  id: string;
}

export interface CreateActivity {
  headline: string;
  category: string;
  photo: string;
  hoodId: string;
  time: string;
  place: string;
  status: string;
  latitude: string;
  longitude: string;
  userId: string;
  contact: string;
  webLink: string;
  moreInfo: string;
  expirationDate: string;
  registerUrl: string;
}

export interface UpdateActivity extends Omit<CreateActivity, 'hoodId'> {
  id: string;
  hoodId: { label: string; id: string };
}

class ActivityRequest extends HTTPRequest {
  getList(params: Payload.AdsList) {
    return this.get<any>(apiPath, { params });
  }

  create(data: CreateActivity) {
    let formData = new FormData();
    formData.append('photo', data.photo);
    formData.append('headline', data.headline);
    formData.append('category', data.category);
    formData.append('hoodId', data.hoodId);
    formData.append('time', data.time);
    formData.append('place', data.place);
    formData.append('status', data.status);
    formData.append('latitude', data.latitude);
    formData.append('longitude', data.longitude);
    formData.append('userId', data.userId);
    formData.append('contact', data.contact);
    formData.append('webLink', data.webLink);
    formData.append('moreInfo', data.moreInfo);
    if (data.expirationDate) {
      formData.append('expirationDate', formatDate(data.expirationDate));
    }
    if (data.registerUrl) {
      formData.append('registerUrl', formatDate(data.registerUrl));
    }
    return this.post(`${apiPath}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deleteActivity(id: string) {
    return this.delete(`${apiPath}/${id}`);
  }

  getDetailActivity(id: string) {
    return this.get<Response.ActivityDetailEntity>(`${apiPath}/${id}`);
  }

  update(data: any) {
    let formData = new FormData();
    if (data?.photo) {
      formData.append('photo', data.photo);
    }
    formData.append('headline', data.headline);
    formData.append('category', data.category);
    if (data?.hoodId?.id) {
      formData.append('hoodId', _.get(data, 'hoodId.id', null));
    }
    if (data?.userId?.id) {
      formData.append('userId', _.get(data, 'userId.id', null));
    }
    formData.append('time', data.time);
    formData.append('place', data.place);
    formData.append('status', data.status);
    formData.append('latitude', data.latitude);
    formData.append('longitude', data.longitude);
    formData.append('contact', data.contact);
    formData.append('webLink', data.webLink);
    formData.append('moreInfo', data.moreInfo);
    formData.append('registerUrl', data.registerUrl);
    formData.append(
      'expirationDate',
      data.expirationDate
        ? formatDate(data.expirationDate.$d ?? data.expirationDate)
        : '',
    );
    return this.put(`${apiPath}/${data.id}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getListSearchHistory(params: any) {
    return this.get<any>(`${apiPath}/search-history`, { params });
  }
}

export default new ActivityRequest();
