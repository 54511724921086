import {
  Grid,
  styled,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Switch,
} from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { Card } from 'src/components/common';
import useForm, { FormValues } from './useForm';
import history from 'src/libs/history';
import {
  AutoCompleteField,
  DatePickerField,
  InputField,
} from 'src/components/form';
import SelectField from 'src/components/form/SelectField';
import { useAppDispatch, useAppSelector } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';
import { searchUsers } from 'src/useCase/userList';
import { PhotoCamera } from '@mui/icons-material';

type Props = {
  hoods: Response.HoodEntity[];
  users: Response.UserEntity[];
  entity: any | null;
  loading: boolean;
};

const FormDetail: React.FC<Props> = (props) => {
  const { hoods, users, entity, loading } = props;
  const { form, submitForm } = useForm(entity, hoods, users);
  const { control, formState, setValue } = form;
  const { t } = useTranslation();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const valueHood = useAppSelector((state) => state.domain.hood.hoods);
  const valueUser = useAppSelector((state) => state.domain.user.list);

  const [refMarker, setRefMarker] = React.useState<File | null>(null);
  useEffect(() => {
    if (entity) {
      (async () => {
        if (entity.hood) {
          await dispatch(searchHoods(entity?.hood?.name));
        } else {
          await dispatch(searchHoods(''));
        }
        if (entity.user) {
          await dispatch(searchUsers(entity.user?.nick_name));
        } else {
          await dispatch(searchUsers(''));
        }
        // @ts-ignore
      })();
    }
  }, [entity]);

  const getHoods = useMemo(() => {
    return valueHood.entities.map((v) => {
      return {
        label: String(v.name),
        id: String(v.id),
      };
    });
  }, [hoods]);

  const getUsers = useMemo(() => {
    return valueUser.entities.map((v) => {
      return {
        label: String(v.nickName),
        id: String(v.id),
      };
    });
  }, [users]);

  const status = [
    {
      value: 'pending_approval',
      label: 'Pending approval',
    },
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'paused',
      label: 'Paused',
    },
    {
      value: 'archived',
      label: 'Archived',
    },
  ];

  return (
    <Wrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={8}>
              <InputField<FormValues>
                controller={{ control, name: 'name' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.name,
                }}
                size="medium"
                label={'Map name'}
              />
            </Grid>
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="marker"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      setValue('marker', event.currentTarget.files[0], {
                        shouldDirty: true,
                      });
                      setRefMarker(event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              <img
                width={100}
                height="auto"
                src={
                  refMarker
                    ? URL.createObjectURL(refMarker)
                    : entity?.marker
                    ? `${process.env.REACT_APP_MEDIA_URL}${entity?.marker}`
                    : 'https://placehold.jp/100x100.png'
                }
                loading="lazy"
                onError={(e) => {
                  if (e.currentTarget.src) {
                    e.currentTarget.title = 'no image';
                  }
                }}
              />
            </Grid>

            <Grid item xs={8}>
              <FormControlLabel
                name="allowOtherUsers"
                control={
                  <Switch
                    size="medium"
                    defaultChecked={entity?.allowOtherUsers}
                  />
                }
                onChange={(e, checked) => {
                  setValue('allowOtherUsers', checked);
                }}
                label="Allow Other Users"
              />
            </Grid>

            <Grid item xs={8}>
              <FormControlLabel
                name="showOnlyToLocal"
                control={
                  <Switch
                    size="medium"
                    defaultChecked={entity?.showOnlyToLocal}
                  />
                }
                onChange={(e, checked) => {
                  setValue('showOnlyToLocal', checked);
                }}
                label="Show Only To Local"
              />
            </Grid>
            <Grid item xs={5}>
              <AutoCompleteField<FormValues>
                controller={{ control, name: 'userId' }}
                options={getUsers || []}
                onOpen={() => setOpenHood(!openUser)}
                onClose={() => setOpenHood(!openUser)}
                callbackOnchange={(v) => {
                  dispatch(searchUsers(v));
                }}
                loading={valueUser.loading}
                label={t('banner_fields.userId')}
                size="medium"
              />
            </Grid>
            <Grid item xs={8}>
              <AutoCompleteField<FormValues>
                controller={{ control, name: 'hoodId' }}
                options={getHoods || []}
                onOpen={() => setOpenUser(!openHood)}
                onClose={() => setOpenUser(!openHood)}
                loading={valueHood.loading}
                callbackOnchange={(v) => {
                  dispatch(searchHoods(v));
                }}
                label={t('ads_fields.hood')}
                size="medium"
              />
            </Grid>
            <Grid item xs={8}>
              <InputField<FormValues>
                controller={{ control, name: 'city' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.city,
                }}
                label={t('ads_fields.city')}
                size="medium"
              />
            </Grid>
            <Grid item xs={8}>
              <InputField<FormValues>
                controller={{ control, name: 'country' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.country,
                }}
                label={t('ads_fields.country')}
                size="medium"
              />
            </Grid>
            <Grid item xs={8}>
              <InputField<FormValues>
                controller={{ control, name: 'state' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.state || '',
                }}
                label={t('ads_fields.state')}
                size="medium"
              />
            </Grid>
            <Grid item xs={8}>
              <SelectField<FormValues>
                controller={{ control, name: 'status' }}
                options={status}
                selectProps={{
                  defaultValue: entity?.status,
                }}
                label={t('ads_fields.status')}
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="button"
                variant="contained"
                size="small"
                // disabled={!formState.isDirty}
                style={{ marginTop: 22 }}
                loading={formState.isSubmitting}
                loadingPosition="start"
                startIcon={<ModeIcon />}
                onClick={submitForm}
              >
                {t('button.update')}
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormDetail;
