import { createAsyncThunk } from '@reduxjs/toolkit';

import adsDetailActions from 'src/store/domain/ads/detail';
import adsRequest, { UpdateAds } from 'src/api/requests/ads';
import { toast } from 'react-toastify';

export const fetchResources = createAsyncThunk<void, string>(
  'get_ads_detail',
  async (id, { dispatch }) => {
    try {
      dispatch(adsDetailActions.updateLoading(true));

      const res = await adsRequest.getDetailAds(id);

      if (res.data) {
        await dispatch(adsDetailActions.updateEntity(res.data));
      }

      dispatch(adsDetailActions.updateLoading(false));
    } catch (err) {
      dispatch(adsDetailActions.updateLoading(false));
    }
  },
);

export const updateAds = createAsyncThunk<void, UpdateAds>(
  'update_ads',
  async (values, { dispatch }) => {
    try {
      const res = await adsRequest.update(values);
      if (res.status != 200) {
        toast.error('There was an error updating');
      } else {
        toast.success('Successfully!');
        await dispatch(fetchResources(values.id));
      }
    } catch (err) {
      console.log(err);
      toast.error('There was an error updating');
    }
  },
);
