import { createAsyncThunk } from '@reduxjs/toolkit';

import activityDetailActions from 'src/store/domain/activity/detail';
import activityRequest, { UpdateActivity } from 'src/api/requests/activity';
import { toast } from 'react-toastify';

export const fetchResources = createAsyncThunk<void, string>(
  'get_activity_detail',
  async (id, { dispatch }) => {
    try {
      dispatch(activityDetailActions.updateLoading(true));

      const res = await activityRequest.getDetailActivity(id);

      if (res.data) {
        await dispatch(activityDetailActions.updateEntity(res.data));
      }

      dispatch(activityDetailActions.updateLoading(false));
    } catch (err) {
      dispatch(activityDetailActions.updateLoading(false));
    }
  },
);

export const updateActivity = createAsyncThunk<void, UpdateActivity>(
  'update_activity',
  async (values, { dispatch }) => {
    try {
      const res = await activityRequest.update(values);
      if (res.status != 200) {
        toast.error('There was an error updating');
      } else {
        toast.success('Successfully!');
        await dispatch(fetchResources(values.id));
      }
    } catch (err) {
      console.log(err);
      toast.error('There was an error updating');
    }
  },
);
