import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import commentActions from 'src/store/domain/comment/list';
import commentRequest from 'src/api/requests/comment';
import appActions from 'src/store/ui/app';
import { GetPostDetail } from '../api/requests/post';

interface FetchResources extends SearchParams.Comment {}

const getParams = (args: FetchResources) => {
  const { take, page } = args;

  const params: Payload.CommentList = {
    take,
    page,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, FetchResources>(
  'get_comment_list',
  async (args, { dispatch }) => {
    try {
      dispatch(commentActions.updateLoading(true));

      const params = getParams(args);
      const res = await commentRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(commentActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(commentActions.updateLoading(false));
    } catch (err) {
      dispatch(commentActions.updateLoading(false));
    }
  },
);

export const deleteComment = createAsyncThunk<void, GetPostDetail>(
  'delete_comment',
  async (payload, { dispatch }) => {
    try {
      dispatch(commentActions.updateLoading(true));

      await commentRequest.deleteComment(payload);
      dispatch(commentActions.updateLoading(false));
    } catch (err) {
      dispatch(commentActions.updateLoading(false));
    }
  },
);
