import React from 'react';

import { PATH } from 'src/pages/activity';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';

const Route: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH.ACTIVITY_LIST,
        element: loadable(() => import('src/pages/activity/ActivityList')),
      },
      {
        path: PATH.ACTIVITY_CREATE,
        element: loadable(() => import('src/pages/activity/ActivityCreate')),
      },
      {
        path: PATH.ACTIVITY_DETAIL,
        element: loadable(() => import('src/pages/activity/ActivityDetail')),
      },
      {
        path: PATH.ACTIVITY_SEARCH_LIST,
        element: loadable(() => import('src/pages/activity/SearchList')),
      },
    ],
  },
];

export default Route;
