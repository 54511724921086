import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingSuspense: React.FC = () => {
  return (
    <div className="content_wrapper">
      <div className="content_wrapper_loading">
        <CircularProgress />
      </div>
    </div>
  );
};

export default LoadingSuspense;
