import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import statisticsDetailActions from 'src/store/domain/statistics/detail';
import statisticsHoodActions from 'src/store/domain/statistics/hood';
import statisticsRequest from 'src/api/requests/statistics';
import moment from 'moment';

interface FetchResources extends SearchParams.UserAnalytics {}

const getParams = (args: FetchResources) => {
  const { startDate, endDate, filter } = args;

  const params: Payload.UserAnalytics = {
    startDate,
    endDate,
    filter,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void>(
  'get_detail_statistics',
  async (args, { dispatch }) => {
    try {
      dispatch(statisticsDetailActions.updateLoading(true));

      const res = await statisticsRequest.getStatistics();

      const { data } = res;

      if (data) {
        dispatch(statisticsDetailActions.updateEntity(data));
      }

      dispatch(statisticsDetailActions.updateLoading(false));
    } catch (err) {
      dispatch(statisticsDetailActions.updateLoading(false));
    }
  },
);

export const fetchUserAnalyticsResources = createAsyncThunk<
  void,
  FetchResources
>('get_detail_user_analytics', async (args, { dispatch }) => {
  try {
    const params = getParams(args);
    const userAnalytics = await statisticsRequest.getUserAnalytics(params);

    const userAnalyticsResult = userAnalytics.data;

    if (userAnalyticsResult) {
      dispatch(
        statisticsDetailActions.updateUserAnalyticsEntity(userAnalyticsResult),
      );
    }

    dispatch(statisticsDetailActions.updateLoading(false));
  } catch (err) {
    dispatch(statisticsDetailActions.updateLoading(false));
  }
});

export const fetchHoodAnalyticsResources = createAsyncThunk<
  void,
  FetchResources
>('get_hood_analytics', async (args, { dispatch }) => {
  try {
    const params = getParams(args);
    const hoodAnalytics = await statisticsRequest.getHoodAnalytics(params);

    const hoodAnalyticsResult = hoodAnalytics.data;

    if (hoodAnalyticsResult) {
      dispatch(
        statisticsHoodActions.updateHoodAnalyticsEntity(hoodAnalyticsResult),
      );
    }

    const hoodAnalyticsDetail = await statisticsRequest.getHoodDetailAnalytics(
      params,
    );
    const hoodAnalyticsDetailResult = hoodAnalyticsDetail.data;
    if (hoodAnalyticsDetailResult) {
      if (params.filter === 'day') {
        const data = _.groupBy(hoodAnalyticsDetailResult, (item) =>
          moment(item.date).format('YYYY/MM/DD'),
        );

        dispatch(statisticsHoodActions.updateHoodAnalyticsDetailEntity(data));
      }

      if (params.filter === 'week') {
        const data = _.groupBy(
          hoodAnalyticsDetailResult,
          (item) => `${item.week} week/${item.year}`,
        );

        dispatch(statisticsHoodActions.updateHoodAnalyticsDetailEntity(data));
      }

      if (params.filter === 'month') {
        const data = _.groupBy(
          hoodAnalyticsDetailResult,
          (item) => `${item.month} month/${item.year}`,
        );

        dispatch(statisticsHoodActions.updateHoodAnalyticsDetailEntity(data));
      }
    }

    dispatch(statisticsHoodActions.updateLoading(false));
  } catch (err) {
    dispatch(statisticsHoodActions.updateLoading(false));
  }
});
