import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar } from '@mui/material';

import { Card, RowDetail } from 'src/components/common';

type Props = {
  entity: Response.UserEntity | null;
  loading: boolean;
};

const Detail: React.FC<Props> = ({ entity }) => {
  return (
    <Card>
      <Avatar
        style={{
          marginRight: '20px',
          float: 'left',
        }}
        sx={{ width: 200, height: 200 }}
        alt={entity?.nickName}
        src={entity?.businessId ? entity?.business.avatar : entity?.avatar}
      />
      <div
        style={{
          float: 'left',
          width: '80%',
        }}
      >
        <RowDetail title="Id" value={entity?.id} />
        <RowDetail title="Username" value={entity?.nickName || '-'} />
        <RowDetail title="Email" value={entity?.email || '-'} />
        <RowDetail title="Phone" value={entity?.phone || '-'} />
        <RowDetail title="Bio" value={entity?.bio || '-'} />
        {entity?.businessId && (
          <>
            <h3
              style={{
                paddingTop: '25px',
              }}
            >
              Business info
            </h3>
            <hr />
            <RowDetail title="Name" value={entity?.business.nickName || '-'} />
            <RowDetail
              title="Address"
              value={entity?.business.fullAddress || '-'}
            />
            <RowDetail
              title="Category"
              value={entity?.business.category || '-'}
            />

            <RowDetail title="Email" value={entity?.business.email || '-'} />
            <RowDetail
              title="Website"
              value={entity?.business.website || '-'}
            />
          </>
        )}
      </div>

      <RowDetail title="Device" value="" />
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>App Id</TableCell>
            <TableCell>Device Id</TableCell>
            <TableCell>Device Name</TableCell>
            <TableCell>Device Type</TableCell>
            <TableCell>Os</TableCell>
            <TableCell>Os Version</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entity?.devices.map((row: Response.DevicesEntity, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.appId}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.deviceId}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.deviceName}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.deviceType}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.os}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.osVersion}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <hr />
      <RowDetail title="Connected Hood" value="" />
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Connected Hood ID</TableCell>
            <TableCell>Alias Name</TableCell>
            <TableCell>Hood ID</TableCell>
            <TableCell>Hood Name</TableCell>
            <TableCell>Hood City</TableCell>
            <TableCell>Hood Description</TableCell>
            <TableCell>Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entity?.connectedHoods.map(
            (row: Response.ConnectedHoodEntity, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.aliasName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.hood.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.hood.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.hood.city}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.hood.description}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.createdAt}
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </Card>
  );
};

export default Detail;
