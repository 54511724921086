import { Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { APP_HEADER_HEIGHT } from 'src/constants/app';
import styled from 'styled-components';

type Props = {
  title: string;
  extra?: React.ReactElement;
};

const ContentWrapperFull: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
  extra,
}) => {
  return (
    <Wrapper>
      <Typography
        variant="h1"
        component="div"
        style={{
          marginBottom: 24,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        {extra}
      </Typography>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: ${`calc(100vh - ${APP_HEADER_HEIGHT}px)`};
  width: 100%;
  padding: 24px;
  min-width: 800px;
`;

export default ContentWrapperFull;
