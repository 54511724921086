import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import qs from 'query-string';

import facebookListActions from 'src/store/domain/facebook/list';
import facebookRequest, { CreateSetting } from 'src/api/requests/facebook';
import history from '../libs/history';
import { PATH as PATH_FACEBOOK } from '../pages/facebook';
// import appActions from 'src/store/ui/app';

interface FetchResources extends SearchParams.Facebook {}

const getParams = (args: FetchResources) => {
  const { take, page } = args;

  const params: Payload.AdsList = {
    ...args,
    take,
    page,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, any>(
  'get_facebook_list',
  async (args, { dispatch }) => {
    try {
      dispatch(facebookListActions.updateLoading(true));

      const params = getParams(args);
      const res = await facebookRequest.getList(_.omitBy(params, _.isEmpty));

      const { data } = res;

      if (data) {
        dispatch(
          facebookListActions.updateEntities(
            data as unknown as Response.FacebookListEntity[],
          ),
        );
      }

      // if (meta) {
      //   dispatch(
      //     appActions.updatePagination({
      //       page: meta.page,
      //       take: meta.take,
      //       itemCount: meta.itemCount,
      //       pageCount: meta.pageCount,
      //     }),
      //   );
      // }
      dispatch(facebookListActions.updateLoading(false));
    } catch (err) {
      dispatch(facebookListActions.updateLoading(false));
    }
  },
);

export const deleteSettingFacebook = createAsyncThunk<void, string>(
  'delete_setting_facebook',
  async (id, { dispatch }) => {
    try {
      const res = await facebookRequest.deleteSetting(id);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');

        await dispatch(
          fetchResources(
            getParams(qs.parse(history.location.search) as FetchResources),
          ),
        );
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);

export const createResource = createAsyncThunk<void, Partial<CreateSetting>>(
  'create_setting_facebook',
  async (data, { dispatch }) => {
    try {
      const res = await facebookRequest.createSetting(data);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');
        history.push(PATH_FACEBOOK.LIST);
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);
