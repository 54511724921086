import _ from 'lodash';
import HTTPRequest from '../index';

const apiPath = '/v1/links';

export interface CreateLinkPayload {
  name: string;
  url: string;
  category: string;
  icon: string;
  iconPath: string;
  hoodId: string;
  city: string;
  country: string;
  state: string;
  status: string;
  latitude: string;
  longitude: string;
  photo: string;
  offer: string;
  userId: string;
  isSuperClub: string;
  isLoyaltyTool: string;
}

export interface CreateLinkByMapPayload {
  name: string;
  url: string;
  category: string;
  icon: string;
  hoodId: string;
  city: string;
  country: string;
  state: string;
  status: string;
  latitude: string;
  longitude: string;
  customMapCode: string;
  id: string;
}

export interface UpdateLinkPayload {
  params: CreateLinkPayload;
  id: string;
}

class LinkRequest extends HTTPRequest {
  getList(params: Payload.AdsList) {
    return this.get<any>(apiPath, { params });
  }

  create(data: CreateLinkPayload) {
    let formData = new FormData();
    if (data.icon) {
      formData.append('icon', data.icon);
    } else if (data.iconPath) {
      formData.append('iconPath', data.iconPath);
    }
    if (data.photo) {
      formData.append('photo', data.photo);
    }
    formData.append('name', data.name);
    formData.append('url', data.url);
    formData.append('category', data.category);
    formData.append('hoodId', data.hoodId);
    formData.append('city', data.city);
    formData.append('country', data.country);
    formData.append('state', data.state);
    formData.append('status', data.status);
    formData.append('userId', data.userId);
    formData.append('isSuperClub', data.isSuperClub);
    formData.append('isLoyaltyTool', data.isLoyaltyTool);
    if (!_.isEmpty(data.latitude)) formData.append('latitude', data.latitude);
    if (!_.isEmpty(data.longitude))
      formData.append('longitude', data.longitude);
    formData.append('offer', data.offer);

    return this.post(`${apiPath}/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  createByMap(data: CreateLinkByMapPayload) {
    const formData = new FormData();
    formData.append('icon', data.icon);
    formData.append('name', data.name);
    formData.append('url', data.url);
    formData.append('category', data.category);
    formData.append('hoodId', data.hoodId);
    formData.append('city', data.city);
    formData.append('country', data.country);
    formData.append('state', data.state);
    formData.append('status', data.status);
    formData.append('customMapCode', data.customMapCode);
    if (!_.isEmpty(data.latitude)) formData.append('latitude', data.latitude);
    if (!_.isEmpty(data.longitude))
      formData.append('longitude', data.longitude);

    return this.post(`${apiPath}/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getDetailLink(id: string) {
    return this.get<any>(`${apiPath}/${id}`);
  }

  deleteLink(id: string) {
    return this.delete(`${apiPath}/${id}`);
  }

  updateLink(data: UpdateLinkPayload) {
    let id = data.id;
    let formData = data.params;

    return this.put(`${apiPath}/${id}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  attachCustomMap(linkId: string, customMapId: string) {
    return this.put(`${apiPath}/${linkId}/custom-map`, {
      customMapId,
      type: 'attach',
    });
  }

  detachCustomMap(linkId: string, customMapId: string) {
    return this.put(`${apiPath}/${linkId}/custom-map`, {
      customMapId,
      type: 'detach',
    });
  }
}

export default new LinkRequest();
