import * as Yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch } from 'src/store';
import { createResource } from 'src/useCase/eventList';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);
dayjs.extend(utc);
// import { DATE_TIME_PICKER } from '../../../../constants/app';

export type FormValues = {
  name: string;
  url: string;
  category: string;
  icon: string;
  city: string;
  country: string;
  state: string;
  latitude: string;
  longitude: string;
  startDate: string;
  endDate: string;
  status: string;
  hoodId: { label: string; id: string } | any;
  image: string;
  description: string;
};

const useFormDetail = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const defaultValues: FormValues = {
    name: '',
    url: '',
    category: '',
    icon: '',
    hoodId: '',
    city: '',
    country: '',
    state: '',
    status: '',
    latitude: '',
    longitude: '',
    startDate: '',
    endDate: '',
    image: '',
    description: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t('validated.required', { name: 'Event Name' }),
    ),
    category: Yup.string().required(
      t('validated.required', { name: 'Category' }),
    ),
    url: Yup.string()
      .required(t('validated.required', { name: 'Event URL' }))
      .url(),
    status: Yup.string().required(t('validated.required', { name: 'Status' })),
    latitude: Yup.string().required(
      t('validated.required', { name: 'latitude' }),
    ),
    longitude: Yup.string().required(
      t('validated.required', { name: 'longitude' }),
    ),
    startDate: Yup.date()
      .required()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    endDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('startDate', (startDate, schema) => {
        if (!startDate) {
          return;
        }
        return schema.min(startDate, t('validated.from_to_date'));
      }),
  });

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (values: FormValues) => {
    const newHoodId = form.watch('hoodId') === null ? '' : values.hoodId.id;

    await dispatch(
      createResource({
        ...values,
        hoodId: newHoodId || '',
        icon: form.watch('icon'),
        image: form.watch('image'),
        startDate: dayjs(values.startDate).tz('UTC', true).toISOString(),
        endDate: values.endDate
          ? dayjs(values.endDate).tz('UTC', true).toISOString()
          : '',
      }),
    );
  };

  return { form, onSubmit };
};

export default useFormDetail;
