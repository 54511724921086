import React, { useEffect } from 'react';

import Container from 'src/containers/ActivitySearchHistory';
import { ContentWrapper } from 'src/components/common';
import { fetchActivitiesSearchHistory } from 'src/useCase/activity';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { PER } from 'src/constants/app';

const SearchListPage: React.FC = () => {
  const query = useQueryUrl();
  const dispatch = useAppDispatch();
  const { page, take } = query;

  useEffect(() => {
    dispatch(
      fetchActivitiesSearchHistory({
        ...query,
        page: page,
        take: take || String(PER.DEFAULT),
      }),
    );
  }, [query]);
  return (
    <ContentWrapper title={'Activities search history'}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/activity_search_history' as const;
export default SearchListPage;
