import {
  Grid,
  styled,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Select,
  FormControl,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'src/components/common';
import useForm from './useForm';
import history from 'src/libs/history';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAppDispatch } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';

type Props = {
  entities: Response.HoodEntity[];
  loading: boolean;
};

const FormCreate: React.FC<Props> = ({ entities, loading }) => {
  const [category, setCategory] = React.useState('');
  const [getStatus, setStatus] = React.useState('');
  const dispatch = useAppDispatch();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const loadingHood = loading;
  const { formik } = useForm(category);
  const refBannerMobileLeaderBoard = useRef<File | null>(null);
  const refBannerWebLeaderBoard = useRef<File | null>(null);
  const refSpotlightImage = useRef<File | null>(null);
  // const refBannerLargeLeaderBoard = useRef<File | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    isSubmitting,
  } = formik;
  const { t } = useTranslation();

  const handleChangeSelectCategory = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleChangeSelectStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setFieldValue('status', event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listHood = useMemo(() => {
    if (openHood) {
      return entities.map((v) => {
        return {
          label: v.name,
          id: v.id,
        };
      });
    }
  }, [entities, openHood]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const categories = [
    {
      value: 'HOME',
      text: 'Home',
    },
    {
      value: 'WORK',
      text: 'Work',
    },
    {
      value: 'FAMILY',
      text: 'Family',
    },
    {
      value: 'HOLIDAY',
      text: 'Holiday',
    },
    {
      value: 'EVENT',
      text: 'Event',
    },
    {
      value: 'DREAM',
      text: 'Dream',
    },
    {
      value: 'OTHER',
      text: 'Other',
    },
  ];

  const status = [
    {
      value: 'pending_approval',
      text: 'Pending approval',
    },
    {
      value: 'active',
      text: 'Active',
    },
    {
      value: 'paused',
      text: 'Paused',
    },
    {
      value: 'archived',
      text: 'Archived',
    },
  ];

  const [searchHood, setSearchHood] = useState<string>('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchHood) {
        dispatch(searchHoods(searchHood));
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchHood]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="title"
                name="title"
                label={t('ads_fields.title')}
                onChange={handleChange}
                error={touched.title && Boolean(errors.title)}
                helperText={touched.title && errors.title}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="url"
                name="url"
                label={t('ads_fields.url')}
                onChange={handleChange}
                error={touched.url && Boolean(errors.url)}
                helperText={touched.url && errors.url}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                type="text"
                id="content"
                name="content"
                label={t('ads_fields.content')}
                onChange={handleChange}
                error={touched.content && Boolean(errors.content)}
                helperText={touched.content && errors.content}
              />
            </Grid>

            <Grid item xs={10}>
              <TextField
                fullWidth
                type="text"
                id="featureText"
                name="featureText"
                label={'Feature Text'}
                onChange={handleChange}
                error={touched.featureText && Boolean(errors.featureText)}
                helperText={touched.featureText && errors.featureText}
              />
            </Grid>

            <Grid item xs={5}>
              <Autocomplete
                multiple
                id="tags"
                options={[]}
                freeSolo
                onChange={(e, v) => {
                  setFieldValue('tags', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={'Hashtag'}
                    error={touched.tags && Boolean(errors.tags)}
                    helperText={touched.tags && errors.tags}
                    placeholder={'Please enter hashtag'}
                  />
                )}
              />
            </Grid>

            {/* Image 320 x 50 ?��? Mobile Leaderboard */}
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="bannerMobileLeaderBoard"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      refBannerMobileLeaderBoard.current =
                        event.currentTarget.files[0];
                      setFieldValue(
                        'bannerMobileLeaderBoard',
                        event.currentTarget.files[0],
                      );
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {touched.bannerMobileLeaderBoard &&
                errors.bannerMobileLeaderBoard && (
                  <FormHelperText className="Mui-error">
                    {errors.bannerMobileLeaderBoard}
                  </FormHelperText>
                )}
              <img
                width={320}
                height="auto"
                src={
                  refBannerMobileLeaderBoard.current
                    ? URL.createObjectURL(refBannerMobileLeaderBoard.current)
                    : 'https://placehold.jp/320x50.png?text=320+x+50+~+320+x+300'
                }
              />
            </Grid>

            {/* Image 728 x 90 Leaderboard Banner (Web) */}
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="bannerWebLeaderBoard"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      refBannerWebLeaderBoard.current =
                        event.currentTarget.files[0];
                      setFieldValue(
                        'bannerWebLeaderBoard',
                        event.currentTarget.files[0],
                      );
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {touched.bannerWebLeaderBoard && errors.bannerWebLeaderBoard && (
                <FormHelperText className="Mui-error">
                  {errors.bannerWebLeaderBoard}
                </FormHelperText>
              )}
              <img
                width={728}
                height={90}
                src={
                  refBannerWebLeaderBoard.current
                    ? URL.createObjectURL(refBannerWebLeaderBoard.current)
                    : 'https://placehold.jp/728x90.png'
                }
              />
            </Grid>

            {/* Image spotlight 300 x 300*/}
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="spotlightImage"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      refSpotlightImage.current = event.currentTarget.files[0];
                      setFieldValue(
                        'spotlightImage',
                        event.currentTarget.files[0],
                      );
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {touched.spotlightImage && errors.spotlightImage && (
                <FormHelperText className="Mui-error">
                  {errors.spotlightImage}
                </FormHelperText>
              )}
              <img
                width={300}
                height={300}
                src={
                  refSpotlightImage.current
                    ? URL.createObjectURL(refSpotlightImage.current)
                    : 'https://placehold.jp/300x300.png'
                }
              />
            </Grid>

            {/* Image 970 x 90 ?��? Large Leaderboard (Web) */}
            {/*<Grid item xs={10}>*/}
            {/*  <IconButton*/}
            {/*    color="primary"*/}
            {/*    aria-label="upload picture"*/}
            {/*    component="label"*/}
            {/*  >*/}
            {/*    <input*/}
            {/*      hidden*/}
            {/*      accept="image/*"*/}
            {/*      type="file"*/}
            {/*      name="bannerLargeLeaderBoard"*/}
            {/*      onChange={(event) => {*/}
            {/*        if (*/}
            {/*          event &&*/}
            {/*          event.currentTarget &&*/}
            {/*          event.currentTarget.files*/}
            {/*        ) {*/}
            {/*          refBannerLargeLeaderBoard.current =*/}
            {/*            event.currentTarget.files[0];*/}
            {/*          setFieldValue(*/}
            {/*            'bannerLargeLeaderBoard',*/}
            {/*            event.currentTarget.files[0],*/}
            {/*          );*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    />*/}
            {/*    <PhotoCamera />*/}
            {/*  </IconButton>*/}
            {/*  {touched.bannerLargeLeaderBoard &&*/}
            {/*    errors.bannerLargeLeaderBoard && (*/}
            {/*      <FormHelperText className="Mui-error">*/}
            {/*        {errors.bannerLargeLeaderBoard}*/}
            {/*      </FormHelperText>*/}
            {/*    )}*/}
            {/*  <img*/}
            {/*    width={970}*/}
            {/*    height={90}*/}
            {/*    src={*/}
            {/*      refBannerLargeLeaderBoard.current*/}
            {/*        ? URL.createObjectURL(refBannerLargeLeaderBoard.current)*/}
            {/*        : 'https://placehold.jp/970x90.png'*/}
            {/*    }*/}
            {/*  />*/}
            {/*</Grid>*/}

            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="language"
                name="language"
                label={t('ads_fields.language')}
                onChange={handleChange}
                error={touched.language && Boolean(errors.language)}
                helperText={touched.language && errors.language}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="buttonText"
                name="buttonText"
                label={t('ads_fields.buttonText')}
                onChange={handleChange}
                error={touched.buttonText && Boolean(errors.buttonText)}
                helperText={touched.buttonText && errors.buttonText}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                value={values.dateFrom}
                label="Date From"
                onChange={(value) => {
                  setFieldValue('dateFrom', value?.toString(), true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="dateFrom"
                    name="dateFrom"
                    label="Date From"
                    error={touched.dateFrom && Boolean(errors.dateFrom)}
                    helperText={touched.dateFrom && errors.dateFrom}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                value={values.dateTo}
                label="Date To"
                onChange={(value) => {
                  setFieldValue('dateTo', value?.toString(), true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="dateTo"
                    name="dateTo"
                    label="Date To"
                    error={touched.dateTo && Boolean(errors.dateTo)}
                    helperText={touched.dateTo && errors.dateTo}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                id="combo-box-demo"
                options={listHood || []}
                onChange={(e, v) => {
                  setFieldValue('hoodId', v?.id);
                }}
                onInputChange={(e: any) => setSearchHood(e.target?.value)}
                loading={loadingHood}
                onOpen={() => setOpenHood(true)}
                onClose={() => setOpenHood(false)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    name="hoodId"
                    {...params}
                    label={t('ads_fields.hood')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingHood ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              {touched.hoodId && Boolean(errors.hoodId)}
            </Grid>
            <Grid item xs={5}>
              <FormControl sx={{ m: 1, minWidth: '100%', margin: 0 }}>
                <InputLabel id="controlled-open-select-label">
                  {t('ads_fields.hoodCategory')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-label"
                  id="controlled-open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={category}
                  label={t('ads_fields.hoodCategory')}
                  onChange={handleChangeSelectCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories.map((category, index) => {
                    return (
                      <MenuItem key={index} value={category.value}>
                        {category.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
                id="city"
                name="city"
                label={t('ads_fields.city')}
                onChange={handleChange}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="text"
                id="country"
                name="country"
                label={t('ads_fields.country')}
                onChange={handleChange}
                error={touched.country && Boolean(errors.country)}
                helperText={touched.country && errors.country}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="text"
                id="state"
                name="state"
                label={t('ads_fields.state')}
                onChange={handleChange}
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state && errors.state}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl
                sx={{ m: 1, minWidth: '100%', margin: 0 }}
                error={touched.status && Boolean(errors.status)}
              >
                <InputLabel id="controlled-open-select-status-label">
                  {t('ads_fields.status')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-status-label"
                  id="controlled-open-select-status"
                  open={openStatus}
                  onClose={handleCloseStatus}
                  onOpen={handleOpenStatus}
                  value={getStatus}
                  name="status"
                  label={t('ads_fields.status')}
                  onChange={handleChangeSelectStatus}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {status.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
                {touched.status && Boolean(errors.status) && (
                  <FormHelperText>{errors.status}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="officeName"
                name="officeName"
                label={t('ads_fields.officeName')}
                onChange={handleChange}
                error={touched.officeName && Boolean(errors.officeName)}
                helperText={touched.officeName && errors.officeName}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                style={{ marginTop: 22 }}
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Create
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormCreate;
