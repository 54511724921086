import HTTPRequest from '../index';

const apiPath = '/v1/reports';

class ReportRequest extends HTTPRequest {
  getList(params: Payload.ReportList) {
    return this.get<Response.ReportListResponse>(apiPath, { params });
  }
}

export default new ReportRequest();
