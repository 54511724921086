import { createAsyncThunk } from '@reduxjs/toolkit';

import userActions from 'src/store/domain/user/detail';
import userRequest, { GetUserDetail } from 'src/api/requests/user';

export const fetchUserDetail = createAsyncThunk<void, GetUserDetail>(
  'get_user_detail',
  async (payload, { dispatch }) => {
    try {
      dispatch(userActions.updateLoading(true));

      const res = await userRequest.getDetail(payload);

      const { data } = res;

      if (data) {
        dispatch(userActions.updateEntity(data));
      }

      dispatch(userActions.updateLoading(false));
    } catch (err) {
      dispatch(userActions.updateLoading(false));
    }
  },
);
