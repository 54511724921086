import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import qs from 'query-string';

import adsListActions from 'src/store/domain/ads/list';
import adsReportListActions from 'src/store/domain/ads/report';
import adsRequest, { CreateAds } from 'src/api/requests/ads';
import appActions from 'src/store/ui/app';
import history from 'src/libs/history';

interface FetchResources extends SearchParams.Ads {}

interface FetchReportResources extends SearchParams.AdsReport {}

const getParams = (args: FetchResources) => {
  const { take, page } = args;

  const params: Payload.AdsList = {
    ...args,
    take,
    page,
  };

  return params;
};

const getReportParams = (args: FetchReportResources) => {
  const { take, page, filter, type } = args;

  const params: Payload.AdsReportList = {
    take,
    page,
    filter,
    type,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, any>(
  'get_ads_list',
  async (args, { dispatch }) => {
    try {
      dispatch(adsListActions.updateLoading(true));

      const params = getParams(args);
      const res = await adsRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(adsListActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(adsListActions.updateLoading(false));
    } catch (err) {
      dispatch(adsListActions.updateLoading(false));
    }
  },
);

export const createResource = createAsyncThunk<void, CreateAds>(
  'create_ads',
  async (body, { dispatch }) => {
    try {
      const res = await adsRequest.create(body);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);

export const deleteAds = createAsyncThunk<void, string>(
  'delete_ads',
  async (id, { dispatch }) => {
    try {
      const res = await adsRequest.deleteAds(id);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');

        await dispatch(
          fetchResources(
            getParams(qs.parse(history.location.search) as FetchResources),
          ),
        );
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);

export const fetchReportResources = createAsyncThunk<void, FetchResources>(
  'get_ads_report_list',
  async (args, { dispatch }) => {
    try {
      dispatch(adsReportListActions.updateLoading(true));
      const params = getReportParams(args);
      console.log('params', params);
      const res = await adsRequest.reportAnalytics(params);

      const { data, meta } = res.data;

      if (data) {
        dispatch(adsReportListActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(adsReportListActions.updateLoading(false));
    } catch (err) {
      dispatch(adsReportListActions.updateLoading(false));
    }
  },
);
