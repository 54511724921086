import HTTPRequest from '../index';
import { formatDate } from 'src/utils/helpers';
import _ from 'lodash';

const apiPath = '/v1/ads';

export interface GetAdsDetail {
  id: string;
}

export interface CreateAds {
  title: string;
  content: string;
  language: string;
  buttonText: string;
  url: string;
  dateFrom: string;
  dateTo: string;
  hoodId: string;
  city: string;
  country: string;
  state: string;
  hoodCategory: string;
  bannerMobileLeaderBoard: string;
  bannerLargeLeaderBoard: string;
  bannerWebLeaderBoard: string;
  spotlightImage: string;
  status: string;
  officeName?: string;
  featureText?: string;
  tags?: string[];
}

export interface UpdateAds extends Omit<CreateAds, 'hoodId'> {
  id: string;
  hoodId: { label: string; id: string };
}

class AdsRequest extends HTTPRequest {
  getList(params: Payload.AdsList) {
    return this.get<any>(apiPath, { params });
  }

  create(data: CreateAds) {
    let formData = new FormData();
    formData.append('bannerMobileLeaderBoard', data.bannerMobileLeaderBoard);
    formData.append('bannerWebLeaderBoard', data.bannerWebLeaderBoard);
    formData.append('bannerLargeLeaderBoard', data.bannerLargeLeaderBoard);
    if (data.spotlightImage) {
      formData.append('spotlightImage', data.spotlightImage);
    }
    formData.append('title', data.title);
    formData.append('content', data.content);
    formData.append('language', data.language);
    formData.append('buttonText', data.buttonText);
    formData.append('url', data.url);
    formData.append('dateTo', formatDate(data.dateTo));
    formData.append('dateFrom', formatDate(data.dateFrom));
    formData.append('hoodId', data.hoodId);
    formData.append('city', data.city);
    formData.append('country', data.country);
    formData.append('state', data.state);
    formData.append('hoodCategory', data.hoodCategory);
    formData.append('status', data.status);
    // @ts-ignore
    formData.append('officeName', data.officeName);
    // @ts-ignore
    formData.append('featureText', data.featureText);
    if (!_.isEmpty(data.tags)) {
      // @ts-ignore
      data.tags.map((item) => {
        formData.append('tags[]', item);
      });
    }
    return this.post(`${apiPath}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deleteAds(id: string) {
    return this.delete(`${apiPath}/${id}`);
  }

  getDetailAds(id: string) {
    return this.get<Response.AdsDetailResponse>(`${apiPath}/${id}`);
  }

  update(data: UpdateAds) {
    let formData = new FormData();
    formData.append('bannerMobileLeaderBoard', data.bannerMobileLeaderBoard);
    formData.append('bannerWebLeaderBoard', data.bannerWebLeaderBoard);
    formData.append('bannerLargeLeaderBoard', data.bannerLargeLeaderBoard);
    formData.append('title', data.title);
    formData.append('content', data.content);
    formData.append('language', data.language);
    formData.append('buttonText', data.buttonText);
    formData.append('url', data.url);
    formData.append('dateTo', formatDate(data.dateTo));
    formData.append('dateFrom', formatDate(data.dateFrom));
    if (data?.hoodId?.id) {
      formData.append('hoodId', _.get(data, 'hoodId.id', null));
    }

    if (data.spotlightImage) {
      formData.append('spotlightImage', data.spotlightImage);
    }
    formData.append('city', data.city);
    formData.append('country', data.country);
    formData.append('state', data.state);
    formData.append('hoodCategory', data.hoodCategory);
    formData.append('status', data.status);
    // @ts-ignore
    formData.append('officeName', data.officeName);
    // @ts-ignore
    formData.append('featureText', data.featureText);
    if (!_.isEmpty(data.tags)) {
      // @ts-ignore
      data.tags.map((item) => {
        formData.append('tags[]', item);
      });
    }

    return this.put(`${apiPath}/${data.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  reportAnalytics(params: Payload.AdsReportList) {
    return this.get(`${apiPath}/${params.type}/analytics`, { params });
  }
}

export default new AdsRequest();
