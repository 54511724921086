import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import qs from 'query-string';
import activityListActions from 'src/store/domain/activity/list';
import activitySearchHistoryActions from 'src/store/domain/activity/search_history';
import activityRequest, { CreateActivity } from 'src/api/requests/activity';
import appActions from 'src/store/ui/app';
import history from 'src/libs/history';

interface FetchResources extends SearchParams.Activity {}

const getParams = (args: FetchResources) => {
  const { take, page } = args;

  const params: Payload.ActivityList = {
    ...args,
    take,
    page,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, any>(
  'get_activity_list',
  async (args, { dispatch }) => {
    try {
      dispatch(activityListActions.updateLoading(true));

      const params = getParams(args);
      const res = await activityRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(activityListActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(activityListActions.updateLoading(false));
    } catch (err) {
      dispatch(activityListActions.updateLoading(false));
    }
  },
);

export const createResource = createAsyncThunk<void, CreateActivity>(
  'create_activity',
  async (body, { dispatch }) => {
    try {
      const res = await activityRequest.create(body);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);

export const deleteActivity = createAsyncThunk<void, string>(
  'delete_activity',
  async (id, { dispatch }) => {
    try {
      const res = await activityRequest.deleteActivity(id);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');

        await dispatch(
          fetchResources(
            getParams(qs.parse(history.location.search) as FetchResources),
          ),
        );
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);

export const fetchActivitiesSearchHistory = createAsyncThunk<void, any>(
  'get_activity_search_history',
  async (args, { dispatch }) => {
    try {
      dispatch(activitySearchHistoryActions.updateLoading(true));

      const params = getParams(args);
      const res = await activityRequest.getListSearchHistory(
        _.omitBy(params, _.isEmpty),
      );

      const { data, meta } = res.data;

      if (data) {
        dispatch(activitySearchHistoryActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(activitySearchHistoryActions.updateLoading(false));
    } catch (err) {
      dispatch(activitySearchHistoryActions.updateLoading(false));
    }
  },
);
