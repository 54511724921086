import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import linkDetailActions from 'src/store/domain/link/detail';
import linkRequest, { UpdateLinkPayload } from 'src/api/requests/link';

export const fetchResources = createAsyncThunk<void, string>(
  'get_link_detail',
  async (id, { dispatch }) => {
    try {
      dispatch(linkDetailActions.updateLoading(true));

      const res = await linkRequest.getDetailLink(id);

      if (res.data) {
        await dispatch(linkDetailActions.updateEntity(res.data));
      }

      dispatch(linkDetailActions.updateLoading(false));
    } catch (err) {
      dispatch(linkDetailActions.updateLoading(false));
    }
  },
);

export const updateLink = createAsyncThunk<void, UpdateLinkPayload>(
  'update_link',
  async (data, { dispatch }) => {
    try {
      const res = await linkRequest.updateLink(data);
      if (res.status != 200) {
        toast.error('There was an error updating');
      } else {
        toast.success('Successfully!');
        await dispatch(fetchResources(data.id));
      }
    } catch (err) {
      toast.error('There was an error updating');
    }
  },
);

export const attachCustomMap = createAsyncThunk<void, any>(
  'link_attachCustomMap',
  async ({ linkId, customMapId }, { dispatch }) => {
    try {
      const res = await linkRequest.attachCustomMap(linkId, customMapId);
      if (res.status != 200) {
        toast.error('There was an error attach');
      } else {
        toast.success('Successfully!');
        await dispatch(fetchResources(linkId));
      }
    } catch (err) {
      toast.error('There was an error updating');
    }
  },
);

export const detachCustomMap = createAsyncThunk<void, any>(
  'link_detachCustomMap',
  async ({ linkId, customMapId }, { dispatch }) => {
    try {
      const res = await linkRequest.detachCustomMap(linkId, customMapId);
      if (res.status != 200) {
        toast.error('There was an error detach');
      } else {
        toast.success('Successfully!');
        await dispatch(fetchResources(linkId));
      }
    } catch (err) {
      toast.error('There was an error updating');
    }
  },
);
