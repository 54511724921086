import HTTPRequest from '../index';

const apiPath = '/v1/facebook-fanpage-settings';

export type CreateSetting = {
  pageId: string;
  pageAccessToken: string;
  type: string;
  pageName: string;
  userId: string;
  hoodId: string;
  category: string;
};

export type PayloadSetting = {
  pageId: string;
  pageAccessToken: string;
  type: string;
  userId: string;
  pageName: string;
  hoodId: string;
  category: string;
};
export type UpdateSetting = {
  id: string;
  data: PayloadSetting;
};

class FacebookRequest extends HTTPRequest {
  getList(params: Payload.FacebookList) {
    return this.get<Response.FacebookListResponse>(apiPath, { params });
  }

  deleteSetting(id: string) {
    return this.delete(`${apiPath}/${id}`);
  }

  createSetting(data: Partial<CreateSetting>) {
    return this.post(apiPath, data);
  }

  getDetail(id: string) {
    return this.get<Response.FacebookResponse>(`${apiPath}/${id}`);
  }

  update(payload: UpdateSetting) {
    return this.put(`${apiPath}/${payload.id}`, { ...payload.data });
  }
}

export default new FacebookRequest();
