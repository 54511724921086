import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  loading: boolean;
  entity: Response.BannerDetailEntity | null;
};

const initialState: State = {
  loading: false,
  entity: null,
};

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'banner_detail',
  initialState,
  reducers: {
    updateEntity: (
      state,
      action: PayloadAction<Response.BannerDetailEntity>,
    ) => {
      state.entity = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export { reducer };
export default actions;
