import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import userListActions from 'src/store/domain/user/list';
import userRequest from 'src/api/requests/user';
import appActions from 'src/store/ui/app';

interface FetchResources extends SearchParams.User {}

interface RequestBanned {
  id: string;
  isBanned: boolean;
  query: FetchResources;
}

const getParams = (args: FetchResources) => {
  const {
    email,
    lastName,
    nickName,
    phone,
    firstName,
    take,
    page,
    isBusiness,
  } = args;

  const params: Payload.UserList = {
    take,
    page,
    email,
    lastName,
    nickName,
    phone,
    firstName,
    isBusiness,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, FetchResources>(
  'get_user_list',
  async (args, { dispatch }) => {
    try {
      dispatch(userListActions.updateLoading(true));

      const params = getParams(args);
      const res = await userRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(userListActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(userListActions.updateLoading(false));
    } catch (err) {
      dispatch(userListActions.updateLoading(false));
    }
  },
);

export const bannedUser = createAsyncThunk<void, RequestBanned>(
  'banned_user',
  async (payload, { dispatch }) => {
    try {
      dispatch(userListActions.updateLoading(true));

      await userRequest.bannedUser(payload.id, { isBanned: payload.isBanned });

      const params = getParams(payload.query);
      const res = await userRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(userListActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(userListActions.updateLoading(false));
    } catch (err) {
      dispatch(userListActions.updateLoading(false));
    }
  },
);

export const searchUsers = createAsyncThunk<void, string>(
  'search_users',
  async (search, { dispatch }) => {
    try {
      dispatch(userListActions.updateLoading(true));
      const res = await userRequest.searchUsers(search);

      const data: any = res.data;
      if (data) {
        dispatch(userListActions.updateEntities(data.data));
      }

      dispatch(userListActions.updateLoading(false));
    } catch (err) {
      dispatch(userListActions.updateLoading(false));
    }
  },
);
