import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  action: string | null;
  loading: boolean;
  entity: Response.StatisticsEntity | null;
  userAnalytics: Response.UserAnalyticsEntity[];
};

const initialState: State = {
  action: null,
  loading: false,
  entity: null,
  userAnalytics: [],
};

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'statistics_detail',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<Response.StatisticsEntity>) => {
      state.entity = action.payload;
    },
    updateUserAnalyticsEntity: (
      state,
      action: PayloadAction<Response.UserAnalyticsEntity[]>,
    ) => {
      state.userAnalytics = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export { reducer };
export default actions;
