import React from 'react';

import Container from 'src/containers/FacebookCreate';
import { ContentWrapper } from 'src/components/common';
import { t } from 'src/libs/i18n';

const Page: React.FC = () => {
  return (
    <ContentWrapper title={t('title.facebook_create')}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/facebook-integration/create' as const;
export default Page;
