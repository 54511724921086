import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  action: string | null;
  loading: boolean;
  hoodAnalytics: Response.HoodAnalyticsEntity[];
  hoodAnalyticsDetail: any;
};

const initialState: State = {
  action: null,
  loading: false,
  hoodAnalytics: [],
  hoodAnalyticsDetail: null,
};

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'statistics_hood',
  initialState,
  reducers: {
    updateHoodAnalyticsEntity: (
      state,
      action: PayloadAction<Response.HoodAnalyticsEntity[]>,
    ) => {
      state.hoodAnalytics = action.payload;
    },
    updateHoodAnalyticsDetailEntity: (state, action: PayloadAction<any>) => {
      state.hoodAnalyticsDetail = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export { reducer };
export default actions;
