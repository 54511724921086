import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useConfirm } from 'material-ui-confirm';

import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { Button, Grid, LinearProgress, CardMedia } from '@mui/material';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME } from 'src/constants/app';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { useAppDispatch } from 'src/store';
import { deleteImage, updateImage } from 'src/useCase/hoodImages';

type Props = {
  loading: boolean;
  entities: Response.HoodEntity[];
};

const HoodImageListDetailTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const handleDeleteConfirm = (image: any) => {
    console.log(image);
    confirm({ description: 'This will permanently delete hood image ?' }).then(
      async () => {
        await dispatch(deleteImage({ id: image.id, hoodId: image.hoodId }));
      },
    );
  };

  const handleStatusConfirm = (image: any) => {
    console.log(image);
    confirm({ description: 'This will change status hood image ?' }).then(
      async () => {
        await dispatch(
          updateImage({
            id: image.id,
            hoodId: image.hoodId,
            status: image.status === 'active' ? 'pending_approval' : 'active',
          }),
        );
      },
    );
  };
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 400,
    },
    {
      field: 'HoodName',
      headerName: 'Hood Name',
      sortable: false,
      width: 400,
      renderCell: ({ row }) => {
        return row?.hood?.name || '-';
      },
    },
    {
      field: 'image',
      headerName: 'Image',
      sortable: false,
      width: 500,
      renderCell: ({ row }) => {
        if (_.isEmpty(row?.image)) return '-';

        const isSvgImage = row?.image ? row?.image.includes('.svg') : false;

        return isSvgImage ? (
          <svg
            data-src={`${process.env.REACT_APP_MEDIA_URL}${row?.image}`}
            fill="currentColor"
            height="100px"
            width="100%"
          />
        ) : (
          <CardMedia
            component="img"
            height="200"
            image={`${process.env.REACT_APP_MEDIA_URL}${row.image}`}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 300,
      valueGetter: ({ value }) => {
        switch (value) {
          case 'active':
            return 'Active';
          default:
            return 'Pending approval';
        }
      },
    },
    {
      field: 'createdAt',
      headerName: t('createdAt'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      width: 330,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => handleDeleteConfirm(row)}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => handleStatusConfirm(row)}
              >
                {row?.status === 'active' ? 'Deactivate' : 'Activate'}
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 1000, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          rowHeight={200}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default HoodImageListDetailTable;
