import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'src/store';
import { updateBanner } from 'src/useCase/bannerDetail';
import { useCallback, useEffect, useState } from 'react';

export type FormValues = {
  bannerId: string;
  bannerInternalFunctionCode: string;
  language: string;
  bannerText: string;
  url: string;
  dateFrom: string;
  dateTo: string;
  hoodId: { label: string; id: string } | any;
  city: string;
  country: string;
  state: string;
  hoodCategory: string;
  bannerMobileLeaderBoard: any;
  bannerWebLeaderBoard: any;
  status: string;
  userId: { label: string; id: string } | any;
  textColor: string;
  tags: string[];
};

type Data = Response.BannerDetailEntity | null;
type Hoods = Response.HoodEntity[];
// type Users = Response.UserEntity[];

const useFormDetail = (data: Data, hoods: Hoods, users: any) => {
  const [loadingHood, setLoadingHood] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getDefaultValueHood = useCallback(
    (data: Response.BannerDetailEntity | null) => {
      const hood = hoods.find((v) => String(v.id) === data?.hoodId);
      if (!hood) return null;

      return {
        label: String(hood?.name),
        id: String(hood?.id),
      };
    },
    [hoods],
  );

  const getDefaultValueUser = useCallback(
    (data: Response.BannerDetailEntity | null) => {
      const user = users.find((v) => String(v.id) === data?.userId);
      if (!user) return null;

      return {
        label: String(user?.nick_name),
        id: String(user?.id),
      };
    },
    [users],
  );

  const validationSchema = Yup.object().shape({
    bannerText: Yup.string().required(
      t('validated.required', { name: t('ads_fields.buttonText') }),
    ),
    url: Yup.string()
      .required(t('validated.required', { name: t('ads_fields.url') }))
      .url(),
    dateFrom: Yup.date().required(
      t('validated.required', { name: t('ads_fields.dateFrom') }),
    ),
    dateTo: Yup.date()
      .required(t('validated.required', { name: t('ads_fields.dateTo') }))
      .when('dateFrom', (dateFrom, schema) => dateFrom && schema.min(dateFrom)),
    status: Yup.mixed().required(
      t('validated.required', { name: t('ads_fields.status') }),
    ),
    textColor: Yup.string()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, t('ads_fields.textColor')),
  });

  const form = useForm<FormValues>({
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!getDefaultValueHood(data)?.id) {
      setLoadingHood(true);
    }
    setLoadingHood(false);
  }, [data, hoods]);

  useEffect(() => {
    if (!getDefaultValueUser(data)?.id) {
      setLoadingUser(true);
    }
    setLoadingUser(false);
  }, [data, users]);

  useEffect(() => {
    const labelHood = data?.hood
      ? {
          label: data?.hood?.name,
          id: data?.hoodId,
        }
      : '';
    const labelUser = data?.user
      ? {
          label: data?.user?.nick_name,
          id: data?.userId,
        }
      : '';
    form.reset({
      bannerId: data?.bannerId,
      bannerText: data?.bannerText,
      language: data?.language,
      bannerInternalFunctionCode: data?.bannerInternalFunctionCode,
      url: data?.url,
      dateFrom: data?.dateFrom || new Date().toDateString(),
      dateTo: data?.dateTo || new Date().toDateString(),
      hoodId: labelHood || '',
      city: data?.city,
      country: data?.country,
      state: data?.state,
      hoodCategory: data?.hoodCategory,
      status: data?.status,
      userId: labelUser || '',
      textColor: data?.textColor,
    });
  }, [data]);

  const submitForm = () => {
    form.handleSubmit(async (values) => {
      await dispatch(
        updateBanner({
          id: data?.id as string,
          ...values,
        }),
      );
    })();
  };

  return { form, submitForm, loadingHood, loadingUser };
};

export default useFormDetail;
