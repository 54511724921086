import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { LinearProgress } from '@mui/material';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME } from 'src/constants/app';
import { Link } from 'react-router-dom';
import { PATH } from '../../../../pages/post';
import { PATH as UserPath } from '../../../../pages/user';

type Props = {
  loading: boolean;
  entities: Response.ShareEntity[];
};

const ShareTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 150,
    },
    {
      field: 'postId',
      headerName: 'Post Id',
      sortable: false,
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link to={PATH.POST_DETAIL.replace(':id', params.row.postId)}>
            {params.row.postId}
          </Link>
        );
      },
    },
    {
      field: 'nickName',
      headerName: 'Nickname',
      sortable: false,
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link to={UserPath.USER_DETAIL.replace(':id', params.row?.user?.id)}>
            {params.row?.user?.nickName}
          </Link>
        );
      },
    },
    {
      field: 'city',
      headerName: 'City',
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <>{_.get(params.row, 'post.hood.city', '-')}</>;
      },
    },
    {
      field: 'State',
      headerName: 'State',
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <>{_.get(params.row, 'post.hood.state', '-')}</>;
      },
    },
    {
      field: 'country',
      headerName: 'Country Code',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        return <>{_.get(params.row, 'post.hood.countryCode', '-')}</>;
      },
    },

    {
      field: 'Location',
      headerName: 'Location',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <a
            href={`http://maps.google.com/maps?q=${_.get(
              params.row,
              'post.location.coordinates.[1]',
              '',
            )},${_.get(params.row, 'post.location.coordinates.[0]', '-')}`}
            target="_blank"
            rel="noreferrer"
          >
            View Maps
          </a>
        );
      },
    },
    {
      field: 'name',
      headerName: t('name'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'phone',
      headerName: t('phone'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'email',
      headerName: t('email'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'note',
      headerName: t('note'),
      sortable: false,
      width: 250,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'code',
      headerName: t('link'),
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <a
              href={`${process.env.REACT_APP_LINK_SHARE}${params.row.code}`}
              target={'_blank'}
              rel="noreferrer"
            >
              Link share
            </a>
          </>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: t('createdAt'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'updatedAt',
      headerName: t('updatedAt'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default ShareTable;
