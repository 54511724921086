import React from 'react';

import FormDetail from './Form';
import { useAppSelector } from 'src/store';

type Props = {
  hoods: Response.HoodEntity[];
};

const Main: React.FC<Props> = ({ hoods }) => {
  const entites = useAppSelector((state) => state.domain.ads.detail);
  const props = {
    hoods: hoods,
    loading: entites.loading,
    entity: entites.entity,
  };

  return (
    <React.Fragment>
      <FormDetail {...props} />
    </React.Fragment>
  );
};

export default Main;
