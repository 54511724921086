import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Button, Grid, LinearProgress, CardMedia, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME } from 'src/constants/app';
import { useConfirm } from 'material-ui-confirm';
import { useAppDispatch } from 'src/store';
import { deleteActivity } from 'src/useCase/activity';
import { isValidHttpUrl } from 'src/utils/helpers';

type Props = {
  loading: boolean;
  entities: any[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const handleConfirm = (id: string) => {
    confirm({ description: t('des.delete_activity') }).then(async () => {
      await dispatch(deleteActivity(id));
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 310,
    },
    {
      field: 'headline',
      headerName: 'Headline',
      sortable: false,
      width: 200,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'category',
      headerName: 'category',
      sortable: false,
      width: 200,
      valueGetter: ({ value }) => {
        switch (value) {
          case 'sports_and_fitness':
            return 'Sports & Fitness';
          case 'culture_and_music':
            return 'Culture & Music';
          case 'buy_and_sell':
            return 'Buy & Sell';
          case 'community_fun':
            return 'Community fun';
          case 'voluntary_work':
            return 'Voluntary work';
          case 'kids_family':
            return 'Kids & Family';
          case 'attractions':
            return 'Attractions';
          case 'food_and_restaurants':
            return 'Food And Restaurants';
          case 'shopping':
            return 'Shopping';
          default:
            return 'Other Activities';
        }
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 400,
      valueGetter: ({ value }) => {
        switch (value) {
          case 'pending_approval':
            return 'Pending approval';
          case 'active':
            return 'Active';
          case 'paused':
            return 'Paused';
          default:
            return 'Archived';
        }
      },
    },
    {
      field: 'photo',
      headerName: 'Photo',
      sortable: false,
      width: 300,
      renderCell: ({ row }) => {
        const url = isValidHttpUrl(row.photo)
          ? row.photo
          : `${process.env.REACT_APP_MEDIA_URL}${row.photo}`;
        return <CardMedia component="img" height="150" image={`${url}`} />;
      },
    },
    {
      field: 'time',
      headerName: 'Time',
      sortable: false,
      width: 100,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },

    {
      field: 'webLink',
      headerName: 'Web Link',
      sortable: false,
      width: 400,
      renderCell: ({ row }) => {
        return (
          <a href={row?.webLink} target="_blank" rel="noreferrer">
            {row?.webLink}
          </a>
        );
      },
    },
    {
      field: 'expirationDate',
      headerName: t('activity_fields.expirationDate'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'createdAt',
      headerName: t('createdAt'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      width: 350,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<EditIcon />}
                to={`/activity_list/${params.row.id}`}
                component={Link}
              >
                Detail
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleConfirm(params.row.id)}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Card>
      <div style={{ minHeight: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          autoHeight={true}
          rowHeight={150}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default UserTable;
