import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentWrapper } from 'src/components/common';
import DashboardContainer from 'src/containers/Dashboard';
import { useAppDispatch } from 'src/store';
import { fetchResources } from 'src/useCase/statistics';

const Page: App.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchResources());
  }, []);
  return (
    <ContentWrapper title={t('title.dashboard')}>
      <DashboardContainer />
    </ContentWrapper>
  );
};

export const PATH = '/' as const;
export default Page;
