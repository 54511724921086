import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { useAppDispatch } from 'src/store';
import { createResource } from 'src/useCase/app';
import history from 'src/libs/history';
import { PATH } from 'src/pages/app/AppList';

export type FormValue = {
  appDescription: string;
  appLabelText: string;
  appAndroidUrl: string;
  appIosUrl: string;
  appDesktopUrl: string;
  order: string;
  hoodId: [];
  city: [];
  country: [];
  state: [];
  hoodCategory: string;
  appIcon: string;
  status: string;
  category: string;
  isBusinessOnly: string;
  latitude: string;
  longitude: string;
};

type Category = string;

const useForm = (category: Category) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const defaultValue: FormValue = {
    appDescription: '',
    appLabelText: '',
    appAndroidUrl: '',
    appIosUrl: '',
    appDesktopUrl: '',
    order: '',
    hoodId: [],
    city: [],
    country: [],
    state: [],
    hoodCategory: '',
    appIcon: '',
    status: '',
    category: '',
    isBusinessOnly: 'false',
    latitude: '',
    longitude: '',
  };

  const validationSchema = Yup.object().shape({
    appLabelText: Yup.string().required(
      t('validated.required', { name: 'Label Text' }),
    ),
    appIcon: Yup.mixed().required(
      t('validated.required', { name: t('ads_fields.banner') }),
    ),
    appDescription: Yup.string().required(
      t('validated.required', { name: 'App description' }),
    ),
    order: Yup.string().required(t('validated.required', { name: 'Order' })),
    status: Yup.string().required(
      t('validated.required', { name: t('ads_fields.status') }),
    ),
  });

  const formik = useFormik<FormValue>({
    initialValues: {
      ...defaultValue,
    },
    validationSchema,
    async onSubmit(values) {
      await dispatch(createResource({ ...values, hoodCategory: category }));
      history.push(PATH);
    },
    validateOnChange: true,
  });

  return { formik };
};

export default useForm;
