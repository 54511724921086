import React from 'react';

import { PATH } from 'src/pages/app';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';

const Route: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH.APP_LIST,
        element: loadable(() => import('src/pages/app/AppList')),
      },
      {
        path: PATH.APP_CREATE,
        element: loadable(() => import('src/pages/app/AppCreate')),
      },
      {
        path: PATH.APP_Detail,
        element: loadable(() => import('src/pages/app/AppDetail')),
      },
    ],
  },
];

export default Route;
