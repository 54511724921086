import HTTPRequest from '../index';

const apiPath = '/v1/apps';
import _ from 'lodash';

export interface GetAppDetail {
  id: string;
}

export interface CreateApp {
  appDescription: string;
  appLabelText: string;
  appAndroidUrl: string;
  appIosUrl: string;
  appDesktopUrl: string;
  order: string;
  hoodId: string | any;
  city: string | any;
  country: string | any;
  state: string | any;
  hoodCategory: string;
  appIcon: string;
  status: string;
  category: string;
  isBusinessOnly: string;
  latitude: string;
  longitude: string;
}

export interface UpdateApp extends Omit<CreateApp, 'hoodId'> {
  id: string;
  hoodId: { label: string; id: string };
}

class AppRequest extends HTTPRequest {
  getList(params: Payload.AppList) {
    return this.get<any>(apiPath, { params });
  }

  create(data: CreateApp) {
    let formData = new FormData();
    formData.append('appIcon', data.appIcon);
    formData.append('appDescription', data.appDescription);
    formData.append('appLabelText', data.appLabelText);
    formData.append('appAndroidUrl', data.appAndroidUrl);
    formData.append('appIosUrl', data.appIosUrl);
    formData.append('appDesktopUrl', data.appDesktopUrl);
    formData.append('order', data.order.toString());
    if (!_.isEmpty(data.hoodId)) {
      data.hoodId.map((item) => {
        if (!_.isEmpty(item.id)) {
          formData.append('hoodId[]', item.id);
        }
      });
    }
    if (!_.isEmpty(data.city)) {
      data.city.map((item) => {
        formData.append('city[]', item);
      });
    }
    if (!_.isEmpty(data.country)) {
      data.country.map((item) => {
        formData.append('country[]', item);
      });
    }
    if (!_.isEmpty(data.state)) {
      data.state.map((item) => {
        formData.append('state[]', item);
      });
    }
    formData.append('hoodCategory', data.hoodCategory);
    formData.append('status', data.status);
    formData.append('category', data.category);
    formData.append('isBusinessOnly', data.isBusinessOnly);
    formData.append('latitude', data.latitude);
    formData.append('longitude', data.longitude);
    return this.post(`${apiPath}/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deleteApp(id: string) {
    return this.delete(`${apiPath}/${id}`);
  }

  getDetailAds(id: string) {
    return this.get<Response.AppDetailResponse>(`${apiPath}/${id}`);
  }

  update(data: any) {
    let formData = new FormData();
    formData.append('appDescription', data.appDescription);
    formData.append('appLabelText', data.appLabelText);
    formData.append('appAndroidUrl', data.appAndroidUrl);
    formData.append('appIosUrl', data.appIosUrl);
    formData.append('appDesktopUrl', data.appDesktopUrl);
    formData.append('order', data.order.toString());
    if (data.appIcon) {
      formData.append('appIcon', data.appIcon);
    }
    if (!_.isEmpty(data.hoodId)) {
      data.hoodId.map((item) => {
        if (!_.isEmpty(item.id)) {
          formData.append('hoodId[]', item.id);
        }
      });
    }
    if (!_.isEmpty(data.city)) {
      data.city.map((item) => {
        formData.append('city[]', item);
      });
    }
    if (!_.isEmpty(data.country)) {
      data.country.map((item) => {
        formData.append('country[]', item);
      });
    }
    if (!_.isEmpty(data.state)) {
      data.state.map((item) => {
        formData.append('state[]', item);
      });
    }
    formData.append('hoodCategory', data.hoodCategory);
    formData.append('status', data.status);
    formData.append('category', data.category);
    formData.append('isBusinessOnly', data.isBusinessOnly);
    formData.append('latitude', data.latitude);
    formData.append('longitude', data.longitude);

    return this.put(`${apiPath}/${data.id}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new AppRequest();
