import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';

import history from 'src/libs/history';

const useQueryUrl = <
  T extends SearchParams.RequestParams & Record<string, any>,
>(
  query?: T,
) => {
  const location = useLocation();

  useLayoutEffect(() => {
    if (query && !location.search) {
      history.replace({
        search: qs.stringify({ ...query }),
      });
    }
  }, [location.search]);

  const search = qs.parse(location.search, {
    arrayFormatSeparator: '|',
    arrayFormat: 'bracket-separator',
  }) as unknown;

  return search as T;
};

export default useQueryUrl;
