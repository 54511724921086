import HTTPRequest from '../index';

const apiPath = '/v1/comments';

class UserRequest extends HTTPRequest {
  getList(params: Payload.CommentList) {
    return this.get<Response.CommentListResponse>(apiPath, { params });
  }

  deleteComment({ id }: any) {
    return this.delete(`${apiPath}/${id}`);
  }
}

export default new UserRequest();
