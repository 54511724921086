import HTTPRequest from '../index';

const apiPath = '/v1/hoods/images';

const DEFAULT_TAKE = '20';

class HoodImageRequest extends HTTPRequest {
  getList(params: Payload.HoodList) {
    return this.get<any>(apiPath, { params });
  }

  getHoodImageDetailList(hoodId: string, params: Payload.HoodList) {
    const urlApi = `/v1/hoods/${hoodId}/images`;
    return this.get<any>(urlApi, { params });
  }

  deleteImage(id: string) {
    return this.delete(`${apiPath}/${id}`);
  }

  updateStatus(id: string, data: {}) {
    return this.put(`${apiPath}/${id}`, data);
  }
}

export default new HoodImageRequest();
