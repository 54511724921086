import React from 'react';
import Box from '@mui/material/Box';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { useController, UseControllerProps } from 'react-hook-form';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
} from '@mui/material';

type Props<T> = {
  radioGroupProps?: RadioGroupProps;
  controller: UseControllerProps<T>;
  label?: string;
  errorCol?: 12 | 24;
  options: {
    label: string | React.ReactElement;
    value: string | number;
  }[];
};

function RadioGroupField<T extends Record<string, any>>(props: Props<T>) {
  const { radioGroupProps, controller, label, options } = props;
  const { fieldState, field } = useController<T>(controller);
  const { error } = fieldState;
  return (
    <Box
      component="div"
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
    >
      <Grid container spacing={1}>
        {label && <InputLabel>{label}</InputLabel>}
        <RadioGroup
          style={{ width: '100%' }}
          {...field}
          {...radioGroupProps}
          row
        >
          {options.map((item, index) => {
            return (
              <FormControlLabel
                key={index}
                value={item.value}
                label={item.label}
                control={<Radio size="small" />}
              />
            );
          })}
        </RadioGroup>
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </Grid>
    </Box>
  );
}

export default RadioGroupField;
