import React from 'react';

import Container from 'src/containers/CustomMaps/Create';
import { ContentWrapper } from 'src/components/common';

const Page: React.FC = () => {
  return (
    <ContentWrapper title={'Custom Maps Create'}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/custom-maps-create' as const;
export default Page;
