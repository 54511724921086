import React from 'react';

import { PATH as PATH_FACEBOOK } from 'src/pages/facebook';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';

const Route: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH_FACEBOOK.LIST,
        element: loadable(() => import('src/pages/facebook/FacebookList')),
      },
      {
        path: PATH_FACEBOOK.DETAIL,
        element: loadable(() => import('src/pages/facebook/FacebookDetail')),
      },
      {
        path: PATH_FACEBOOK.CREATE,
        element: loadable(() => import('src/pages/facebook/FacebookCreate')),
      },
    ],
  },
];

export default Route;
