import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from 'src/libs/history';
import qs from 'query-string';

export enum Status {
  all = 'all',
  pending_approval = 'pending_approval',
  active = 'active',
  paused = 'paused',
  archived = 'archived',
}

export type FormValues = {
  status: Status;
  headline?: string;
};

const useFormSearch = () => {
  const validationSchema = Yup.object().shape({});

  const defaultValues: FormValues = {
    status: Status.all,
  };

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const handleSearch = () => {
    form.handleSubmit((data) => {
      history.push({
        search: qs.stringify({
          ...data,
          page: 1,
        }),
      });
    })();
  };

  return { form, handleSearch };
};

export default useFormSearch;
