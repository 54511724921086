import {
  USER_SEARCH_PERIOD,
  USER_SEARCH_TYPE,
  USER_GENDER,
} from 'src/enums/user';

export * from './common';

export const user_search_type: App.OptionsFnc = (t) => [
  {
    label: t('option.user_name'),
    value: USER_SEARCH_TYPE.USER_NAME,
  },
  { label: t('option.phone'), value: USER_SEARCH_TYPE.PHONE },
  { label: t('option.email'), value: USER_SEARCH_TYPE.EMAIL },
];

export const user_search_period: App.OptionsFnc = (t) => [
  {
    label: t('option.created_date'),
    value: USER_SEARCH_PERIOD.CREATED_DATE,
  },
  { label: t('option.quited_date'), value: USER_SEARCH_PERIOD.QUITED_DATE },
];

export const user_gender: App.OptionsFnc = (t) => [
  {
    label: t('option.gender_male'),
    value: USER_GENDER.MALE,
  },
  { label: t('option.gender_female'), value: USER_GENDER.FEMALE },
];
