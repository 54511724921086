import React from 'react';
import { useRoutes } from 'react-router';
import { CssBaseline } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/lab';

import { SidebarProvider } from 'src/theme/SidebarContext';
import ThemeProvider from 'src/theme/ThemeProvider';
import routeConfig from 'src/routes';
import { ConfirmProvider } from 'material-ui-confirm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const routes = useRoutes(routeConfig);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ConfirmProvider>
        <SidebarProvider>
          <ThemeProvider>
            <CssBaseline />
            {routes}
          </ThemeProvider>
        </SidebarProvider>
        <ToastContainer />
      </ConfirmProvider>
    </LocalizationProvider>
  );
}

export default App;
