import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAppSelector } from 'src/store';
import { useAppDispatch } from 'src/store';
import { updatePost } from 'src/useCase/postDetail';

export type UpdatePost = {
  content: string;
};

const useForm = () => {
  const dispatch = useAppDispatch();
  const validationSchema = Yup.object().shape({
    content: Yup.string().required('content is required'),
  });
  const { entity } = useAppSelector((state) => state.domain.post.detail);

  const defaultValue: UpdatePost = {
    content: String(entity?.content),
  };

  const formik = useFormik<UpdatePost>({
    initialValues: {
      ...defaultValue,
    },
    validationSchema,
    async onSubmit(values, meta) {
      dispatch(updatePost({ id: String(entity?.id), ...values }));
    },
    enableReinitialize: true,
  });

  return { formik };
};

export default useForm;
