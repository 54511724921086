import {
  Grid,
  styled,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Select,
  FormControl,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'src/components/common';
import useForm from './useForm';
import history from 'src/libs/history';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAppDispatch } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';
import { searchUsers } from 'src/useCase/userList';
import { internalFunctionCode } from 'src/constants/optionsSelect/common';

type Props = {
  hoods: {
    entities: Response.HoodEntity[];
    loading: boolean;
  };
  users: {
    entities: Response.UserEntity[];
    loading: boolean;
  };
};

const FormCreate: React.FC<Props> = ({ hoods, users }) => {
  const [category, setCategory] = React.useState('');
  const [bannerId, setBannerId] = React.useState('');
  const [getStatus, setStatus] = React.useState('');
  const dispatch = useAppDispatch();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const loadingHood = hoods.loading;
  const loadingUser = users.loading;
  const { formik } = useForm(category);
  const refBannerMobileLeaderBoard = useRef<File | null>(null);
  const refBannerWebLeaderBoard = useRef<File | null>(null);
  // const refBannerLargeLeaderBoard = useRef<File | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const [openBannerId, setOpenBannerId] = React.useState(false);
  const [openInternalCode, setOpenInternalCode] = React.useState(false);
  const [internalCode, setInternalCode] = React.useState('');
  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    isSubmitting,
  } = formik;
  const { t } = useTranslation();

  const handleChangeSelectCategory = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
    setFieldValue('hoodCategory', event.target.value);
  };

  const handleChangeSelectBannerId = (event: SelectChangeEvent) => {
    setBannerId(event.target.value);
    setFieldValue('bannerId', event.target.value);
  };

  const handleChangeSelectStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setFieldValue('status', event.target.value);
  };

  const handleChangeSelectInternalCode = (event: SelectChangeEvent) => {
    setInternalCode(event.target.value);
    setFieldValue('bannerInternalFunctionCode', event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseBannerId = () => {
    setOpenBannerId(false);
  };

  const listHood = useMemo(() => {
    if (openHood) {
      return hoods.entities.map((v) => {
        return {
          label: v.name,
          id: v.id,
        };
      });
    }
  }, [hoods.entities, openHood]);

  const listUser = useMemo(() => {
    if (openUser && users.entities.length) {
      return users.entities.map((v) => {
        return {
          label: v.nickName,
          id: v.id,
        };
      });
    }
  }, [users.entities, openUser]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenBannerId = () => {
    setOpenBannerId(true);
  };

  const handleOpenInternalCode = () => {
    setOpenInternalCode(true);
  };

  const handleCloseInternalCode = () => {
    setOpenInternalCode(false);
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const categories = [
    {
      value: 'HOME',
      text: 'Home',
    },
    {
      value: 'WORK',
      text: 'Work',
    },
    {
      value: 'FAMILY',
      text: 'Family',
    },
    {
      value: 'HOLIDAY',
      text: 'Holiday',
    },
    {
      value: 'EVENT',
      text: 'Event',
    },
    {
      value: 'DREAM',
      text: 'Dream',
    },
    {
      value: 'OTHER',
      text: 'Other',
    },
  ];

  const bannerIds = [
    {
      value: 'help',
      text: 'Help',
    },
    {
      value: 'questionnaire',
      text: 'Questionnaire',
    },
    {
      value: 'alert',
      text: 'Alert',
    },
    {
      value: 'invite',
      text: 'Invite',
    },
    {
      value: 'question',
      text: 'Question',
    },
    {
      value: 'notification',
      text: 'Notification',
    },
    {
      value: 'party',
      text: 'Party',
    },
  ];

  const status = [
    {
      value: 'pending_approval',
      text: 'Pending approval',
    },
    {
      value: 'active',
      text: 'Active',
    },
    {
      value: 'paused',
      text: 'Paused',
    },
    {
      value: 'archived',
      text: 'Archived',
    },
  ];

  const [searchHood, setSearchHood] = useState<string>('');

  const [searchUser, setSearchUser] = useState<string>('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchHood) {
        dispatch(searchHoods(searchHood));
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchHood]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchUser) {
        dispatch(searchUsers(searchUser));
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchUser]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={5}>
              <FormControl sx={{ m: 1, minWidth: '100%', margin: 0 }}>
                <InputLabel id="controlled-open-select-label">
                  {t('banner_fields.bannerId')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-banner-id-label"
                  id="controlled-open-select-banner-id"
                  open={openBannerId}
                  onClose={handleCloseBannerId}
                  onOpen={handleOpenBannerId}
                  value={bannerId}
                  name="bannerId"
                  label={t('banner_fields.bannerId')}
                  onChange={handleChangeSelectBannerId}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {bannerIds.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
                {touched.bannerId && Boolean(errors.bannerId) && (
                  <FormHelperText className="Mui-error">
                    {errors.bannerId}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="url"
                name="url"
                label={t('ads_fields.url')}
                onChange={handleChange}
                error={touched.url && Boolean(errors.url)}
                helperText={touched.url && errors.url}
              />
            </Grid>

            <Grid item xs={10}>
              <FormControl sx={{ m: 1, minWidth: '100%', margin: 0 }}>
                <InputLabel id="controlled-open-internal-code-select-label">
                  {t('banner_fields.bannerInternalCode')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-internal-code-select-label"
                  id="controlled-open-internal-code-select"
                  open={openInternalCode}
                  onClose={handleCloseInternalCode}
                  onOpen={handleOpenInternalCode}
                  name="bannerInternalFunctionCode"
                  value={internalCode}
                  label={t('banner_fields.bannerInternalCode')}
                  onChange={handleChangeSelectInternalCode}
                >
                  {internalFunctionCode.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={5}>
              <Autocomplete
                multiple
                id="tags"
                options={[]}
                freeSolo
                onChange={(e, v) => {
                  setFieldValue('tags', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={'Hashtag'}
                    error={touched.tags && Boolean(errors.tags)}
                    helperText={touched.tags && errors.tags}
                    placeholder={'Please enter hashtag'}
                  />
                )}
              />
            </Grid>

            {/* Image 320 x 50 ?��? Mobile Leaderboard */}
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="bannerMobileLeaderBoard"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      refBannerMobileLeaderBoard.current =
                        event.currentTarget.files[0];
                      setFieldValue(
                        'bannerMobileLeaderBoard',
                        event.currentTarget.files[0],
                      );
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {touched.bannerMobileLeaderBoard &&
                errors.bannerMobileLeaderBoard && (
                  <FormHelperText className="Mui-error">
                    {errors.bannerMobileLeaderBoard}
                  </FormHelperText>
                )}
              <img
                width={320}
                height="auto"
                src={
                  refBannerMobileLeaderBoard.current
                    ? URL.createObjectURL(refBannerMobileLeaderBoard.current)
                    : 'https://placehold.jp/320x50.png?text=320+x+50+~+320+x+300'
                }
              />
            </Grid>

            {/* Image 728 x 90 ?��? Leaderboard Banner (Web) */}
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="bannerWebLeaderBoard"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      refBannerWebLeaderBoard.current =
                        event.currentTarget.files[0];
                      setFieldValue(
                        'bannerWebLeaderBoard',
                        event.currentTarget.files[0],
                      );
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {touched.bannerWebLeaderBoard && errors.bannerWebLeaderBoard && (
                <FormHelperText className="Mui-error">
                  {errors.bannerWebLeaderBoard}
                </FormHelperText>
              )}
              <img
                width={728}
                height={90}
                src={
                  refBannerWebLeaderBoard.current
                    ? URL.createObjectURL(refBannerWebLeaderBoard.current)
                    : 'https://placehold.jp/728x90.png'
                }
              />
            </Grid>

            <Grid item xs={5}>
              <Autocomplete
                id="combo-box-demo"
                options={listUser || []}
                onChange={(e, v) => {
                  setFieldValue('userId', v?.id);
                }}
                onInputChange={(e: any) => setSearchUser(e.target?.value)}
                loading={loadingUser}
                onOpen={() => setOpenUser(true)}
                onClose={() => setOpenUser(false)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    name="userId"
                    {...params}
                    label={t('banner_fields.userId')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingUser ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              {touched.userId && Boolean(errors.userId)}
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="textColor"
                name="textColor"
                label={t('banner_fields.bannerTextColor')}
                onChange={handleChange}
                error={touched.textColor && Boolean(errors.textColor)}
                helperText={touched.textColor && errors.textColor}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                type="text"
                id="bannerText"
                name="bannerText"
                label={t('banner_fields.bannerText')}
                onChange={handleChange}
                error={touched.bannerText && Boolean(errors.bannerText)}
                helperText={touched.bannerText && errors.bannerText}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                value={values.dateFrom}
                label="Date From"
                onChange={(value) => {
                  setFieldValue('dateFrom', value?.toString(), true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="dateFrom"
                    name="dateFrom"
                    label="Date From"
                    error={touched.dateFrom && Boolean(errors.dateFrom)}
                    helperText={touched.dateFrom && errors.dateFrom}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                value={values.dateTo}
                label="Date To"
                onChange={(value) => {
                  setFieldValue('dateTo', value?.toString(), true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="dateTo"
                    name="dateTo"
                    label="Date To"
                    error={touched.dateTo && Boolean(errors.dateTo)}
                    helperText={touched.dateTo && errors.dateTo}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                id="combo-box-demo"
                options={listHood || []}
                onChange={(e, v) => {
                  setFieldValue('hoodId', v?.id);
                }}
                onInputChange={(e: any) => setSearchHood(e.target?.value)}
                loading={loadingHood}
                onOpen={() => setOpenHood(true)}
                onClose={() => setOpenHood(false)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    name="hoodId"
                    {...params}
                    label={t('ads_fields.hood')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingHood ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              {touched.hoodId && Boolean(errors.hoodId)}
            </Grid>
            <Grid item xs={5}>
              <FormControl sx={{ m: 1, minWidth: '100%', margin: 0 }}>
                <InputLabel id="controlled-open-select-label">
                  {t('ads_fields.hoodCategory')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-label"
                  id="controlled-open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  name="hoodCategory"
                  value={category}
                  label={t('ads_fields.hoodCategory')}
                  onChange={handleChangeSelectCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories.map((category, index) => {
                    return (
                      <MenuItem key={index} value={category.value}>
                        {category.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
                id="city"
                name="city"
                label={t('ads_fields.city')}
                onChange={handleChange}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="text"
                id="country"
                name="country"
                label={t('ads_fields.country')}
                onChange={handleChange}
                error={touched.country && Boolean(errors.country)}
                helperText={touched.country && errors.country}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="text"
                id="state"
                name="state"
                label={t('ads_fields.state')}
                onChange={handleChange}
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state && errors.state}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="language"
                name="language"
                label={t('ads_fields.language')}
                onChange={handleChange}
                error={touched.language && Boolean(errors.language)}
                helperText={touched.language && errors.language}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl
                sx={{ m: 1, minWidth: '100%', margin: 0 }}
                error={touched.status && Boolean(errors.status)}
              >
                <InputLabel id="controlled-open-select-status-label">
                  {t('ads_fields.status')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-status-label"
                  id="controlled-open-select-status"
                  open={openStatus}
                  onClose={handleCloseStatus}
                  onOpen={handleOpenStatus}
                  value={getStatus}
                  name="status"
                  label={t('ads_fields.status')}
                  onChange={handleChangeSelectStatus}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {status.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
                {touched.status && Boolean(errors.status) && (
                  <FormHelperText>{errors.status}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                style={{ marginTop: 22 }}
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Create
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormCreate;
