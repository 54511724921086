import React, { useEffect } from 'react';
import { ContentWrapper } from 'src/components/common';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import Container from 'src/containers/AdsReport';
import { PER } from 'src/constants/app';
import { fetchReportResources } from 'src/useCase/ads';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import qs from 'query-string';
import history from 'src/libs/history';

const Page: React.FC = () => {
  const query = useQueryUrl();
  const { page, take, type, filter } = query;
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState(filter);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
    history.push({
      pathname: 'ads_report',
      search: qs.stringify({
        filter: event.target.value,
        type: type,
      }),
    });
  };

  useEffect(() => {
    dispatch(
      fetchReportResources({
        ...query,
        page: page,
        take: take || String(PER.DEFAULT),
      }),
    );
  }, [query]);

  return (
    <ContentWrapper
      title={'Ads Report'}
      extra={
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="controlled-open-select-label"
            id="controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={category}
            label="Time"
            onChange={handleChangeSelect}
          >
            <MenuItem value="day">
              <em>Day</em>
            </MenuItem>
            <MenuItem value="week">
              <em>Week</em>
            </MenuItem>
            <MenuItem value="month">
              <em>Month</em>
            </MenuItem>
          </Select>
        </FormControl>
      }
    >
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/ads_report' as const;
export default Page;
