import React from 'react';

import { PATH } from 'src/pages/auth';
import RestrictLayout from 'src/components/application/RestrictLayout';
import loadable from 'src/utils/loadable';
import { t } from 'src/libs/i18n';
import { IRouter } from 'src/interfaces/route';

const AuthRoute: IRouter[] = [
  {
    element: <RestrictLayout />,
    children: [
      {
        path: PATH.SIGN_IN,
        element: loadable(() => import('src/pages/auth/SignIn')),
        title: t('sign_in.page_title'),
      },
    ],
  },
];

export default AuthRoute;
