import React from 'react';

import { PATH } from 'src/pages/post';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';

const Route: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH.POST_LIST,
        element: loadable(() => import('src/pages/post/PostList')),
      },
      {
        path: PATH.POST_DETAIL,
        element: loadable(() => import('src/pages/post/PostDetail')),
      },
    ],
  },
];

export default Route;
