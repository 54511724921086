import React, { useEffect } from 'react';

import Container from 'src/containers/UserDetail';
import { ContentWrapper } from 'src/components/common';
import { t } from 'src/libs/i18n';
import { useParams } from 'react-router';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { fetchUserDetail } from 'src/useCase/userDetail';

const Page: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const query = useQueryUrl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUserDetail({ id: String(id) }));
  }, [query]);
  return (
    <ContentWrapper title={t('title.user_detail')}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/user_list/:id' as const;
export default Page;
