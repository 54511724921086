import React from 'react';

import { PATH } from 'src/pages/customMaps';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';

const Route: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH.CUSTOM_MAPS_LIST_PATH,
        element: loadable(() => import('src/pages/customMaps/CustomMapsList')),
      },
      {
        path: PATH.CUSTOM_MAPS_CREATE_PATH,
        element: loadable(
          () => import('src/pages/customMaps/CustomMapsCreate'),
        ),
      },
      {
        path: PATH.CUSTOM_MAPS_DETAIL_PATH,
        element: loadable(
          () => import('src/pages/customMaps/CustomMapsDetail'),
        ),
      },
    ],
  },
];

export default Route;
