import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import qs from 'query-string';

import customMapsListActions from 'src/store/domain/customMaps/list';
import customMapsDetailActions from 'src/store/domain/customMaps/detail';
import customMapsRequest from 'src/api/requests/customMaps';
import localServiceRequest from 'src/api/requests/link';
import appActions from 'src/store/ui/app';
import history from 'src/libs/history';
import { CreateLinkByMapPayload } from 'src/api/requests/link';
import linkRequest from 'src/api/requests/link';

interface FetchResources extends SearchParams.Ads {}

const getParams = (args: FetchResources) => {
  const { take, page } = args;

  const params: Payload.AdsList = {
    ...args,
    take,
    page,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, any>(
  'get_action_custom_maps_list',
  async (args, { dispatch }) => {
    try {
      dispatch(customMapsListActions.updateLoading(true));

      const params = getParams(args);
      const res = await customMapsRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(customMapsListActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(customMapsListActions.updateLoading(false));
    } catch (err) {
      dispatch(customMapsListActions.updateLoading(false));
    }
  },
);

export const createResource = createAsyncThunk<void, any>(
  'create_action_custom_maps',
  async (body, { dispatch }) => {
    try {
      const res = await customMapsRequest.create(body);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);

export const deleteCustomMaps = createAsyncThunk<void, string>(
  'delete_action_custom_maps',
  async (id, { dispatch }) => {
    try {
      const res = await customMapsRequest.remove(id);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');

        await dispatch(
          fetchResources(
            getParams(qs.parse(history.location.search) as FetchResources),
          ),
        );
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);

export const fetchDetailCustomMaps = createAsyncThunk<void, string>(
  'get_action_custom_maps_detail',
  async (id, { dispatch }) => {
    try {
      dispatch(customMapsDetailActions.updateLoading(true));
      const res = await customMapsRequest.detail(id);

      if (res?.data) {
        await dispatch(customMapsDetailActions.updateEntity(res?.data));
      }
    } finally {
      dispatch(customMapsDetailActions.updateLoading(false));
    }
  },
);

export const updateCustomMaps = createAsyncThunk<void, any>(
  'update_action_custom_maps',
  async (values, { dispatch }) => {
    try {
      const res = await customMapsRequest.update(values);
      if (res.status != 200) {
        toast.error('There was an error updating');
      } else {
        toast.success('Successfully!');
        await dispatch(fetchResources(values.id));
      }
    } catch (err) {
      console.log(err);
      toast.error('There was an error updating');
    }
  },
);

export const createLocalServicesByMap = createAsyncThunk<
  void,
  CreateLinkByMapPayload
>('custom_maps_create_local_services', async (body, { dispatch }) => {
  try {
    const res = await localServiceRequest.createByMap(body);
    if (res.status != 200) {
      toast.error('There was an error creating');
    } else {
      toast.success('Successfully!');
    }
    dispatch(fetchDetailCustomMaps(body.id));
  } catch (err) {
    toast.error('There was an error creating');
  }
});

export const deleteLocalServicesByMap = createAsyncThunk<void, any>(
  'custom_map_delete_local_service',
  async ({ localServiceId, mapId }, { dispatch }) => {
    try {
      const res = await linkRequest.deleteLink(localServiceId);
      if (res.status != 200) {
        toast.error('There was an error delete');
      } else {
        toast.success('Successfully!');
        dispatch(fetchDetailCustomMaps(mapId));
      }
    } catch (err) {
      toast.error('There was an error delete');
    }
  },
);
