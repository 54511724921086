import { Grid, styled, CircularProgress, IconButton } from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { Card, MapLink } from 'src/components/common';
import useForm, { FormValues } from './useForm';
import history from 'src/libs/history';
import {
  AutoCompleteField,
  DatePickerField,
  InputField,
} from 'src/components/form';
import SelectField from 'src/components/form/SelectField';
import { useAppDispatch, useAppSelector } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';
import { searchUsers } from 'src/useCase/userList';
import { PhotoCamera } from '@mui/icons-material';
import { isValidHttpUrl } from 'src/utils/helpers';

type Props = {
  hoods: {
    entities: Response.HoodEntity[];
    loading: boolean;
  };
  users: {
    entities: Response.UserEntity[];
    loading: boolean;
  };
  entity: Response.ActivityDetailEntity | null;
  loading: boolean;
};

const FormDetail: React.FC<Props> = (props) => {
  const { hoods, entity, loading, users } = props;

  const { form, submitForm } = useForm(entity, hoods.entities, users.entities);
  const { control, formState, setValue } = form;
  const { t } = useTranslation();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const valueHood = useAppSelector((state) => state.domain.hood.hoods);
  const valueUser = useAppSelector((state) => state.domain.user.list);
  const [refPhoto, setRefPhoto] = React.useState<File | null>(null);

  useEffect(() => {
    if (entity) {
      (async () => {
        if (entity.hood) {
          await dispatch(searchHoods(entity?.hood?.name));
        } else {
          await dispatch(searchHoods(''));
        }

        if (entity.userId) {
          await dispatch(searchUsers(entity?.user?.nick_name));
        } else {
          await dispatch(searchUsers(''));
        }
        // @ts-ignore
      })();
    }
  }, [entity]);

  const getHoods = useMemo(() => {
    return valueHood.entities.map((v) => {
      return {
        label: String(v.name),
        id: String(v.id),
      };
    });
  }, [hoods]);

  const getUsers = useMemo(() => {
    return valueUser.entities.map((v) => {
      return {
        label: v.nickName ? String(v.nickName) : String(v.id),
        id: String(v.id),
      };
    });
  }, [users]);

  const categories = [
    {
      value: 'sports_and_fitness',
      label: 'Sports & Fitness',
    },
    {
      value: 'culture_and_music',
      label: 'Culture & Music',
    },
    {
      value: 'buy_and_sell',
      label: 'Buy & Sell',
    },
    {
      value: 'community_fun',
      label: 'Community fun',
    },
    {
      value: 'voluntary_work',
      label: 'Voluntary work',
    },
    {
      value: 'kids_family',
      label: 'Kids & Family',
    },
    {
      value: 'other',
      label: 'Other activities',
    },
    {
      value: 'attractions',
      label: 'Attractions',
    },
    {
      value: 'food_and_restaurants',
      label: 'Food And Restaurants',
    },
    {
      value: 'shopping',
      label: 'Shopping',
    },
  ];

  const status = [
    {
      value: 'pending_approval',
      label: 'Pending approval',
    },
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'paused',
      label: 'Paused',
    },
    {
      value: 'archived',
      label: 'Archived',
    },
  ];

  return (
    <Wrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'headline' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={t('activity_fields.headline')}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField<FormValues>
                controller={{ control, name: 'category' }}
                options={categories}
                selectProps={{
                  defaultValue: entity?.category,
                }}
                label={t('activity_fields.category')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <AutoCompleteField<FormValues>
                controller={{ control, name: 'hoodId' }}
                options={getHoods || []}
                onOpen={() => setOpenHood(!openHood)}
                onClose={() => setOpenHood(!openHood)}
                loading={valueHood.loading}
                callbackOnchange={(v) => {
                  dispatch(searchHoods(v));
                }}
                label={t('activity_fields.hoodId')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <AutoCompleteField<FormValues>
                controller={{ control, name: 'userId' }}
                options={getUsers || []}
                onOpen={() => setOpenUser(!openUser)}
                onClose={() => setOpenUser(!openUser)}
                loading={valueUser.loading}
                callbackOnchange={(v) => {
                  dispatch(searchUsers(v));
                }}
                label={t('activity_fields.userId')}
                size="medium"
              />
            </Grid>
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="photo"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      setValue('photo', event.currentTarget.files[0], {
                        shouldDirty: true,
                      });
                      setRefPhoto(event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              <img
                width={320}
                height="auto"
                src={
                  refPhoto
                    ? URL.createObjectURL(refPhoto)
                    : isValidHttpUrl(entity?.photo || '')
                    ? entity?.photo
                    : `${process.env.REACT_APP_MEDIA_URL}${entity?.photo}`
                }
                loading="lazy"
                onError={(e) => {
                  if (e.currentTarget.src) {
                    e.currentTarget.title = 'no image';
                  }
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'time' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={t('activity_fields.time')}
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'place' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={t('activity_fields.place')}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField<FormValues>
                controller={{ control, name: 'latitude' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={t('activity_fields.latitude')}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField<FormValues>
                controller={{ control, name: 'longitude' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={t('activity_fields.longitude')}
              />
            </Grid>
            <Grid item xs={4}>
              <MapLink
                latitude={entity?.activityLocation.coordinates[1]}
                longitude={entity?.activityLocation.coordinates[0]}
              ></MapLink>
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'contact' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={t('activity_fields.contact')}
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'webLink' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={t('activity_fields.webLink')}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField<FormValues>
                controller={{ control, name: 'status' }}
                options={status}
                selectProps={{
                  defaultValue: entity?.status,
                }}
                label={t('activity_fields.status')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'moreInfo' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={t('activity_fields.moreInfo')}
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'registerUrl' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={'Register Url'}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePickerField<FormValues>
                controller={{ control, name: 'expirationDate' }}
                label="Expiration Date"
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="button"
                variant="contained"
                size="small"
                disabled={!formState.isDirty}
                style={{ marginTop: 22 }}
                loading={formState.isSubmitting}
                loadingPosition="start"
                startIcon={<ModeIcon />}
                onClick={submitForm}
              >
                {t('button.update')}
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormDetail;
