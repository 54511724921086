import { createAsyncThunk } from '@reduxjs/toolkit';

import postActions from 'src/store/domain/post/detail';
import postRequest, { GetPostDetail, UpdatePost } from 'src/api/requests/post';
import history from 'src/libs/history';

export const fetchDetailPost = createAsyncThunk<void, GetPostDetail>(
  'get_post_detail',
  async (payload, { dispatch }) => {
    try {
      dispatch(postActions.updateLoading(true));

      const res = await postRequest.getDetail(payload);

      const { data } = res;

      if (data) {
        dispatch(postActions.updateEntity(data));
      }

      dispatch(postActions.updateLoading(false));
    } catch (err) {
      dispatch(postActions.updateLoading(false));
    }
  },
);

export const deletePost = createAsyncThunk<void, GetPostDetail>(
  'delete_post_detail',
  async (payload, { dispatch }) => {
    try {
      dispatch(postActions.updateLoading(true));

      await postRequest.deletePost(payload);
      dispatch(postActions.updateLoading(false));
      history.push('/post_list');
    } catch (err) {
      dispatch(postActions.updateLoading(false));
    }
  },
);

export const updatePost = createAsyncThunk<void, UpdatePost>(
  'update_post_detail',
  async (payload, { dispatch }) => {
    try {
      dispatch(postActions.updateLoading(true));

      await postRequest.updatePost(payload);
      dispatch(postActions.updateLoading(false));
    } catch (err) {
      dispatch(postActions.updateLoading(false));
    }
  },
);
