import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import qs from 'query-string';

import actionBannerListActions from 'src/store/domain/actionBanner/list';
import bannerRequest from 'src/api/requests/actionBanner';
import appActions from 'src/store/ui/app';
import history from 'src/libs/history';

interface FetchResources extends SearchParams.Ads {}

const getParams = (args: FetchResources) => {
  const { take, page } = args;

  const params: Payload.AdsList = {
    ...args,
    take,
    page,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, any>(
  'get_action_banner_list',
  async (args, { dispatch }) => {
    try {
      dispatch(actionBannerListActions.updateLoading(true));

      const params = getParams(args);
      const res = await bannerRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(actionBannerListActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(actionBannerListActions.updateLoading(false));
    } catch (err) {
      dispatch(actionBannerListActions.updateLoading(false));
    }
  },
);

export const createResource = createAsyncThunk<void, any>(
  'create_action_banner',
  async (body, { dispatch }) => {
    try {
      const res = await bannerRequest.create(body);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);

export const deleteActionBanner = createAsyncThunk<void, string>(
  'delete_action_banner',
  async (id, { dispatch }) => {
    try {
      const res = await bannerRequest.delete(id);
      if (res.status != 200) {
        toast.error('There was an error creating');
      } else {
        toast.success('Successfully!');

        await dispatch(
          fetchResources(
            getParams(qs.parse(history.location.search) as FetchResources),
          ),
        );
      }
    } catch (err) {
      toast.error('There was an error creating');
    }
  },
);
