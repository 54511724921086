import React from 'react';
import styled from 'styled-components';

import RowDetail from 'src/components/common/RowDetail';
import { SelectField } from 'src/components/form';
import useFormSearch, { Status } from './useForm';
import { Button } from '@mui/material';

const optionStatus = [
  { label: 'All', value: Status.all },
  { label: 'Pending approval', value: Status.pending_approval },
  { label: 'Active', value: Status.active },
  { label: 'Paused', value: Status.paused },
  { label: 'Archived', value: Status.archived },
];

const Search = () => {
  const { form, handleSearch } = useFormSearch();
  const { control } = form;

  return (
    <Wrapper>
      <RowDetail
        title="Status"
        marginBottom={0}
        layout={{
          actionsCol: {
            xs: 3,
          },
          titleCol: {
            xs: 1,
          },
        }}
        style={{
          alignItems: 'baseline',
        }}
        value={
          <SelectField
            controller={{
              control,
              name: 'status',
            }}
            size={'small'}
            options={optionStatus}
          />
        }
        actions={
          <BtnSearch variant="contained" fullWidth onClick={handleSearch}>
            Search
          </BtnSearch>
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: white;
  padding: 16px;
  margin: 24px 24px 0;
  border-radius: 10px;
`;

const BtnSearch = styled(Button)`
  margin-left: 100px;
`;

export default Search;
