import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import datahubRequest from 'src/api/requests/datahub';

export const syncData = createAsyncThunk<void>(
  'sync_datahub',
  async (body, { dispatch }) => {
    try {
      const res = await datahubRequest.create();
      if (res.status != 200) {
        toast.error('There was an error');
      } else {
        toast.success('Start syncing data please check the data!');
      }
    } catch (err) {
      toast.error('There was an error');
    }
  },
);
