import React from 'react';

import FormCreate from './Form';

type Props = {
  entities: Response.HoodEntity[];
  loading: boolean;
};

const Main: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <FormCreate loading={props.loading} hoods={props.entities} />
    </React.Fragment>
  );
};

export default Main;
