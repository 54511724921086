import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Button, Grid, LinearProgress, CardMedia, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReportIcon from '@mui/icons-material/Report';

import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME } from 'src/constants/app';
import { useConfirm } from 'material-ui-confirm';
import { useAppDispatch } from 'src/store';
import { deleteAds } from 'src/useCase/ads';
import history from 'src/libs/history';
import qs from 'query-string';

type Props = {
  loading: boolean;
  entities: any[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const handleConfirm = (id: string) => {
    confirm({ description: t('des.delete_ads') }).then(async () => {
      await dispatch(deleteAds(id));
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 310,
    },
    {
      field: 'title',
      headerName: 'Title',
      sortable: false,
      width: 200,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    // {
    //   field: 'content',
    //   headerName: 'Content',
    //   sortable: false,
    //   width: 400,
    //   valueGetter: ({ value }) => {
    //     return value || '-';
    //   },
    // },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 400,
      valueGetter: ({ value }) => {
        switch (value) {
          case 'pending_approval':
            return 'Pending approval';
          case 'active':
            return 'Active';
          case 'paused':
            return 'Paused';
          default:
            return 'Archived';
        }
      },
    },
    {
      field: 'image',
      headerName: 'Image',
      sortable: false,
      width: 300,
      renderCell: ({ row }) => {
        return (
          <CardMedia
            component="img"
            height="150"
            image={`${process.env.REACT_APP_MEDIA_URL}${row.bannerMobileLeaderBoard}`}
          />
        );
      },
    },
    // {
    //   field: 'buttonText',
    //   headerName: 'Button Text',
    //   sortable: false,
    //   width: 100,
    //   valueGetter: ({ value }) => {
    //     return value || '-';
    //   },
    // },

    {
      field: 'url',
      headerName: 'URL',
      sortable: false,
      width: 400,
      renderCell: ({ row }) => {
        return (
          <a href={row?.url} target="_blank" rel="noreferrer">
            {row?.url}
          </a>
        );
      },
    },
    {
      field: 'dateFrom',
      headerName: t('dateFrom'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'dateTo',
      headerName: t('dateTo'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'createdAt',
      headerName: t('createdAt'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      width: 350,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<EditIcon />}
                to={`/ads_list/${params.row.id}`}
                component={Link}
              >
                Detail
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleConfirm(params.row.id)}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<ReportIcon />}
                onClick={() => onClickReportByAdsId(params.row.id)}
              >
                Report
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const onclickReportAllAds = () => {
    history.push({
      pathname: 'ads_report',
      search: qs.stringify({
        filter: 'day',
        type: 'all',
      }),
    });
  };

  const onClickReportByAdsId = (adId) => {
    history.push({
      pathname: 'ads_report',
      search: qs.stringify({
        filter: 'day',
        type: adId,
      }),
    });
  };

  return (
    <Card>
      <div style={{ minHeight: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          autoHeight={true}
          rowHeight={150}
          {...tableProps}
        />

        <Box mt={5}>
          <Grid item xs={4}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<ReportIcon />}
              onClick={onclickReportAllAds}
            >
              Report All Ads
            </Button>
          </Grid>
        </Box>
      </div>
    </Card>
  );
};

export default UserTable;
