import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Button, Grid, LinearProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME } from 'src/constants/app';
import { useConfirm } from 'material-ui-confirm';
import { useAppDispatch } from 'src/store';
import { deleteAds } from 'src/useCase/ads';

type Props = {
  loading: boolean;
  entities: any[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const handleConfirm = (id: string) => {
    confirm({ description: t('des.delete_banner') }).then(async () => {
      await dispatch(deleteAds(id));
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 350,
    },
    {
      field: 'bannerId',
      headerName: 'Banner ID',
      sortable: false,
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 400,
      valueGetter: ({ value }) => {
        switch (value) {
          case 'pending_approval':
            return 'Pending approval';
          case 'active':
            return 'Active';
          case 'paused':
            return 'Paused';
          default:
            return 'Archived';
        }
      },
    },
    {
      field: 'bannerText',
      headerName: 'Banner Text',
      sortable: false,
      width: 300,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },

    {
      field: 'url',
      headerName: 'URL',
      sortable: false,
      width: 400,
      renderCell: ({ row }) => {
        return (
          <a href={row?.url} target="_blank" rel="noreferrer">
            {row?.url}
          </a>
        );
      },
    },
    {
      field: 'dateFrom',
      headerName: t('dateFrom'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'dateTo',
      headerName: t('dateTo'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'hoodCategory',
      headerName: 'Hood Category',
      sortable: false,
      width: 300,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      width: 350,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<EditIcon />}
                to={`/banner_detail/${params.row.id}`}
                component={Link}
              >
                Detail
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleConfirm(params.row.id)}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Card>
      <div style={{ minHeight: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          autoHeight={true}
          rowHeight={150}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default UserTable;
