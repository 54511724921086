import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Button, Grid, LinearProgress, CardMedia } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import 'external-svg-loader';
import _ from 'lodash';

import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME_HOUR } from 'src/constants/app';
import { useConfirm } from 'material-ui-confirm';
import { useAppDispatch } from 'src/store';
import { deleteEvent } from 'src/useCase/eventList';
import { getEventCategory } from 'src/utils/helpers';

type Props = {
  loading: boolean;
  entities: Response.EventListEntity[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const handleConfirm = (id: string) => {
    confirm({ description: t('des.delete_event') }).then(async () => {
      await dispatch(deleteEvent(id));
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 310,
    },
    {
      field: 'icon',
      headerName: 'ICON',
      sortable: false,
      width: 300,
      renderCell: ({ row }) => {
        if (_.isEmpty(row?.icon)) return '-';

        const isSvgImage = row?.icon ? row?.icon.includes('.svg') : false;

        return isSvgImage ? (
          <svg
            data-src={`${process.env.REACT_APP_MEDIA_URL}${row?.icon}`}
            fill="currentColor"
            height="100px"
            width="100%"
          />
        ) : (
          <CardMedia
            component="img"
            height="100"
            image={`${process.env.REACT_APP_MEDIA_URL}${row.icon}`}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      width: 200,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      sortable: false,
      width: 200,
      valueGetter: ({ value }) => {
        return value ? getEventCategory(value) : '-';
      },
    },
    {
      field: 'Location',
      headerName: 'Location',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        const isLocation = params.row?.location;
        const lat =
          isLocation && _.get(params.row, 'location.coordinates.[1]', '');
        const long =
          isLocation && _.get(params.row, 'location.coordinates.[0]', '');
        return (
          isLocation && (
            <a
              href={`http://maps.google.com/maps?q=${lat},${long}`}
              target="_blank"
              rel="noreferrer"
            >
              View Maps
            </a>
          )
        );
      },
    },
    {
      field: 'url',
      headerName: 'URL',
      sortable: false,
      width: 400,
      renderCell: ({ row }) => {
        return (
          <a href={row?.url} target="_blank" rel="noreferrer">
            {row?.url}
          </a>
        );
      },
    },
    {
      field: 'Hood',
      headerName: 'Hood Id',
      sortable: false,
      width: 400,
      renderCell: ({ row }) => {
        return row?.hoodId || '-';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        switch (value) {
          case 'pending_approval':
            return 'Pending approval';
          case 'active':
            return 'Active';
          case 'paused':
            return 'Paused';
          default:
            return 'Archived';
        }
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      sortable: false,
      width: 170,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME_HOUR) || '-';
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      sortable: false,
      width: 170,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME_HOUR) || '-';
      },
    },
    {
      field: 'createdAt',
      headerName: t('createdAt'),
      sortable: false,
      width: 170,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME_HOUR) || '-';
      },
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      width: 330,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<EditIcon />}
                to={`/event_list/${params.row.id}`}
                component={Link}
              >
                Detail
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleConfirm(params.row.id)}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Card>
      <div style={{ minHeight: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          autoHeight={true}
          rowHeight={150}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default UserTable;
