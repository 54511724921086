import React from 'react';
import Box from '@mui/material/Box';
import { useController, UseControllerProps } from 'react-hook-form';
import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';

type Props<T> = {
  autoCompleteProps?: AutocompleteProps<any, any, any, any>;
  controller: UseControllerProps<T>;
  label?: string;
  loading?: boolean;
  errorCol?: 12 | 24;
  options: Array<{
    label: string;
    id: string;
  }>;
  size?: 'small' | 'medium';
  onClose?: () => void;
  onOpen?: () => void;
  callbackOnchange?: (v) => void;
};

function AutoCompleteField<T extends Record<string, any>>(props: Props<T>) {
  const {
    autoCompleteProps,
    controller,
    label,
    options,
    size = 'small',
    loading = false,
    callbackOnchange,
    ...rest
  } = props;
  const { fieldState, field } = useController<T>(controller);
  const { error } = fieldState;

  const onChange = (e) => {
    const value = e.target.value;

    callbackOnchange && callbackOnchange(value);
  };

  return (
    <Box
      component="div"
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
    >
      <Grid container spacing={1}>
        {label && <InputLabel>{label}</InputLabel>}
        <Autocomplete
          {...autoCompleteProps}
          {...field}
          options={options}
          size={size}
          loading={loading}
          style={{ width: '100%', margin: 0 }}
          onChange={(e, v) => {
            field.onChange(v);
          }}
          onClose={rest.onClose}
          onOpen={rest.onOpen}
          renderInput={(params) => (
            <TextField
              style={{ width: '100%', margin: 0 }}
              {...params}
              InputProps={{
                ...params.InputProps,
                onChange: onChange,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </Grid>
    </Box>
  );
}

export default AutoCompleteField;
