import React from 'react';

import Main from './Main';
import { useAppSelector } from 'src/store';

const Container: React.FC = () => {
  const props = useAppSelector(
    (state) => state.domain.statistics.hoodAnalytics,
  );
  return <Main {...props} />;
};

export default Container;
