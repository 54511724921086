import { useEffect } from 'react';
import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch } from 'src/store';
import { updateFacebook } from '../../../../useCase/facebookDetail';

export type FormValue = {
  pageId: string;
  pageName: string;
  pageAccessToken: string;
  type: string;
  userId: string;
  hoodId: { label: string; id: string } | any;
  category: string;
};

type Arg = {
  entity: Response.FacebookEntity | null;
};

const useForm = (arg: Arg) => {
  const { entity } = arg;
  const dispatch = useAppDispatch();

  const schema = Yup.object().shape({});

  const defaultValues: FormValue = {
    pageId: '',
    pageAccessToken: '',
    type: 'create_post_fanpage',
    pageName: '',
    userId: '',
    hoodId: '',
    category: 'authority_alerts',
  };

  const formInfo = useHookForm<FormValue>({
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'onSubmit',
  });

  const onSubmit = async (values: FormValue) => {
    await dispatch(
      updateFacebook({
        id: entity?.id as string,
        data: {
          pageId: values.pageId,
          pageAccessToken: values.pageAccessToken,
          type: values.type,
          userId: values.userId,
          pageName: values.pageName,
          category: values.category,
          hoodId: typeof values.hoodId === 'object' ? values.hoodId.id : '',
        },
      }),
    );
  };

  useEffect(() => {
    formInfo.reset({
      pageId: entity?.pageId || '',
      pageName: entity?.pageName || '',
      pageAccessToken: entity?.pageAccessToken || '',
      type: entity?.type || 'create_post_fanpage',
      userId: entity?.userId || '',
      hoodId: {
        id: entity?.hood.id,
        label: entity?.hood.name,
      },
      category: entity?.category || 'authority_alerts',
    });
  }, [entity]);

  return { formInfo, onSubmit };
};

export default useForm;
