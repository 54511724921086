import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { Button, LinearProgress } from '@mui/material';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME } from 'src/constants/app';
import { PATH } from 'src/pages/post';

type Props = {
  loading: boolean;
  entities: Response.PostEntity[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 150,
    },
    {
      field: 'image',
      headerName: t('image'),
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return params.row.postMedias.length > 0 ? (
          <div>
            <img
              style={{ width: '30px' }}
              src={`${process.env.REACT_APP_MEDIA_URL}${params.row.postMedias[0].url}`}
            />
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      field: 'content',
      headerName: t('content'),
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineClamp: 1,
            }}
          >
            {params.row.content}
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            component={Link}
            variant="outlined"
            color="primary"
            to={PATH.POST_DETAIL.replace(':id', params.row.id)}
          >
            Detail
          </Button>
        );
      },
    },
    {
      field: 'likeCount',
      headerName: t('likeCount'),
      sortable: false,
      width: 100,
    },
    {
      field: 'commentCount',
      headerName: t('commentCount'),
      sortable: false,
      width: 100,
    },
    {
      field: 'totalReports',
      headerName: t('totalReports'),
      sortable: false,
      width: 100,
    },
    {
      field: 'status',
      headerName: t('status'),
      sortable: false,
      width: 150,
    },
    {
      field: 'nickName',
      headerName: t('username'),
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.user?.nickName || '-';
      },
    },
    {
      field: 'email',
      headerName: t('email'),
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return params.row.user?.email || '-';
      },
    },
    {
      field: 'createdAt',
      headerName: t('createdAt'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'updatedAt',
      headerName: t('updatedAt'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default UserTable;
