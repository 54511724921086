import { PERIOD } from 'src/enums/common';

export const range_picker: App.OptionsFnc = (t) => [
  { label: t('button.today'), value: 'today' },
  { label: t('button.yesterday'), value: 'yesterday' },
  { label: t('button.week'), value: 'week' },
  { label: t('button.month'), value: 'month' },
];

export const period: App.OptionsFnc = (t) => [
  { label: t('option.register_date'), value: PERIOD.REGISTER_DATE },
  { label: t('option.quited_date'), value: PERIOD.QUITED_DATE },
];

export const map_icons = [
  {
    value: 'links/default.png',
    label: 'Default',
  },
  {
    value: 'links/recycle.png',
    label: 'Recycle point',
  },
  {
    value: 'links/coworking.png',
    label: 'Meet & Work',
  },
  {
    value: 'links/cafe.png',
    label: 'Cafe',
  },
  {
    value: 'links/restaurant.png',
    label: 'Restaurant',
  },

  {
    value: 'links/groceries.png',
    label: 'Groceries',
  },
  {
    value: 'links/local_service.png',
    label: 'Local service',
  },
  {
    value: 'links/sports.png',
    label: 'Sports facility',
  },
  {
    value: 'links/playground.png',
    label: 'Kids playground',
  },
  {
    value: 'links/clinic.png',
    label: 'Health clinic',
  },
  {
    value: 'links/charging.png',
    label: 'Car charging',
  },
  {
    value: 'links/campfire.png',
    label: 'Campfire',
  },
  {
    value: 'links/attraction.png',
    label: 'Attraction',
  },
  {
    value: 'links/park.png',
    label: 'Park',
  },
  {
    value: 'links/garage_sale.png',
    label: 'GarageSale',
  },
  {
    value: 'links/service_sc_sports.png',
    label: 'Superclub-Sports',
  },
  {
    value: 'links/service_sc_restaurant.png',
    label: 'Superclub-Restaurant',
  },
  {
    value: 'links/service_sc_localservice.png',
    label: 'Superclub-LocalService',
  },

  {
    value: 'links/service_sc_groceries.png',
    label: 'Superclub-Groceries',
  },

  {
    value: 'links/service_sc_cafe.png',
    label: 'Superclub-Cafe',
  },
];

export const internalFunctionCode = [
  {
    value: '',
    label: 'Empty',
  },
  {
    value: 'navigation_createHoodOptionsScreen',
    label: 'Add a neighborhood',
  },
  {
    value: 'navigation_profile',
    label: 'Create a profile',
  },
  {
    value: 'fixedNavigation_event',
    label: 'Add a new Event',
  },
  {
    value: 'fixedNavigation_activity',
    label: 'Add a new Activity',
  },
  {
    value: 'fixedNavigation_hood_adjustment',
    label: 'Open Hood options view',
  },
  {
    value: 'fixedNavigation_open_hood_filter',
    label: 'Open feed filter menu ',
  },
  {
    value: 'fixedNavigation_create_post',
    label: 'Start a new post',
  },
  {
    value: 'connectHashTag_<hash_tag_here>',
    label: 'Follow hashtag',
  },
];
