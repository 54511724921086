import React, { useState } from 'react';

import FormDetail from './Form';
import { useAppSelector } from 'src/store';
import LocalServices from '../LocalServices';
import { ContentWrapper } from 'src/components/common';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateForm from 'src/containers/CustomMaps/Detail/LocalServices/Create';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
  hoods: Response.HoodEntity[];
  users: Response.UserEntity[];
};

const Main: React.FC<Props> = ({ hoods, users }) => {
  const entities = useAppSelector((state) => state.domain.customMaps.detail);
  const props = {
    hoods: hoods,
    users,
    loading: entities.loading,
    entity: entities.entity,
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <FormDetail {...props} />
      <ContentWrapper
        title={'Local Services'}
        extra={
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => setIsOpen(true)}
          >
            Add
          </Button>
        }
      >
        <LocalServices entities={entities?.entity?.links} />
      </ContentWrapper>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
      >
        <DialogTitle>Add Local services for map</DialogTitle>
        <DialogContent>
          <CreateForm onCloseForm={() => setIsOpen(!isOpen)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(!isOpen)}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Main;
