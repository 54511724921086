import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from 'src/libs/history';
import qs from 'query-string';
import moment from 'moment';
import _ from 'lodash';

export enum Filters {
  day = 'day',
  week = 'week',
  month = 'month',
}

export type FormValues = {
  filter: Filters;
  start_date: string;
  end_date: string;
};

const useFormSearch = () => {
  const validationSchema = Yup.object().shape({});

  const startDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD');
  const endDate = moment().add(1, 'days').format('YYYY-MM-DD');

  const defaultValues: FormValues = {
    filter: Filters.day,
    start_date: startDate,
    end_date: endDate,
  };

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const handleSearch = () => {
    form.handleSubmit((data) => {
      if (!_.isString(data.start_date)) {
        // @ts-ignore
        data.start_date = data.start_date?.format('YYYY-MM-DD');
      }

      if (!_.isString(data.end_date)) {
        // @ts-ignore
        data.end_date = data.end_date?.format('YYYY-MM-DD');
      }

      history.push({
        search: qs.stringify({
          ...data,
        }),
      });
    })();
  };

  return { form, handleSearch };
};

export default useFormSearch;
