import HTTPRequest from '../index';

const apiPath = '/v1/posts';

export interface GetPostDetail {
  id: string;
}

export interface UpdatePost {
  id: string;
  content: string;
}

class PostRequest extends HTTPRequest {
  getList(params: Payload.PostList) {
    return this.get<Response.PostListResponse>(apiPath, { params });
  }

  getDetail({ id }: GetPostDetail) {
    return this.get<Response.PostEntity>(`${apiPath}/${id}`);
  }

  deletePost({ id }: GetPostDetail) {
    return this.delete(`${apiPath}/${id}`);
  }

  updatePost({ id, content }: UpdatePost) {
    return this.put(`${apiPath}/${id}`, { content });
  }
}

export default new PostRequest();
