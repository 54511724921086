import React from 'react';

import Main from './Main';
import { useAppSelector } from 'src/store';

const Container: React.FC<{ onCloseForm: any }> = ({ onCloseForm }) => {
  const props = useAppSelector((state) => state.domain.hood.hoods);

  return <Main {...props} onCloseForm={onCloseForm} />;
};

export default Container;
