import { createAsyncThunk } from '@reduxjs/toolkit';

import bannerDetailActions from 'src/store/domain/actionBanner/detail';
import bannerRequest, { UpdateBanner } from 'src/api/requests/actionBanner';
import { toast } from 'react-toastify';

export const fetchResources = createAsyncThunk<void, string>(
  'get_banner_detail',
  async (id, { dispatch }) => {
    try {
      dispatch(bannerDetailActions.updateLoading(true));

      const res = await bannerRequest.getDetailBanner(id);

      if (res.data) {
        await dispatch(bannerDetailActions.updateEntity(res.data));
      }

      dispatch(bannerDetailActions.updateLoading(false));
    } catch (err) {
      dispatch(bannerDetailActions.updateLoading(false));
    }
  },
);

export const updateBanner = createAsyncThunk<void, UpdateBanner>(
  'update_banner',
  async (values, { dispatch }) => {
    try {
      const res = await bannerRequest.update(values);
      if (res.status != 200) {
        toast.error('There was an error updating');
      } else {
        toast.success('Successfully!');
        await dispatch(fetchResources(values.id));
      }
    } catch (err) {
      console.log(err);
      toast.error('There was an error updating');
    }
  },
);
