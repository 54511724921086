import React, { useEffect } from 'react';
import _ from 'lodash';
import Container from 'src/containers/ShareList';
import { ContentWrapper } from 'src/components/common';
import { t } from 'src/libs/i18n';
import { fetchResources } from 'src/useCase/shareList';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { PER } from 'src/constants/app';
import history from 'src/libs/history';
import qs from 'query-string';

const Page: React.FC = () => {
  const query = useQueryUrl();
  const dispatch = useAppDispatch();
  const { page, take } = query;

  if (_.isEmpty(query?.type)) {
    history.replace({
      search: qs.stringify({ type: 'local_police' }),
    });
  }

  useEffect(() => {
    dispatch(
      fetchResources({
        type: 'local_police',
        ...query,
        page: page,
        take: take || String(PER.DEFAULT),
      }),
    );
  }, [query]);
  return (
    <ContentWrapper title={t('title.share_list')}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/share_list' as const;
export default Page;
