import React from 'react';
import styled from 'styled-components';

import { InputField, SelectField } from 'src/components/form';
import useFormSearch, { Status } from './useForm';
import { Button, Grid } from '@mui/material';

const optionStatus = [
  { label: 'All', value: Status.all },
  { label: 'Pending approval', value: Status.pending_approval },
  { label: 'Active', value: Status.active },
  { label: 'Paused', value: Status.paused },
  { label: 'Archived', value: Status.archived },
];

const Search = () => {
  const { form, handleSearch } = useFormSearch();
  const { control } = form;

  return (
    <Wrapper>
      <Grid container marginLeft={0} spacing={4} marginBottom={3}>
        <Grid item xs={3}>
          <SelectField
            controller={{
              control,
              name: 'status',
            }}
            label={'Status'}
            size="medium"
            options={optionStatus}
          />
        </Grid>

        <Grid item xs={4}>
          <InputField
            controller={{ control, name: 'headline' }}
            inputProps={{
              fullWidth: true,
              placeholder: 'Enter activities headline',
            }}
            size="medium"
            label={'Headline'}
          />
        </Grid>

        <Grid
          item
          xs={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BtnSearch onClick={handleSearch} variant="contained">
            Search
          </BtnSearch>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: white;
  padding: 16px;
  margin: 24px 24px 0;
  border-radius: 10px;
`;

const BtnSearch = styled(Button)`
  margin-left: 100px;
`;

export default Search;
