import React, { useEffect } from 'react';

import Container from 'src/containers/FacebookDetail';
import { ContentWrapper } from 'src/components/common';
import { t } from 'src/libs/i18n';
import { useParams } from 'react-router';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { fetchResources } from 'src/useCase/facebookDetail';
import { searchHoods } from '../../useCase/hoodList';

const Page: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const query = useQueryUrl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchResources(String(id)));
    dispatch(searchHoods(''));
  }, [query]);

  return (
    <ContentWrapper title={t('title.facebook_detail')}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/facebook-integration/:id' as const;
export default Page;
