import {
  Grid,
  styled,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Select,
  FormControl,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'src/components/common';
import useForm from './useForm';
// import history from 'src/libs/history';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';

type Props = {
  entities: Response.HoodEntity[];
  loading: boolean;
  onCloseForm: () => void;
};

const FormCreate: React.FC<Props> = ({ entities, loading, onCloseForm }) => {
  const { entity } = useAppSelector((state) => state.domain.customMaps.detail);

  const [category, setCategory] = React.useState('');
  const [getStatus, setStatus] = React.useState('');
  const dispatch = useAppDispatch();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const loadingHood = loading;
  const { formik } = useForm(onCloseForm);
  const refIcon = useRef<File | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    // values,
    setFieldValue,
    isSubmitting,
  } = formik;
  const { t } = useTranslation();

  useEffect(() => {
    if (entity) {
      setFieldValue('id', entity?.id);
      setFieldValue('customMapCode', entity?.mapCode);
    }
  }, [entity]);

  const handleChangeSelectCategory = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
    setFieldValue('category', event.target.value);
  };

  const handleChangeSelectStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setFieldValue('status', event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listHood = useMemo(() => {
    if (openHood) {
      return entities.map((v) => {
        return {
          label: v.name,
          id: v.id,
        };
      });
    }
  }, [entities, openHood]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const categories = [
    {
      value: 'authorities_and_city',
      text: 'Authorities & City',
    },
    {
      value: 'public_services',
      text: 'Public services',
    },
    {
      value: 'associations',
      text: 'Associations',
    },
    {
      value: 'commercial_services',
      text: 'Commercial services',
    },
    {
      value: 'custom',
      text: 'Custom',
    },
  ];

  const status = [
    {
      value: 'pending_approval',
      text: 'Pending approval',
    },
    {
      value: 'active',
      text: 'Active',
    },
    {
      value: 'paused',
      text: 'Paused',
    },
    {
      value: 'archived',
      text: 'Archived',
    },
  ];

  const [searchHood, setSearchHood] = useState<string>('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchHood) {
        dispatch(searchHoods(searchHood));
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchHood]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="name"
                name="name"
                label={'Name'}
                onChange={handleChange}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                type="text"
                id="url"
                name="url"
                label={'URL'}
                onChange={handleChange}
                error={touched.url && Boolean(errors.url)}
                helperText={touched.url && errors.url}
              />
            </Grid>
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="icon"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      refIcon.current = event.currentTarget.files[0];
                      setFieldValue('icon', event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {touched.icon && errors.icon && (
                <FormHelperText className="Mui-error">
                  {errors.icon}
                </FormHelperText>
              )}
              <img
                width={100}
                height={100}
                src={
                  refIcon.current
                    ? URL.createObjectURL(refIcon.current)
                    : 'https://placehold.jp/100x100.png'
                }
              />
            </Grid>

            <Grid item xs={5}>
              <Autocomplete
                id="combo-box-demo"
                options={listHood || []}
                onChange={(e, v) => {
                  setFieldValue('hoodId', v?.id);
                }}
                onInputChange={(e: any) => setSearchHood(e.target?.value)}
                loading={loadingHood}
                onOpen={() => setOpenHood(true)}
                onClose={() => setOpenHood(false)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    name="hoodId"
                    {...params}
                    label={t('ads_fields.hood')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingHood ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              {touched.hoodId && Boolean(errors.hoodId)}
            </Grid>
            <Grid item xs={5}>
              <FormControl sx={{ m: 1, minWidth: '100%', margin: 0 }}>
                <InputLabel id="controlled-open-select-label">
                  {'Category'}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-label"
                  id="controlled-open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={category}
                  label={'Category'}
                  onChange={handleChangeSelectCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories.map((category, index) => {
                    return (
                      <MenuItem key={index} value={category.value}>
                        {category.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
                id="city"
                name="city"
                label={t('ads_fields.city')}
                onChange={handleChange}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="text"
                id="country"
                name="country"
                label={t('ads_fields.country')}
                onChange={handleChange}
                error={touched.country && Boolean(errors.country)}
                helperText={touched.country && errors.country}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="text"
                id="state"
                name="state"
                label={t('ads_fields.state')}
                onChange={handleChange}
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state && errors.state}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={5}>
                <FormControl
                  sx={{ m: 1, minWidth: '100%', margin: 0 }}
                  error={touched.status && Boolean(errors.status)}
                >
                  <InputLabel id="controlled-open-select-status-label">
                    {'Status'}
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="controlled-open-select-status-label"
                    id="controlled-open-select-status"
                    open={openStatus}
                    onClose={handleCloseStatus}
                    onOpen={handleOpenStatus}
                    value={getStatus}
                    name="status"
                    label={'Status'}
                    onChange={handleChangeSelectStatus}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {status.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {touched.status && Boolean(errors.status) && (
                    <FormHelperText>{errors.status}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
                id="latitude"
                name="latitude"
                label={'latitude'}
                onChange={handleChange}
                error={touched.latitude && Boolean(errors.latitude)}
                helperText={touched.latitude && errors.latitude}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
                id="longitude"
                name="longitude"
                label={'longitude'}
                onChange={handleChange}
                error={touched.longitude && Boolean(errors.longitude)}
                helperText={touched.longitude && errors.longitude}
              />
            </Grid>
            <Grid item xs={7}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                style={{ marginTop: 22 }}
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Create
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormCreate;
