import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { createResource } from 'src/useCase/link';
import { useAppDispatch } from 'src/store';
import history from 'src/libs/history';
import { PATH } from 'src/pages/linked/LinkList';

export type FormValue = {
  name: string;
  url: string;
  category: string;
  icon: string;
  hoodId: string;
  city: string;
  country: string;
  state: string;
  status: string;
  latitude: string;
  longitude: string;
  iconPath: string;
  photo: string;
  offer: string;
  userId: string;
  isSuperClub: string;
  isLoyaltyTool: string;
};

const useForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const defaultValue: FormValue = {
    name: '',
    url: '',
    category: '',
    icon: '',
    hoodId: '',
    city: '',
    country: '',
    state: '',
    status: '',
    latitude: '',
    longitude: '',
    iconPath: '',
    photo: '',
    offer: '',
    userId: '',
    isSuperClub: 'false',
    isLoyaltyTool: 'false',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validated.required', { name: 'Link Name' })),
    // icon: Yup.string().required(t('validated.required', { name: 'Link Icon' })),
    icon: Yup.string().when('mapIcon', {
      is: '',
      then: Yup.string().required(
        t('validated.required', { name: 'Link Icon' }),
      ),
    }),
    category: Yup.string().required(
      t('validated.required', { name: 'Category' }),
    ),
    url: Yup.string()
      .required(t('validated.required', { name: 'Link URL' }))
      .url(),
    status: Yup.mixed().required(t('validated.required', { name: 'Status' })),
  });

  const formik = useFormik<FormValue>({
    initialValues: {
      ...defaultValue,
    },
    validationSchema,
    async onSubmit(values) {
      await dispatch(createResource({ ...values }));
      history.push(PATH);
    },
    validateOnChange: true,
  });

  return { formik };
};

export default useForm;
