import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import hoodImageActions from 'src/store/domain/hoodImage/list';
import hoodImageRequest from 'src/api/requests/hoodImage';
import appActions from 'src/store/ui/app';
import hoodImageDetailActions from 'src/store/domain/hoodImage/detail';
import adsRequest from 'src/api/requests/ads';
import { toast } from 'react-toastify';
import qs from 'query-string';
import history from 'src/libs/history';

interface FetchResources extends SearchParams.User {}

interface FetchHoodImageDetailResources extends SearchParams.HoodImageDetail {}

const getParams = (args: FetchResources) => {
  const { take, page } = args;

  const params: Payload.UserList = {
    take,
    page,
  };

  return params;
};

const getHoodImageDetailParams = (args: FetchHoodImageDetailResources) => {
  const { take, page, hoodId } = args;

  const params: Payload.HoodImageDetailList = {
    take,
    page,
    hoodId,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, FetchResources>(
  'get_hood_images_list',
  async (args, { dispatch }) => {
    try {
      dispatch(hoodImageActions.updateLoading(true));

      const params = getParams(args);
      const res = await hoodImageRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(hoodImageActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(hoodImageActions.updateLoading(false));
    } catch (err) {
      dispatch(hoodImageActions.updateLoading(false));
    }
  },
);

export const fetchHoodImageDetailResources = createAsyncThunk<
  void,
  FetchHoodImageDetailResources
>('get_hood_images_list', async (args, { dispatch }) => {
  try {
    dispatch(hoodImageDetailActions.updateLoading(true));

    const params = getHoodImageDetailParams(args);
    const res = await hoodImageRequest.getHoodImageDetailList(
      params.hoodId,
      _.omitBy(params, _.isEmpty),
    );

    const { data, meta } = res.data;

    if (data) {
      dispatch(hoodImageDetailActions.updateEntities(data));
    }

    if (meta) {
      dispatch(
        appActions.updatePagination({
          page: meta.page,
          take: meta.take,
          itemCount: meta.itemCount,
          pageCount: meta.pageCount,
        }),
      );
    }
    dispatch(hoodImageDetailActions.updateLoading(false));
  } catch (err) {
    dispatch(hoodImageDetailActions.updateLoading(false));
  }
});

export const deleteImage = createAsyncThunk<void, any>(
  'delete_hood_images',
  async ({ id, hoodId }, { dispatch }) => {
    try {
      const res = await hoodImageRequest.deleteImage(id);
      if (res.status != 200) {
        toast.error('There was an error deleting');
      } else {
        toast.success('Successfully!');

        await dispatch(fetchHoodImageDetailResources({ hoodId }));
      }
    } catch (err) {
      toast.error('There was an error deleting');
    }
  },
);

export const updateImage = createAsyncThunk<void, any>(
  'delete_hood_images',
  async ({ id, status, hoodId }, { dispatch }) => {
    try {
      console.log('updateImage', { id, status, hoodId });
      const res = await hoodImageRequest.updateStatus(id, { status });
      if (res.status != 200) {
        toast.error('There was an error updating');
      } else {
        toast.success('Successfully!');

        await dispatch(fetchHoodImageDetailResources({ hoodId }));
      }
    } catch (err) {
      toast.error('There was an error updating');
    }
  },
);
