import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'src/store';
import { updateCustomMaps } from 'src/useCase/customMaps';
import { useCallback, useEffect, useState } from 'react';

export type FormValues = {
  name: string;
  userId: { label: string; id: string } | any;
  allowOtherUsers: boolean;
  showOnlyToLocal: boolean;
  marker: string | any;
  hoodId: { label: string; id: string } | any;
  city: string;
  country: string;
  state: string;
  status: string;
};

type Data = Response.CustomMapEntity | null;
type Hoods = Response.HoodEntity[];
// type Users = Response.UserEntity[];

const useFormDetail = (data: Data, hoods: Hoods, users: any) => {
  const [loadingHood, setLoadingHood] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getDefaultValueHood = useCallback(
    (data: Response.CustomMapEntity | null) => {
      const hood = hoods.find((v) => String(v.id) === data?.hoodId);
      if (!hood) return null;

      return {
        label: String(hood?.name),
        id: String(hood?.id),
      };
    },
    [hoods],
  );

  const getDefaultValueUser = useCallback(
    (data: Response.CustomMapEntity | null) => {
      const user = users.find((v) => String(v.id) === data?.userId);
      if (!user) return null;

      return {
        label: String(user?.nick_name),
        id: String(user?.id),
      };
    },
    [users],
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validated.required', { name: 'Maps name' })),
    status: Yup.mixed().required(t('validated.required', { name: 'Status' })),
  });

  const form = useForm<FormValues>({
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!getDefaultValueHood(data)?.id) {
      setLoadingHood(true);
    }
    setLoadingHood(false);
  }, [data, hoods]);

  useEffect(() => {
    if (!getDefaultValueUser(data)?.id) {
      setLoadingUser(true);
    }
    setLoadingUser(false);
  }, [data, users]);

  useEffect(() => {
    const labelHood = data?.hood
      ? {
          label: data?.hood?.name,
          id: data?.hoodId,
        }
      : '';
    const labelUser = data?.user
      ? {
          label: data?.user?.nick_name,
          id: data?.userId,
        }
      : '';
    form.reset({
      name: data?.name,
      userId: labelUser || '',
      allowOtherUsers: data?.allowOtherUsers,
      showOnlyToLocal: data?.showOnlyToLocal,
      marker: data?.marker,
      hoodId: labelHood || '',
      city: data?.city,
      country: data?.country,
      state: data?.state,
      status: data?.status,
    });
  }, [data]);

  const submitForm = () => {
    form.handleSubmit(async (values) => {
      await dispatch(
        updateCustomMaps({
          id: data?.id as string,
          ...values,
        }),
      );
    })();
  };

  return { form, submitForm, loadingHood, loadingUser };
};

export default useFormDetail;
