import React from 'react';
import { Container } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { formatDate } from 'src/utils/helpers';
import styled from 'styled-components';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  loading: boolean;
  userAnalytics: Response.UserAnalyticsEntity[];
};
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'User Analytics',
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export interface chartDatasetInterface {
  label: string;
  data: number[];
  backgroundColor: string;
}

const Main: React.FC<Props> = ({ userAnalytics }) => {
  let chartLabels: string[] = [];
  let dataUserNew: number[] = [];
  let dataUserReturning: number[] = [];

  if (userAnalytics) {
    userAnalytics.map((userAnalytic) => {
      if (userAnalytic.date) {
        chartLabels.push(formatDate(userAnalytic.date, 'YYYY/MM/DD'));
      } else if (userAnalytic.week) {
        chartLabels.push(`${userAnalytic.week} week/${userAnalytic.year}`);
      } else if (userAnalytic.month) {
        chartLabels.push(`${userAnalytic.month} month/${userAnalytic.year}`);
      }
      dataUserNew.push(userAnalytic.new_count);
      dataUserReturning.push(userAnalytic.returning_count);
    });
  }
  let chartDataset: chartDatasetInterface[] = [
    {
      label: 'New',
      data: dataUserNew,
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Returning',
      data: dataUserReturning,
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ];
  const chartData = {
    labels: chartLabels,
    datasets: chartDataset,
  };
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Bar options={options} data={chartData} />

        <TableDetailWrapper>
          {userAnalytics &&
            userAnalytics.map((item, index) => {
              const flexItem = 1 / Object.keys(userAnalytics).length;
              const hoods = item.hoods;
              let title = '';
              if (item.date) {
                title = formatDate(item.date, 'YYYY/MM/DD');
              } else if (item.week) {
                title = `${item.week} week/${item.year}`;
              } else if (item.month) {
                title = `${item.month} month/${item.year}`;
              }
              return (
                <TableItem key={index} flex={flexItem}>
                  <div className="title">{title}</div>
                  <div className="content">
                    {hoods &&
                      hoods.map((hood, key) => {
                        return (
                          <div key={key} className="item">
                            <span>{hood?.hood_name}</span>
                            <span>{hood.total}</span>
                          </div>
                        );
                      })}
                  </div>
                </TableItem>
              );
            })}
        </TableDetailWrapper>
      </Container>
    </React.Fragment>
  );
};

export default Main;

const TableDetailWrapper = styled.div`
  margin-left: 25px;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
  .title {
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TableItem = styled.div<{ flex: number }>`
  flex: ${({ flex }) => flex};
  //margin-left: 20px;
  //margin-top: 10px;
  padding: 0 10px;
  border-right: 1px slategray solid;
  //padding-right: 10px;
`;
