import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import facebookDetailActions from 'src/store/domain/facebook/detail';
import facebookRequest, { UpdateSetting } from 'src/api/requests/facebook';

export const fetchResources = createAsyncThunk<void, string>(
  'get_facebook_detail',
  async (id, { dispatch }) => {
    try {
      dispatch(facebookDetailActions.updateLoading(true));

      const res = await facebookRequest.getDetail(id);

      if (res.data) {
        await dispatch(facebookDetailActions.updateEntity(res.data));
      }

      dispatch(facebookDetailActions.updateLoading(false));
    } catch (err) {
      dispatch(facebookDetailActions.updateLoading(false));
    }
  },
);

export const updateFacebook = createAsyncThunk<void, UpdateSetting>(
  'update_facebook',
  async (data, { dispatch }) => {
    try {
      const res = await facebookRequest.update(data);
      if (res.status != 200) {
        toast.error('There was an error updating');
      } else {
        toast.success('Successfully!');
        await dispatch(fetchResources(data.id));
      }
    } catch (err) {
      toast.error('There was an error updating');
    }
  },
);
