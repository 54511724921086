import React, { useEffect } from 'react';

import Container from 'src/containers/FacebookList';
import { ContentWrapper } from 'src/components/common';
import { t } from 'src/libs/i18n';
import { fetchResources } from 'src/useCase/facebooks';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { PER } from 'src/constants/app';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { PATH as PATH_FACEBOOK } from './index';

const Page: React.FC = () => {
  const query = useQueryUrl();
  const dispatch = useAppDispatch();
  const { page, take } = query;

  useEffect(() => {
    dispatch(
      fetchResources({
        ...query,
        page: page,
        take: take || String(PER.DEFAULT),
      }),
    );
  }, [query]);

  return (
    <ContentWrapper
      title={t('title.facebook_list')}
      extra={
        <Button
          startIcon={<AddIcon />}
          component={Link}
          variant="contained"
          to={PATH_FACEBOOK.CREATE}
        >
          {t('button.create') as string}
        </Button>
      }
    >
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/facebook-integration' as const;
export default Page;
