import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

import Search from './Search';
import Table from './Table';
import useQueryUrl from 'src/hooks/useQueryUrl';
import history from 'src/libs/history';

type MainProps = {
  loading: boolean;
  entities: Response.UserEntity[];
};

const Main: React.FC<MainProps> = (props) => {
  const query = useQueryUrl();
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    history.push({
      search: qs.stringify({ isBusiness: newValue }),
    });
  };

  return (
    <React.Fragment>
      <Box sx={{ width: '100%' }}>
        <TabContext value={String(query.isBusiness ?? 'false')}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label={t('consumer_users')} value={'false'} />
              <Tab label={t('business_users')} value={'true'} />
            </TabList>
          </Box>
          <TabPanel value={'true'} style={{ padding: 0 }}>
            <Search />
            <MarginBottom />
            <Table {...props} />
          </TabPanel>
          <TabPanel value={'false'} style={{ padding: 0 }}>
            <Search />
            <MarginBottom />
            <Table {...props} />
          </TabPanel>
        </TabContext>
      </Box>
    </React.Fragment>
  );
};

const MarginBottom = styled.div`
  margin-bottom: 24px;
`;

export default Main;
