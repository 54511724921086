import React from 'react';
import { Grid, GridProps, Typography } from '@mui/material';

type Props = {
  title?: string | React.ReactNode;
  value?: string | number | React.ReactNode;
  actions?: React.ReactNode;
  layout?: {
    titleCol?: GridProps;
    valueCol?: GridProps;
    actionsCol?: GridProps;
  };
  style?: React.CSSProperties;
  marginBottom?: number;
};

const RowDetail: React.FC<Props> = (props) => {
  const { title, value, actions, layout, marginBottom = 20, style } = props;

  const defaultLayout: Props['layout'] = {
    titleCol: layout?.titleCol ?? { xs: 2 },
    valueCol: layout?.valueCol ?? { xs: 4 },
    actionsCol: layout?.actionsCol ?? { xs: 4 },
  };

  return (
    <Grid container style={{ ...style, marginBottom: marginBottom }}>
      <Grid item {...defaultLayout.titleCol}>
        <Typography style={{ fontWeight: 500 }}>{title}</Typography>
      </Grid>
      <Grid item {...defaultLayout.valueCol}>
        <Typography>{value}</Typography>
      </Grid>
      <Grid item {...defaultLayout.actionsCol}>
        {actions}
      </Grid>
    </Grid>
  );
};

export default RowDetail;
