import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { Button, CardMedia, Grid, LinearProgress } from '@mui/material';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME } from 'src/constants/app';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';

type Props = {
  loading: boolean;
  entities: Response.HoodEntity[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 400,
    },
    {
      field: 'name',
      headerName: t('name'),
      sortable: false,
      width: 400,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'totalImage',
      headerName: 'Total Image',
      sortable: false,
      width: 400,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'activeImage',
      headerName: 'Active Image',
      sortable: false,
      width: 400,
      renderCell: ({ row }) => {
        if (_.isEmpty(row?.activeImage)) return null;

        return (
          <div>
            <CardMedia
              component="img"
              height="150"
              image={`${process.env.REACT_APP_MEDIA_URL}${row?.activeImage?.image}`}
            />
          </div>
        );
      },
    },
    // {
    //   field: 'Active At',
    //   headerName: 'Active At',
    //   sortable: false,
    //   width: 400,
    //   renderCell: ({ row }) => {
    //     if (_.isEmpty(row?.activeImage)) return null;
    //     <div>
    //       Active At {formatDate(row?.activeImage?.updatedAt, DATE_TIME) || '-'}
    //     </div>;
    //   },
    // },
    {
      field: 'latestImage',
      headerName: 'Latest Image',
      sortable: false,
      width: 400,
      renderCell: ({ row }) => {
        if (_.isEmpty(row?.latestImage)) return null;
        return (
          <CardMedia
            component="img"
            height="150"
            image={`${process.env.REACT_APP_MEDIA_URL}${row?.latestImage?.image}`}
          />
        );
      },
    },
    // {
    //   field: 'Latest Upload At',
    //   headerName: 'Latest Upload At',
    //   sortable: false,
    //   width: 400,
    //   renderCell: ({ row }) => {
    //     if (_.isEmpty(row?.latestImage)) return null;
    //     <div>
    //       Active At {formatDate(row?.latestImage?.updatedAt, DATE_TIME) || '-'}
    //     </div>;
    //   },
    // },
    {
      field: 'createdAt',
      headerName: t('createdAt'),
      sortable: false,
      width: 400,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      width: 350,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Grid container spacing={0} alignContent="center">
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<EditIcon />}
                to={`/hood_images/${params.row.id}`}
                component={Link}
              >
                Detail
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          rowHeight={150}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default UserTable;
