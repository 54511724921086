import React from 'react';

import { PATH } from 'src/pages/ads';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';

const Route: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH.ADS_LIST,
        element: loadable(() => import('src/pages/ads/AdsList')),
      },
      {
        path: PATH.ADS_CREATE,
        element: loadable(() => import('src/pages/ads/AdsCreate')),
      },
      {
        path: PATH.ADS_Detail,
        element: loadable(() => import('src/pages/ads/AdsDetail')),
      },
      {
        path: PATH.ADS_REPORT,
        element: loadable(() => import('src/pages/ads/AdsReport')),
      },
    ],
  },
];

export default Route;
