import React, { useEffect } from 'react';

import Container from 'src/containers/AppDetail';
import { ContentWrapper } from 'src/components/common';
import { t } from 'src/libs/i18n';
import { useAppDispatch } from 'src/store';
import { useParams } from 'react-router-dom';
import { fetchResources } from 'src/useCase/appDetail';
import useQueryUrl from 'src/hooks/useQueryUrl';

const Page: React.FC = () => {
  const dispatch = useAppDispatch();
  const query = useQueryUrl();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchResources(String(id)));
  }, [query]);

  return (
    <ContentWrapper title={t('title.app_detail')}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/app_list/:id' as const;
export default Page;
