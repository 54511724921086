import React from 'react';

import FormDetail from './Form';
import { useAppSelector } from 'src/store';

type Props = {
  hoods: Response.HoodEntity[];
  users: Response.UserEntity[];
};

const Main: React.FC<Props> = ({ hoods, users }) => {
  const entites = useAppSelector((state) => state.domain.actionBanner.detail);
  const props = {
    hoods: hoods,
    users,
    loading: entites.loading,
    entity: entites.entity,
  };

  return (
    <React.Fragment>
      <FormDetail {...props} />
    </React.Fragment>
  );
};

export default Main;
