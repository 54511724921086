import { Button, Grid, styled } from '@mui/material';
import React from 'react';

import { Card, RowDetail } from 'src/components/common';
import {
  DatePickerField,
  InputField,
  RadioGroupField,
} from 'src/components/form';
import useForm, { FormValue } from './useForm';
import { user_gender } from 'src/constants/optionsSelect/user';
import { t } from 'src/libs/i18n';
import history from 'src/libs/history';

type Props = {
  entity: Response.UserEntity | null;
  loading: boolean;
};

const FormUpdate: React.FC<Props> = ({ entity }) => {
  const { control, handleSubmit } = useForm({ entity });

  const onSubmit = (values) => {
    console.log('values', values);
  };

  return (
    <Card>
      <Wrapper container spacing={0}>
        <RowDetail
          title={'Username'}
          value={
            <InputField<FormValue> controller={{ control, name: 'username' }} />
          }
        />
        <RowDetail
          title={'Email'}
          value={
            <InputField<FormValue> controller={{ control, name: 'email' }} />
          }
        />
        <RowDetail
          title={'Phone'}
          value={
            <InputField<FormValue> controller={{ control, name: 'phone' }} />
          }
        />
        <RowDetail
          title={'Birth date'}
          value={
            <DatePickerField<FormValue>
              controller={{ control, name: 'birthdate' }}
            />
          }
        />
        <RowDetail
          title={'Gender'}
          value={
            <RadioGroupField<FormValue>
              controller={{ control, name: 'gender' }}
              options={user_gender(t)}
            />
          }
        />
        <Grid container style={{ marginTop: 20 }} spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              type="submit"
              onClick={() => handleSubmit(onSubmit)}
            >
              Update
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" onClick={history.back}>
              Back
            </Button>
          </Grid>
        </Grid>
      </Wrapper>
    </Card>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormUpdate;
