import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  styled,
  TextField,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { Card, RowDetail } from 'src/components/common';
import { InputField, SelectField } from 'src/components/form';
import useForm, { FormValue, optionCategory, optionType } from './useForm';
import { t } from 'src/libs/i18n';
import history from 'src/libs/history';
import { searchHoods } from 'src/useCase/hoodList';
import { useAppDispatch } from 'src/store';

type Props = {
  entities: Response.HoodEntity[];
  loading: boolean;
};

const FormCreate: React.FC<Props> = ({ entities, loading }) => {
  const { formInfo, onSubmit } = useForm();
  const { control, handleSubmit, setValue } = formInfo;
  const [openHood, setOpenHood] = useState<boolean>(false);
  const [searchHood, setSearchHood] = useState<string>('');
  const dispatch = useAppDispatch();

  const listHood = useMemo(() => {
    if (openHood) {
      return entities.map((v) => {
        return {
          label: v.name,
          id: v.id,
        };
      });
    }
  }, [entities, openHood]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchHood) {
        dispatch(searchHoods(searchHood));
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchHood]);

  return (
    <Card>
      <Wrapper container spacing={0}>
        <RowDetail
          title={'Page Name'}
          value={
            <InputField<FormValue> controller={{ control, name: 'pageName' }} />
          }
        />
        <RowDetail
          title={'Page Id'}
          value={
            <InputField<FormValue> controller={{ control, name: 'pageId' }} />
          }
        />
        <RowDetail
          title={'Page Access Token'}
          value={
            <InputField<FormValue>
              controller={{ control, name: 'pageAccessToken' }}
            />
          }
        />
        <RowDetail
          title={'Type'}
          value={
            <SelectField<FormValue>
              controller={{ control, name: 'type' }}
              options={optionType}
            />
          }
        />

        <RowDetail
          title={'Hoods'}
          value={
            <Autocomplete
              id="combo-box-demo"
              options={listHood || []}
              onChange={(e, v) => {
                setValue('hoodId', String(v?.id || ''));
              }}
              size="small"
              style={{ marginLeft: '-8px' }}
              onInputChange={(e: any) => setSearchHood(e.target?.value)}
              loading={loading}
              onOpen={() => setOpenHood(true)}
              onClose={() => setOpenHood(false)}
              includeInputInList
              renderInput={(params) => (
                <TextField
                  type="text"
                  name="hoodId"
                  {...params}
                  label={t('ads_fields.hood') as string}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          }
        />
        <RowDetail
          title={'User ID'}
          value={
            <InputField<FormValue> controller={{ control, name: 'userId' }} />
          }
        />
        <RowDetail
          title={'Category'}
          value={
            <SelectField<FormValue>
              controller={{ control, name: 'category' }}
              options={optionCategory}
            />
          }
        />
        <Grid container style={{ marginTop: 20 }} spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              onClick={handleSubmit(onSubmit)}
            >
              Create
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" onClick={history.back}>
              Back
            </Button>
          </Grid>
        </Grid>
      </Wrapper>
    </Card>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormCreate;
