import {
  Grid,
  styled,
  CircularProgress,
  IconButton,
  TextField,
  FormHelperText,
  Autocomplete,
} from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { Card } from 'src/components/common';
import useForm, { FormValues } from './useForm';
import history from 'src/libs/history';
import { InputField } from 'src/components/form';
import SelectField from 'src/components/form/SelectField';
import { useAppDispatch, useAppSelector } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';
import { Add, Delete, PhotoCamera } from '@mui/icons-material';
import _ from 'lodash';

type Props = {
  hoods: Response.HoodEntity[];
  entity: Response.AppDetailEntity | null;
  loading: boolean;
};

const FormDetail: React.FC<Props> = (props) => {
  const { hoods, entity, loading } = props;

  const { form, submitForm } = useForm(entity, hoods);
  const { control, formState, setValue } = form;
  const { t } = useTranslation();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const valueHood = useAppSelector((state) => state.domain.hood.hoods);
  const [refAppIcon, setRefAppIcon] = React.useState<File | null>(null);

  useEffect(() => {
    if (entity) {
      setLabels(entity.appLabelText);
      // (async () => {
      //   if (entity.hood) {
      //     await dispatch(searchHoods(entity?.hood?.name));
      //   } else {
      //     await dispatch(searchHoods(''));
      //   }
      //   // @ts-ignore
      // })();
    }
  }, [entity]);

  const getHoods = useMemo(() => {
    return valueHood.entities.map((v) => {
      return {
        label: String(v.name),
        id: String(v.id),
      };
    });
  }, [hoods]);

  const category = [
    {
      value: 'HOME',
      label: 'Home',
    },
    {
      value: 'WORK',
      label: 'Work',
    },
    {
      value: 'FAMILY',
      label: 'Family',
    },
    {
      value: 'HOLIDAY',
      label: 'Holiday',
    },
    {
      value: 'EVENT',
      label: 'Event',
    },
    {
      value: 'DREAM',
      label: 'Dream',
    },
    {
      value: 'OTHER',
      label: 'Other',
    },
  ];

  const adsStatus = [
    {
      value: 'pending_approval',
      label: 'Pending approval',
    },
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'paused',
      label: 'Paused',
    },
    {
      value: 'archived',
      label: 'Archived',
    },
  ];

  const bussiness = [
    {
      value: 'true',
      label: 'True',
    },
    {
      value: 'false',
      label: 'False',
    },
  ];

  const defaultCategory = ['city', 'food', 'services', 'authorities'];

  const [labels, setLabels] = useState<any>([]);

  useEffect(() => {
    if (_.isEmpty(labels)) {
      setValue('appLabelText', '');
    } else {
      setValue('appLabelText', JSON.stringify(labels));
    }
  }, [labels]);

  const addMoreLabels = () => {
    setLabels([...labels, { key: '', value: '' }]);
  };

  const onChangeValueLabelText = (index, value) => {
    const data = [...labels];
    data[index] = value;
    setLabels(data);
  };

  const removeLabelText = (index) => {
    const data = [...labels];
    data.splice(index, 1);
    setLabels(data);
  };

  return (
    <Wrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={11}>
              {labels.map((label, index) => {
                return (
                  <LabelInputWrapper>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="appLabelText"
                        name="appLabelText"
                        label="Language Code"
                        value={labels[index].key}
                        onChange={(e) => {
                          onChangeValueLabelText(index, {
                            key: e.target.value,
                            value: labels[index].value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} marginLeft={2}>
                      <TextField
                        fullWidth
                        type="text"
                        id="appLabelText"
                        name="appLabelText"
                        label={'Label Text'}
                        value={labels[index].value}
                        onChange={(e) => {
                          onChangeValueLabelText(index, {
                            key: labels[index].key,
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <IconButton
                      style={{ marginLeft: 5 }}
                      color="primary"
                      aria-label="Remove"
                      component="label"
                      onClick={() => removeLabelText(index)}
                    >
                      <Delete />
                    </IconButton>
                  </LabelInputWrapper>
                );
              })}
              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22 }}
                loading={false}
                startIcon={<Add />}
                onClick={addMoreLabels}
              >
                ADD LABEL TEXT
              </LoadingButton>
            </Grid>

            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'appAndroidUrl' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.appAndroidUrl,
                }}
                size="medium"
                label={t('app_fields.appAndroidUrl')}
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'appIosUrl' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.appIosUrl,
                }}
                size="medium"
                label={t('app_fields.appIosUrl')}
              />
            </Grid>
            <Grid item xs={10}>
              <InputField<FormValues>
                controller={{ control, name: 'appDescription' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.appDescription,
                }}
                size="medium"
                label={t('app_fields.appDescription')}
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'appDesktopUrl' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.appDesktopUrl,
                }}
                size="medium"
                label={t('app_fields.appDesktopUrl')}
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'order' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.order,
                }}
                size="medium"
                label={t('app_fields.order')}
              />
            </Grid>
            {/* Image 320 x 50 Mobile Leaderboard */}
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="appIcon"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      setValue('appIcon', event.currentTarget.files[0], {
                        shouldDirty: true,
                      });
                      setRefAppIcon(event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              <img
                width={320}
                height="auto"
                src={
                  refAppIcon
                    ? URL.createObjectURL(refAppIcon)
                    : `${process.env.REACT_APP_MEDIA_URL}${entity?.appIcon}`
                }
                loading="lazy"
                onError={(e) => {
                  if (e.currentTarget.src) {
                    e.currentTarget.title = 'no image';
                  }
                }}
              />
            </Grid>
            <Grid item xs={7}>
              {/*<AutoCompleteField<FormValues>*/}
              {/*  controller={{ control, name: 'hoodId' }}*/}
              {/*  options={getHoods || []}*/}
              {/*  onOpen={() => setOpenHood(!openHood)}*/}
              {/*  onClose={() => setOpenHood(!openHood)}*/}
              {/*  loading={valueHood.loading}*/}
              {/*  callbackOnchange={(v) => {*/}
              {/*    dispatch(searchHoods(v));*/}
              {/*  }}*/}
              {/*  label={t('ads_fields.hood')}*/}
              {/*  size="medium"*/}
              {/*/>*/}
              <Autocomplete
                multiple
                id="combo-box-demo"
                options={getHoods || []}
                onChange={(e, v) => {
                  // @ts-ignore
                  setValue('hoodId', v);
                }}
                defaultValue={
                  entity?.hoods
                    ? entity?.hoods.map((item: any) => {
                        return { label: item.name, id: item.id };
                      })
                    : []
                }
                onInputChange={(e: any) =>
                  dispatch(searchHoods(e.target.value))
                }
                loading={valueHood.loading}
                onOpen={() => setOpenHood(!openHood)}
                onClose={() => setOpenHood(!openHood)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    // name="hoodId"
                    {...params}
                    label={t('ads_fields.hood')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {valueHood.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <SelectField<FormValues>
                controller={{ control, name: 'hoodCategory' }}
                options={category}
                selectProps={{
                  defaultValue: entity?.hoodCategory,
                }}
                label={t('ads_fields.hoodCategory')}
                size="medium"
              />
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                multiple
                id="tags-cities"
                options={[]}
                defaultValue={entity?.city || []}
                freeSolo
                onChange={(e, v) => {
                  // @ts-ignore
                  setValue('city', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t('ads_fields.city')}
                    placeholder={t('ads_fields.city')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                multiple
                id="tags-country"
                options={[]}
                defaultValue={entity?.country || []}
                freeSolo
                onChange={(e, v) => {
                  // @ts-ignore
                  setValue('country', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t('ads_fields.country')}
                    placeholder={t('ads_fields.country')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                multiple
                id="tags-state"
                options={[]}
                defaultValue={entity?.state || []}
                freeSolo
                onChange={(e, v) => {
                  // @ts-ignore
                  setValue('state', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t('ads_fields.state')}
                    placeholder={t('ads_fields.state')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <SelectField<FormValues>
                controller={{ control, name: 'status' }}
                options={adsStatus}
                selectProps={{
                  defaultValue: entity?.status,
                }}
                label={t('ads_fields.status')}
                size="medium"
              />
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                id="tags-category"
                options={defaultCategory}
                defaultValue={entity?.category || ''}
                freeSolo
                onBlur={(e: any) => {
                  setValue('category', e.target?.value);
                }}
                onChange={(e, v) => {
                  // @ts-ignore
                  setValue('category', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t('app_fields.category')}
                    placeholder={t('app_fields.category')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <SelectField<FormValues>
                controller={{ control, name: 'isBusinessOnly' }}
                options={bussiness}
                selectProps={{
                  defaultValue: entity?.isBusinessOnly,
                }}
                label={t('app_fields.businessOnly')}
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={5}>
                <InputField<FormValues>
                  controller={{ control, name: 'latitude' }}
                  inputProps={{
                    fullWidth: true,
                    defaultValue:
                      entity?.exactLocation !== null
                        ? entity?.exactLocation.coordinates?.[1]
                        : '',
                  }}
                  size="medium"
                  label={t('app_fields.latitude')}
                />
              </Grid>
              <Grid item xs={5}>
                <InputField<FormValues>
                  controller={{ control, name: 'longitude' }}
                  inputProps={{
                    fullWidth: true,
                    defaultValue:
                      entity?.exactLocation !== null
                        ? entity?.exactLocation.coordinates?.[0]
                        : '',
                  }}
                  size="medium"
                  label={t('app_fields.longitude')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="button"
                variant="contained"
                size="small"
                // disabled={!formState.isDirty}
                style={{ marginTop: 22 }}
                loading={formState.isSubmitting}
                loadingPosition="start"
                startIcon={<ModeIcon />}
                onClick={submitForm}
              >
                {t('button.update')}
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

const LabelInputWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 10px;
`;

export default FormDetail;
