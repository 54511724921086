import React from 'react';
import { Box, Grid, styled, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { Card } from 'src/components/common';
import useForm from './useForm';

const Search: React.FC = () => {
  const { t } = useTranslation();
  const { formik } = useForm();

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    handleReset,
    values: { email, nickName },
  } = formik;

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="search"
                id="nickName"
                name="nickName"
                label={t('username')}
                onChange={handleChange}
                error={touched.nickName && Boolean(errors.nickName)}
                helperText={touched.nickName && errors.nickName}
                value={nickName}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="search"
                id="email"
                name="email"
                label={t('email')}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                value={email}
              />
            </Grid>
            <Grid item xs={1}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                style={{ marginTop: 22 }}
              >
                Search
              </LoadingButton>
            </Grid>
            <Grid item xs={1}>
              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22 }}
                onClick={handleReset}
              >
                Reset
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(
  ({ theme }) => `
    width: 100%;
    .MuiGrid-container {
      margin: 0;
      width: 100%;
      
      .MuiGrid-item:nth-of-type(1) {
        padding-left: 0;
      }
    }
    .MuiGrid-container:nth-of-type(1) {
      .MuiGrid-item {
        padding-top: 0;
      }
    }
  `,
);
export default Search;
