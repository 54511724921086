import React from 'react';
import { Container, Grid } from '@mui/material';
import { AppWidgetSummary } from 'src/components/common';
import { LoadingButton } from '@mui/lab';
import { syncData } from 'src/useCase/datahub';
import { useAppDispatch } from 'src/store';

type Props = {
  loading: boolean;
  entity: Response.StatisticsEntity | null;
};

const Main: React.FC<Props> = ({ entity }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const handleSyncData = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      await dispatch(syncData());
      setIsLoading(false);
    }, 1000);
  };
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <LoadingButton
          type="button"
          variant="contained"
          size="small"
          style={{ marginTop: 20, marginBottom: 20, left: 0 }}
          loadingPosition="start"
          loading={isLoading}
          onClick={handleSyncData}
        >
          Sync data from datahub
        </LoadingButton>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Account Business"
              total={entity?.totalAccountBusiness}
              color="info"
              icon={'bxs:user-check'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Account Not Include Business"
              total={entity?.totalAccountNotIncludeBusiness}
              color="error"
              icon={'bxs:user-x'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Posts"
              total={entity?.totalPosts}
              color="primary"
              icon={'material-symbols:post-add-rounded'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Hoods"
              total={entity?.totalHoods}
              color="warning"
              icon={'material-symbols:connect-without-contact'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Report"
              total={entity?.totalReports}
              color="error"
              icon={'material-symbols:report'}
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Main;
