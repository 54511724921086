import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ContentWrapperFull } from 'src/components/common';
import HoodAnalyticsContainer from 'src/containers/HoodAnalytics';
import useQueryUrl from '../../hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { fetchHoodAnalyticsResources } from 'src/useCase/statistics';
import Search from 'src/containers/UserAnalytics/Search';

const Page: App.FC = () => {
  const { t } = useTranslation();
  const query = useQueryUrl();
  const dispatch = useAppDispatch();
  const { start_date, end_date, filter } = query;

  useEffect(() => {
    const startDate =
      start_date || moment().subtract(1, 'weeks').format('YYYY-MM-DD');
    const endDate = end_date || moment().add(1, 'days').format('YYYY-MM-DD');
    dispatch(
      fetchHoodAnalyticsResources({
        startDate: startDate,
        endDate: endDate,
        filter: filter || 'day',
      }),
    );
  }, [query]);
  return (
    <ContentWrapperFull title={t('title.hood_analytics')}>
      <Search />
      <HoodAnalyticsContainer />
    </ContentWrapperFull>
  );
};

export const PATH = '/hood-analytics' as const;
export default Page;
