import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'src/store';
import { createResource } from '../../../../useCase/facebooks';

export const optionCategory = [
  {
    label: 'authority alerts',
    value: 'authority_alerts',
  },
  {
    label: 'authority info',
    value: 'authority_info',
  },
  {
    label: 'city or municipality',
    value: 'city_or_municipality',
  },
  {
    label: 'neighborhood council',
    value: 'neighborhood_council',
  },
  {
    label: 'local news',
    value: 'local_news',
  },
  {
    label: 'community alerts',
    value: 'community_alerts',
  },
  {
    label: 'idea & discussion',
    value: 'idea_and_discussion',
  },
  {
    label: 'questions & help',
    value: 'questions_and_help',
  },
  {
    label: 'location knowledge',
    value: 'community_knowledge',
  },
  {
    label: 'community fun',
    value: 'community_fun',
  },
  {
    label: 'lost & found',
    value: 'lost_and_found',
  },
  {
    label: 'buy and sell',
    value: 'buy_and_sell',
  },
  {
    label: 'culture and music',
    value: 'culture_and_music',
  },
  {
    label: 'sports and news',
    value: 'sports_and_news',
  },
  {
    label: 'traffic and street work',
    value: 'traffic_and_street_work',
  },
  {
    label: 'food cafes entertainment',
    value: 'food_cafes_entertainment',
  },
  {
    label: 'retail & shopping',
    value: 'retail_and_shopping',
  },
  {
    label: 'health and beauty',
    value: 'health_and_beauty_services',
  },
  {
    label: 'mobility and services',
    value: 'mobility_and_services',
  },
  {
    label: 'home and services',
    value: 'home_and_services',
  },
];

export const optionType = [
  { label: 'subscribe fanpage', value: 'subscribe_fanpage' },
  { label: 'create post fanpage', value: 'create_post_fanpage' },
];

export type FormValue = {
  pageId: string;
  pageAccessToken: string;
  type: string;
  pageName: string;
  userId: string;
  hoodId: { label: string; id: string } | any;
  category: string;
};

const useForm = () => {
  const dispatch = useAppDispatch();

  const schema = Yup.object().shape({});

  const defaultValues: FormValue = {
    pageId: '',
    pageAccessToken: '',
    type: 'create_post_fanpage',
    pageName: '',
    userId: '',
    hoodId: '',
    category: optionCategory[0].value,
  };

  const formInfo = useHookForm<FormValue>({
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'onSubmit',
  });

  const onSubmit = async (values: FormValue) => {
    const newHoodId = formInfo.watch('hoodId') === null ? '' : values.hoodId.id;

    await dispatch(createResource({ ...values, hoodId: newHoodId }));
  };

  return { formInfo, onSubmit };
};

export default useForm;
