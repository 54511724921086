import React from 'react';

import FormCreate from './Form';

type Props = {
  hoods: {
    entities: Response.HoodEntity[];
    loading: boolean;
  };
  users: {
    entities: Response.UserEntity[];
    loading: boolean;
  };
};

const Main: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <FormCreate {...props} />
    </React.Fragment>
  );
};

export default Main;
