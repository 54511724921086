import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export type FormValue = {
  username: string;
  email: string;
  phone: string;
  gender: string;
  birthdate: string;
};

type Props = {
  entity: Response.UserEntity | null;
};

const useForm = ({ entity }: Props) => {
  const schema = Yup.object().shape({
    username: Yup.string().required(),
    email: Yup.string().email().required(),
  });

  const defaultValues: Partial<FormValue> = {
    email: entity?.email || '',
    phone: entity?.phone || '',
    gender: entity?.gender || '',
  };

  const formInfo = useHookForm<FormValue>({
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'onSubmit',
  });
  return { ...formInfo };
};

export default useForm;
