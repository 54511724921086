import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';

import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME } from 'src/constants/app';

type Props = {
  loading: boolean;
  entities: any[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Time report',
      sortable: false,
      width: 310,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
      renderCell: ({ row }) => {
        if (row.week && row.year) {
          return `Week ${row.week}-${row.year}`;
        } else if (row.month && row.year) {
          return `Month ${row.month}-${row.year}`;
        }
      },
    },
    {
      field: 'click_count',
      headerName: 'Click count',
      sortable: false,
      width: 200,
      valueGetter: ({ value }) => {
        return value;
      },
    },
    {
      field: 'view_count',
      headerName: 'View count',
      sortable: false,
      width: 400,
      valueGetter: ({ value }) => {
        return value;
      },
    },
    {
      field: 'open_count',
      headerName: 'Open count',
      sortable: false,
      width: 400,
      valueGetter: ({ value }) => {
        return value;
      },
    },
  ];

  return (
    <Card>
      <div style={{ minHeight: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          getRowId={() => Math.random()}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          autoHeight={true}
          rowHeight={150}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default UserTable;
