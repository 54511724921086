import React from 'react';
import styled from 'styled-components';
import Table from './Table';

type MainProps = {
  entities: any[];
};

const Main: React.FC<MainProps> = ({ entities }) => {
  return (
    <React.Fragment>
      <MarginBottom />
      {entities && <Table entities={entities} />}
    </React.Fragment>
  );
};

const MarginBottom = styled.div`
  margin-bottom: 24px;
`;

export default Main;
