import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'src/store';
import { updateActivity } from 'src/useCase/activityDetail';
import { useCallback, useEffect, useState } from 'react';

export type FormValues = {
  headline: string;
  category: string;
  photo: any;
  hoodId: { label: string; id: string } | any;
  time: string;
  place: string;
  status: string;
  latitude: string;
  longitude: string;
  userId: { label: string; id: string } | any;
  contact: string;
  webLink: string;
  moreInfo: string;
  expirationDate: string;
  registerUrl: string;
};

type Data = Response.ActivityDetailEntity | null;
type Hoods = Response.HoodEntity[];
type Users = Response.UserEntity[];

const useFormDetail = (data: Data, hoods: Hoods, users: Users) => {
  const [loadingHood, setLoadingHood] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getDefaultValueHood = useCallback(
    (data: Response.ActivityDetailEntity | null) => {
      const hood = hoods.find((v) => String(v.id) === data?.hoodId);
      if (!hood) return null;

      return {
        label: String(hood?.name),
        id: String(hood?.id),
      };
    },
    [hoods],
  );

  const getDefaultValueUser = useCallback(
    (data: Response.ActivityDetailEntity | null) => {
      const user = users.find((v) => String(v.id) === data?.userId);
      if (!user) return null;

      return {
        label: user?.nickName ? String(user?.nickName) : String(user?.id),
        id: String(user?.id),
      };
    },
    [users],
  );

  const validationSchema = Yup.object().shape({
    headline: Yup.string().required(
      t('validated.required', { name: t('activity_fields.headline') }),
    ),
    category: Yup.string().required(
      t('validated.required', { name: t('activity_fields.category') }),
    ),
    place: Yup.string().required(
      t('validated.required', { name: t('activity_fields.place') }),
    ),
    status: Yup.string().required(
      t('validated.required', { name: t('activity_fields.status') }),
    ),
    contact: Yup.string().required(
      t('validated.required', { name: t('activity_fields.contact') }),
    ),
    moreInfo: Yup.string().required(
      t('validated.required', { name: t('activity_fields.moreInfo') }),
    ),
  });

  const form = useForm<FormValues>({
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!getDefaultValueHood(data)?.id) {
      setLoadingHood(true);
    }
    setLoadingHood(false);
  }, [data, hoods]);

  useEffect(() => {
    if (!getDefaultValueUser(data)?.id) {
      setLoadingUser(true);
    }
    setLoadingUser(false);
  }, [data, users]);

  useEffect(() => {
    const labelHood = data?.hood
      ? {
          label: data?.hood?.name,
          id: data?.hoodId,
        }
      : '';
    const labelUser = data?.user
      ? {
          label: data?.user.nick_name ?? data?.userId,
          id: data?.userId,
        }
      : '';
    const latitude =
      data?.activityLocation !== null
        ? data?.activityLocation.coordinates?.[1]
        : '';
    const longitude =
      data?.activityLocation !== null
        ? data?.activityLocation.coordinates?.[0]
        : '';
    form.reset({
      headline: data?.headline,
      category: data?.category,
      hoodId: labelHood || '',
      time: data?.time,
      place: data?.place,
      status: data?.status,
      latitude: latitude as string,
      longitude: longitude as string,
      userId: labelUser || '',
      contact: data?.contact,
      webLink: data?.webLink,
      moreInfo: data?.moreInfo,
      expirationDate: data?.expirationDate || '',
      registerUrl: data?.registerUrl || '',
    });
  }, [data]);

  const submitForm = () => {
    form.handleSubmit(async (values) => {
      await dispatch(
        updateActivity({
          id: data?.id as string,
          ...values,
        }),
      );
    })();
  };

  return { form, submitForm, loadingHood, loadingUser };
};

export default useFormDetail;
