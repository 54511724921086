import React from 'react';
import Box from '@mui/material/Box';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormHelperText, Grid, InputLabel, TextField } from '@mui/material';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'src/constants/app';

type Props<T> = {
  datePickerProps?: DatePickerProps;
  controller: UseControllerProps<T>;
  label?: string;
  errorCol?: 12 | 24;
  size?: 'small' | 'medium';
  format?: string;
};

function DatePickerField<T extends Record<string, any>>(props: Props<T>) {
  const {
    datePickerProps,
    controller,
    label,
    size = 'small',
    format = DATE_FORMAT,
  } = props;
  const { fieldState, field } = useController<T>(controller);
  const { error } = fieldState;

  return (
    <Box
      component="div"
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
    >
      <Grid container spacing={1}>
        {label && <InputLabel>{label}</InputLabel>}
        <DatePicker
          {...field}
          {...datePickerProps}
          inputFormat={format || DATE_FORMAT}
          value={field.value ? dayjs(field.value) : null}
          onChange={(dateString) => {
            field.onChange(dateString);
          }}
          renderInput={(params) => {
            return (
              <TextField
                style={{ width: '100%', margin: 0 }}
                size={size}
                {...params}
              />
            );
          }}
        />
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </Grid>
    </Box>
  );
}

export default DatePickerField;
