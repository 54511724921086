import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import {
  Button,
  FormControlLabel,
  LinearProgress,
  Switch,
} from '@mui/material';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME, PER } from 'src/constants/app';
import { useAppDispatch } from 'src/store';
import { bannedUser } from 'src/useCase/userList';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { Link } from 'react-router-dom';
import { PATH } from 'src/pages/user';

type Props = {
  loading: boolean;
  entities: Response.UserEntity[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const query = useQueryUrl();
  const dispatch = useAppDispatch();
  const { page, take } = query;
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 150,
    },
    {
      field: 'nickName',
      headerName: t('username'),
      sortable: false,
      width: 200,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'detail',
      headerName: t('detail'),
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            component={Link}
            variant="outlined"
            color="primary"
            to={PATH.USER_DETAIL.replace(':id', params.row.id)}
          >
            Detail
          </Button>
        );
      },
    },
    {
      field: 'email',
      headerName: t('email'),
      sortable: false,
      width: 250,
      valueGetter: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'totalPost',
      headerName: t('totalPost'),
      sortable: false,
      width: 100,
    },
    {
      field: 'createdAt',
      headerName: t('createdAt'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'updatedAt',
      headerName: t('updatedAt'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <FormControlLabel
            control={
              <Switch
                checked={params.row.isBanned}
                onChange={(event, checked) => {
                  const payload = {
                    id: params.row.id,
                    isBanned: !params.row.isBanned,
                    query: {
                      ...query,
                      page: page,
                      take: take || String(PER.DEFAULT),
                    },
                  };
                  dispatch(bannedUser(payload));
                }}
              />
            }
            label="Banned"
          />
        );
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default UserTable;
