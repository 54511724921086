import { Grid, styled, IconButton, FormHelperText } from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import React, { useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import 'external-svg-loader';

import { Card } from 'src/components/common';
import useForm, { FormValues } from './useForm';
import history from 'src/libs/history';
import {
  AutoCompleteField,
  DateTimePickerField,
  InputField,
} from 'src/components/form';
import SelectField from 'src/components/form/SelectField';
import { useAppDispatch } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';
import { PhotoCamera } from '@mui/icons-material';
import { category, eventStatus } from 'src/containers/EventDetail/Main/Form';

type Props = {
  hoods: Response.HoodEntity[];
  loading: boolean;
};

const FormDetail: React.FC<Props> = (props) => {
  const { hoods, loading } = props;

  const { form, onSubmit } = useForm();
  const { control, formState, setValue, handleSubmit, clearErrors } = form;
  const { t } = useTranslation();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [refIcon, setRefIcon] = React.useState<File | null>(null);
  const [refImage, setRefImage] = React.useState<File | null>(null);

  const getHoods = useMemo(() => {
    return hoods.map((v) => {
      return {
        label: String(v.name),
        id: String(v.id),
      };
    });
  }, [hoods]);

  return (
    <Wrapper>
      <Card>
        <Grid container marginLeft={0} spacing={2}>
          <Grid item xs={5}>
            <InputField<FormValues>
              controller={{ control, name: 'name' }}
              inputProps={{
                fullWidth: true,
              }}
              size="medium"
              label={'Name'}
            />
          </Grid>
          <Grid item xs={5}>
            <InputField<FormValues>
              controller={{ control, name: 'url' }}
              inputProps={{
                fullWidth: true,
              }}
              size="medium"
              label={'URL'}
            />
          </Grid>
          <Grid item xs={10}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                name="icon"
                onChange={(event) => {
                  if (
                    event &&
                    event.currentTarget &&
                    event.currentTarget.files
                  ) {
                    // @ts-ignore
                    setValue('icon', event.currentTarget.files[0]);
                    setRefIcon(event.currentTarget.files[0]);
                    clearErrors('icon');
                  }
                }}
              />
              <PhotoCamera />
            </IconButton>

            <img
              width={100}
              height={100}
              src={
                refIcon
                  ? URL.createObjectURL(refIcon)
                  : 'https://placehold.jp/100x100.png'
              }
            />
          </Grid>
          <Grid item xs={10}>
            {formState.errors.icon && (
              <FormHelperText className="Mui-error">
                {formState.errors.icon.message}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={5}>
            <AutoCompleteField<FormValues>
              controller={{ control, name: 'hoodId' }}
              options={getHoods || []}
              onOpen={() => setOpenHood(!openHood)}
              callbackOnchange={(v) => {
                dispatch(searchHoods(v));
              }}
              onClose={() => setOpenHood(!openHood)}
              loading={loading}
              label={t('ads_fields.hood')}
              size="medium"
            />
          </Grid>
          <Grid item xs={5}>
            <SelectField<FormValues>
              controller={{ control, name: 'category' }}
              options={category}
              label={'Category'}
              size="medium"
            />
          </Grid>
          <Grid item xs={4}>
            <InputField<FormValues>
              controller={{ control, name: 'city' }}
              inputProps={{
                fullWidth: true,
              }}
              label={t('ads_fields.city')}
              size="medium"
            />
          </Grid>
          <Grid item xs={3}>
            <InputField<FormValues>
              controller={{ control, name: 'country' }}
              inputProps={{
                fullWidth: true,
              }}
              label={t('ads_fields.country')}
              size="medium"
            />
          </Grid>
          <Grid item xs={3}>
            <InputField<FormValues>
              controller={{ control, name: 'state' }}
              inputProps={{
                fullWidth: true,
              }}
              label={t('ads_fields.state')}
              size="medium"
            />
          </Grid>
          <Grid item xs={5}>
            <DateTimePickerField<FormValues>
              controller={{ control, name: 'startDate' }}
              label={'Start Date'}
              size="medium"
            />
          </Grid>
          <Grid item xs={5}>
            <DateTimePickerField<FormValues>
              controller={{ control, name: 'endDate' }}
              label={'End Date'}
              size="medium"
            />
          </Grid>
          <Grid item xs={4}>
            <SelectField<FormValues>
              controller={{ control, name: 'status' }}
              options={eventStatus}
              label={t('ads_fields.status')}
              size="medium"
            />
          </Grid>
          <Grid item xs={3}>
            <InputField<FormValues>
              controller={{ control, name: 'longitude' }}
              size="medium"
              label={'Longitude'}
            />
          </Grid>
          <Grid item xs={3}>
            <InputField<FormValues>
              controller={{ control, name: 'latitude' }}
              size="medium"
              label={'Latitude'}
            />
          </Grid>
          <Grid item xs={12}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                name="image"
                onChange={(event) => {
                  if (
                    event &&
                    event.currentTarget &&
                    event.currentTarget.files
                  ) {
                    // @ts-ignore
                    setValue('image', event.currentTarget.files[0]);
                    setRefImage(event.currentTarget.files[0]);
                    clearErrors('image');
                  }
                }}
              />
              <PhotoCamera />
            </IconButton>

            <img
              width={300}
              height={300}
              src={
                refImage
                  ? URL.createObjectURL(refImage)
                  : 'https://placehold.jp/300x300.png'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputField<FormValues>
              controller={{ control, name: 'description' }}
              inputProps={{
                fullWidth: true,
              }}
              label={'Description'}
              size="medium"
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              type="button"
              variant="contained"
              size="small"
              style={{ marginTop: 22 }}
              loading={formState.isSubmitting}
              loadingPosition="start"
              startIcon={<ModeIcon />}
              onClick={handleSubmit(onSubmit)}
            >
              {t('button.create')}
            </LoadingButton>

            <LoadingButton
              variant="contained"
              size="small"
              style={{ marginTop: 22, marginLeft: 10 }}
              onClick={history.back}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormDetail;
