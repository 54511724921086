import React from 'react';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormHelperText, Grid, InputLabel } from '@mui/material';

type Props<T> = {
  inputProps?: TextFieldProps;
  controller: UseControllerProps<T>;
  label?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  errorCol?: 12 | 24;
  size?: 'small' | 'medium';
};

function InputField<T extends Record<string, any>>(props: Props<T>) {
  const {
    inputProps,
    controller,
    label,
    variant = 'outlined',
    size = 'small',
  } = props;
  const { fieldState, field } = useController<T>(controller);
  const { error } = fieldState;
  return (
    <Box
      component="div"
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
    >
      <Grid container spacing={1}>
        {label && <InputLabel>{label}</InputLabel>}
        <TextField
          style={{ width: '100%', margin: 0 }}
          variant={variant}
          {...field}
          {...inputProps}
          size={size}
        />
        {error && (
          <FormHelperText style={{ color: 'red' }}>
            {error.message}
          </FormHelperText>
        )}
      </Grid>
    </Box>
  );
}

export default InputField;
