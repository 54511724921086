import {
  Grid,
  styled,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Select,
  FormControl,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import _ from 'lodash';
import { PhotoCamera, Add, Delete } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'src/components/common';
import useForm from './useForm';
import history from 'src/libs/history';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';

type Props = {
  entities: Response.HoodEntity[];
  loading: boolean;
};

const FormCreate: React.FC<Props> = ({ entities, loading }) => {
  const [category, setCategory] = React.useState('');
  const [getStatus, setStatus] = React.useState('');
  const dispatch = useAppDispatch();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const loadingHood = loading;
  const { formik } = useForm(category);
  const refAppIcon = useRef<File | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openBusiness, setOpenBusiness] = React.useState(false);
  const [getBusiness, setBusiness] = React.useState('false');
  const [openStatus, setOpenStatus] = React.useState(false);
  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    setFieldValue,
    isSubmitting,
  } = formik;
  const { t } = useTranslation();

  const handleChangeSelectCategory = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleChangeSelectStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setFieldValue('status', event.target.value);
  };

  const handleChangeSelectBusiness = (event: SelectChangeEvent) => {
    setBusiness(event.target.value);
    setFieldValue('isBusinessOnly', event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listHood = useMemo(() => {
    if (openHood) {
      return entities.map((v) => {
        return {
          label: v.name,
          id: v.id,
        };
      });
    }
  }, [entities, openHood]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const handleOpenBusiness = () => {
    setOpenBusiness(true);
  };

  const handleCloseBusiness = () => {
    setOpenBusiness(false);
  };

  const categories = [
    {
      value: 'HOME',
      text: 'Home',
    },
    {
      value: 'WORK',
      text: 'Work',
    },
    {
      value: 'FAMILY',
      text: 'Family',
    },
    {
      value: 'HOLIDAY',
      text: 'Holiday',
    },
    {
      value: 'EVENT',
      text: 'Event',
    },
    {
      value: 'DREAM',
      text: 'Dream',
    },
    {
      value: 'OTHER',
      text: 'Other',
    },
  ];

  const status = [
    {
      value: 'pending_approval',
      text: 'Pending approval',
    },
    {
      value: 'active',
      text: 'Active',
    },
    {
      value: 'paused',
      text: 'Paused',
    },
    {
      value: 'archived',
      text: 'Archived',
    },
  ];

  const defaultCategory = ['city', 'food', 'services', 'authorities'];

  const [searchHood, setSearchHood] = useState<string>('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchHood) {
        dispatch(searchHoods(searchHood));
      }
    }, 1200);
    return () => clearTimeout(delayDebounceFn);
  }, [searchHood]);

  const [labels, setLabels] = useState<any>([]);

  useEffect(() => {
    if (_.isEmpty(labels)) {
      setFieldValue('appLabelText', '');
    } else {
      setFieldValue('appLabelText', JSON.stringify(labels));
    }
  }, [labels]);

  const addMoreLabels = () => {
    setLabels([...labels, { key: '', value: '' }]);
  };

  const onChangeValueLabelText = (index, value) => {
    const data = [...labels];
    data[index] = value;
    setLabels(data);
  };

  const removeLabelText = (index) => {
    const data = [...labels];
    data.splice(index, 1);
    setLabels(data);
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={11}>
              {labels.map((label, index) => {
                return (
                  <LabelInputWrapper>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        type="text"
                        id="appLabelText"
                        name="appLabelText"
                        label="Language Code"
                        value={labels[index].key}
                        onChange={(e) => {
                          onChangeValueLabelText(index, {
                            key: e.target.value,
                            value: labels[index].value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} marginLeft={2}>
                      <TextField
                        fullWidth
                        type="text"
                        id="appLabelText"
                        name="appLabelText"
                        label={'Label Text'}
                        value={labels[index].value}
                        onChange={(e) => {
                          onChangeValueLabelText(index, {
                            key: labels[index].key,
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <IconButton
                      style={{ marginLeft: 5 }}
                      color="primary"
                      aria-label="Remove"
                      component="label"
                      onClick={() => removeLabelText(index)}
                    >
                      <Delete />
                    </IconButton>
                  </LabelInputWrapper>
                );
              })}
              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22 }}
                loading={false}
                startIcon={<Add />}
                onClick={addMoreLabels}
              >
                ADD LABEL TEXT
              </LoadingButton>

              {touched.appLabelText && errors.appLabelText && (
                <FormHelperText className="Mui-error">
                  {errors.appLabelText}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="appAndroidUrl"
                name="appAndroidUrl"
                label={t('app_fields.appAndroidUrl')}
                onChange={handleChange}
                error={touched.appAndroidUrl && Boolean(errors.appAndroidUrl)}
                helperText={touched.appAndroidUrl && errors.appAndroidUrl}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="appIosUrl"
                name="appIosUrl"
                label={t('app_fields.appIosUrl')}
                onChange={handleChange}
                error={touched.appIosUrl && Boolean(errors.appIosUrl)}
                helperText={touched.appIosUrl && errors.appIosUrl}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                type="text"
                id="appDescription"
                name="appDescription"
                label={t('app_fields.appDescription')}
                onChange={handleChange}
                error={touched.appDescription && Boolean(errors.appDescription)}
                helperText={touched.appDescription && errors.appDescription}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="appDesktopUrl"
                name="appDesktopUrl"
                label={t('app_fields.appDesktopUrl')}
                onChange={handleChange}
                error={touched.appDesktopUrl && Boolean(errors.appDesktopUrl)}
                helperText={touched.appDesktopUrl && errors.appDesktopUrl}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="order"
                name="order"
                label={t('app_fields.order')}
                onChange={handleChange}
                error={touched.order && Boolean(errors.order)}
                helperText={touched.order && errors.order}
              />
            </Grid>
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="appIcon"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      refAppIcon.current = event.currentTarget.files[0];
                      setFieldValue('appIcon', event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {touched.appIcon && errors.appIcon && (
                <FormHelperText className="Mui-error">
                  {errors.appIcon}
                </FormHelperText>
              )}
              <img
                width={120}
                height="auto"
                src={
                  refAppIcon.current
                    ? URL.createObjectURL(refAppIcon.current)
                    : 'https://placehold.jp/120x120.png?text=120%20x%20120'
                }
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                multiple
                id="combo-box-demo"
                options={listHood || []}
                onChange={(e, v) => {
                  setFieldValue('hoodId', v);
                }}
                onInputChange={(e: any) => setSearchHood(e.target?.value)}
                loading={loadingHood}
                onOpen={() => setOpenHood(true)}
                onClose={() => setOpenHood(false)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    name="hoodId"
                    {...params}
                    label={t('ads_fields.hood')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingHood ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              {touched.hoodId && Boolean(errors.hoodId)}
            </Grid>
            <Grid item xs={5}>
              <FormControl sx={{ m: 1, minWidth: '100%', margin: 0 }}>
                <InputLabel id="controlled-open-select-label">
                  {t('ads_fields.hoodCategory')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-label"
                  id="controlled-open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={category}
                  label={t('ads_fields.hoodCategory')}
                  onChange={handleChangeSelectCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories.map((category, index) => {
                    return (
                      <MenuItem key={index} value={category.value}>
                        {category.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                multiple
                id="tags-cities"
                options={[]}
                freeSolo
                onChange={(e, v) => {
                  setFieldValue('city', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t('ads_fields.city')}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                    placeholder={t('ads_fields.city')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                multiple
                id="tags-country"
                options={[]}
                freeSolo
                onChange={(e, v) => {
                  setFieldValue('country', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t('ads_fields.country')}
                    placeholder={t('ads_fields.country')}
                    error={touched.country && Boolean(errors.country)}
                    helperText={touched.country && errors.country}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                multiple
                id="tags-state"
                options={[]}
                freeSolo
                onChange={(e, v) => {
                  setFieldValue('state', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t('ads_fields.state')}
                    placeholder={t('ads_fields.state')}
                    error={touched.state && Boolean(errors.state)}
                    helperText={touched.state && errors.state}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl
                sx={{ m: 1, minWidth: '100%', margin: 0 }}
                error={touched.status && Boolean(errors.status)}
              >
                <InputLabel id="controlled-open-select-status-label">
                  {t('ads_fields.status')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-status-label"
                  id="controlled-open-select-status"
                  open={openStatus}
                  onClose={handleCloseStatus}
                  onOpen={handleOpenStatus}
                  value={getStatus}
                  name="status"
                  label={t('ads_fields.status')}
                  onChange={handleChangeSelectStatus}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {status.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
                {touched.status && Boolean(errors.status) && (
                  <FormHelperText>{errors.status}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                id="tags-category"
                options={defaultCategory}
                onBlur={(e: any) => {
                  setFieldValue('category', e.target?.value);
                }}
                freeSolo
                onChange={(e, v) => {
                  setFieldValue('category', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={t('app_fields.category')}
                    placeholder={t('app_fields.category')}
                    error={touched.category && Boolean(errors.category)}
                    helperText={touched.category && errors.category}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl
                sx={{ m: 1, minWidth: '100%', margin: 0 }}
                error={touched.isBusinessOnly && Boolean(errors.isBusinessOnly)}
              >
                <InputLabel id="controlled-open-select-status-label">
                  {t('app_fields.businessOnly')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-status-label"
                  id="controlled-open-select-status"
                  open={openBusiness}
                  onClose={handleCloseBusiness}
                  onOpen={handleOpenBusiness}
                  value={getBusiness}
                  name="status"
                  label={t('app_fields.businessOnly')}
                  onChange={handleChangeSelectBusiness}
                >
                  <MenuItem value="true">
                    <em>True</em>
                  </MenuItem>
                  <MenuItem value="false">
                    <em>False</em>
                  </MenuItem>
                </Select>
                {touched.isBusinessOnly && Boolean(errors.isBusinessOnly) && (
                  <FormHelperText>{errors.isBusinessOnly}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="latitude"
                name="latitude"
                label={t('app_fields.latitude')}
                onChange={handleChange}
                error={touched.latitude && Boolean(errors.latitude)}
                helperText={touched.latitude && errors.latitude}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="longitude"
                name="longitude"
                label={t('app_fields.longitude')}
                onChange={handleChange}
                error={touched.longitude && Boolean(errors.longitude)}
                helperText={touched.longitude && errors.longitude}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Create
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

const LabelInputWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 10px;
`;

export default FormCreate;
