import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { useAppDispatch } from 'src/store';
import { createResource } from 'src/useCase/actionBanner';
import history from 'src/libs/history';
import { PATH } from 'src/pages/actionBanner/ActionBannerList';

export type FormValue = {
  bannerId: string;
  bannerInternalFunctionCode: string;
  language: string;
  bannerText: string;
  url: string;
  dateFrom: string;
  dateTo: string;
  hoodId: string;
  city: string;
  country: string;
  state: string;
  hoodCategory: string;
  bannerMobileLeaderBoard: string;
  bannerWebLeaderBoard: string;
  status: string;
  userId: string;
  textColor: string;
  tags: string[];
};

type Category = string;

const useForm = (category: Category) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const defaultValue: FormValue = {
    bannerId: '',
    bannerInternalFunctionCode: '',
    language: '',
    bannerText: '',
    url: '',
    dateFrom: new Date().toDateString(),
    dateTo: new Date().toDateString(),
    hoodId: '',
    city: '',
    country: '',
    state: '',
    hoodCategory: '',
    bannerMobileLeaderBoard: '',
    bannerWebLeaderBoard: '',
    status: '',
    userId: '',
    textColor: '',
    tags: [],
  };

  const validationSchema = Yup.object().shape({
    bannerId: Yup.string().when(
      ['bannerMobileLeaderBoard', 'bannerWebLeaderBoard'],
      {
        is: (bannerMobileLeaderBoard, bannerWebLeaderBoard) =>
          bannerMobileLeaderBoard && bannerWebLeaderBoard,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required(
          t('validated.required', { name: t('banner_fields.bannerId') }),
        ),
      },
    ),
    bannerText: Yup.string().required(
      t('validated.required', { name: t('ads_fields.buttonText') }),
    ),
    url: Yup.string()
      .required(t('validated.required', { name: t('ads_fields.url') }))
      .url(),
    dateFrom: Yup.date().required(
      t('validated.required', { name: t('ads_fields.dateFrom') }),
    ),
    dateTo: Yup.date()
      .required(t('validated.required', { name: t('ads_fields.dateTo') }))
      .when('dateFrom', (dateFrom, schema) => dateFrom && schema.min(dateFrom)),
    status: Yup.mixed().required(
      t('validated.required', { name: t('ads_fields.status') }),
    ),
    textColor: Yup.string()
      .nullable()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, t('ads_fields.textColor')),
  });

  const formik = useFormik<FormValue>({
    initialValues: {
      ...defaultValue,
    },
    validationSchema,
    async onSubmit(values) {
      await dispatch(createResource({ ...values, hoodCategory: category }));
      history.push(PATH);
    },
    validateOnChange: true,
  });

  return { formik };
};

export default useForm;
