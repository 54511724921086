import moment from 'src/libs/moment';
import { DATE_TIME_HOUR } from 'src/constants/app';

export const formatDate = (
  day: string | null,
  format: string = DATE_TIME_HOUR,
  defaultValue: string = '-',
  defaultFormat: string | null = null,
) => {
  if (!day) return defaultValue;

  if (moment(day).isValid() && moment) {
    if (defaultFormat) {
      return moment(day, defaultFormat).format(format);
    }
    return moment(day).format(format);
  }

  return defaultValue;
};

export function deepCloneObject<T>(object) {
  return JSON.parse(JSON.stringify(object)) as T;
}

export function getCustomMapWebUrl(mapCode: string) {
  return `${process.env.REACT_APP_WEB_APP_URL}/maps?code=${mapCode}`;
}

export const getEventCategory = (category: string): string => {
  const categories = {
    art: 'Art',
    movies: 'Movies',
    music: 'Music',
    sports: 'Sports',
    theater: 'Theater',
    trade_fair: 'Trade fair',
    dance: 'Dance',
    family_activities: 'Family activities',
    course: 'Course',
    religion: 'Religion',
    associations: 'Associations',
    authorities: 'Authorities',
    other: 'Other',
  };

  return categories[category] ?? 'Other';
};

export function isValidHttpUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
