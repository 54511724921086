import React from 'react';

import { PATH } from 'src/pages/actionBanner';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';

const Route: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH.BANNER_LIST,
        element: loadable(
          () => import('src/pages/actionBanner/ActionBannerList'),
        ),
      },
      {
        path: PATH.BANNER_CREATE,
        element: loadable(
          () => import('src/pages/actionBanner/ActionBannerCreate'),
        ),
      },
      {
        path: PATH.BANNER_Detail,
        element: loadable(
          () => import('src/pages/actionBanner/ActionBannerDetail'),
        ),
      },
    ],
  },
];

export default Route;
