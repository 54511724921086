import HTTPRequest from '../index';

const apiPath = '/v1/users';

export interface RequestBanned {
  id: string;
  isBanned?: boolean;
  arg: any;
}

export interface GetUserDetail {
  id: string;
}

class UserRequest extends HTTPRequest {
  getList(params: Payload.UserList) {
    return this.get<Response.UserListResponse>(apiPath, { params });
  }

  getDetail({ id }: GetUserDetail) {
    return this.get<Response.UserEntity>(`${apiPath}/${id}`);
  }

  bannedUser(id: string, data: { isBanned: boolean }) {
    return this.put(`${apiPath}/${id}/banned`, { ...data });
  }

  searchUsers(nickName: string = '') {
    if (nickName) {
      return this.get<Response.UserListResponse>(apiPath, {
        params: {
          nickName,
        },
      });
    }

    return this.get<Response.UserListResponse>(apiPath);
  }
}

export default new UserRequest();
