import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { PATH } from 'src/pages/post';

type Props = {
  loading: boolean;
  entities: Response.ReportEntity[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const { t } = useTranslation();

  const dataSource = entities.map((entity, index) => {
    return {
      id: index,
      content: entity?.post?.content === '' ? '-' : entity?.post?.content,
      ...entity,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'sk',
      headerName: t('sk'),
      sortable: false,
      width: 250,
    },
    {
      field: 'count',
      headerName: t('count'),
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.count ?? 0;
      },
    },
    {
      field: 'appId',
      headerName: t('appId'),
      sortable: false,
      width: 250,
    },
    {
      field: 'pk',
      headerName: t('pk'),
      sortable: false,
      width: 250,
    },
    {
      field: 'postId',
      headerName: t('postId'),
      sortable: false,
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link to={PATH.POST_DETAIL.replace(':id', params.row.postId)}>
            {params.row.postId}
          </Link>
        );
      },
    },
    {
      field: 'content',
      headerName: t('postContent'),
      sortable: false,
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineClamp: 1,
            }}
          >
            {params.row.content}
          </div>
        );
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={dataSource}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default UserTable;
