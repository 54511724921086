import {
  Grid,
  styled,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Select,
  FormControl,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'src/components/common';
import useForm from './useForm';
import history from 'src/libs/history';
import { useTranslation } from 'react-i18next';
import { DatePicker, LoadingButton } from '@mui/lab';
import { useAppDispatch } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';
import { searchUsers } from 'src/useCase/userList';

type Props = {
  hoods: {
    entities: Response.HoodEntity[];
    loading: boolean;
  };
  users: {
    entities: Response.UserEntity[];
    loading: boolean;
  };
};

const FormCreate: React.FC<Props> = ({ hoods, users }) => {
  const [category, setCategory] = React.useState('');
  const [getStatus, setStatus] = React.useState('');
  const dispatch = useAppDispatch();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const loadingHood = hoods.loading;
  const [openUser, setOpenUser] = useState<boolean>(false);
  const loadingUser = users.loading;
  const { formik } = useForm(category);
  const photo = useRef<File | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    isSubmitting,
  } = formik;
  const { t } = useTranslation();

  const handleChangeSelectCategory = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
    setFieldValue('category', event.target.value);
  };

  const handleChangeSelectStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setFieldValue('status', event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listHood = useMemo(() => {
    if (openHood) {
      return hoods.entities.map((v) => {
        return {
          label: v.name,
          id: v.id,
        };
      });
    }
  }, [hoods, openHood]);

  const listUser = useMemo(() => {
    if (openUser) {
      return users.entities.map((v) => {
        return {
          label: v.nickName,
          id: v.id,
        };
      });
    }
  }, [users, openUser]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const categories = [
    {
      value: 'sports_and_fitness',
      text: 'Sports & Fitness',
    },
    {
      value: 'culture_and_music',
      text: 'Culture & Music',
    },
    {
      value: 'buy_and_sell',
      text: 'Buy & Sell',
    },
    {
      value: 'community_fun',
      text: 'Community fun',
    },
    {
      value: 'voluntary_work',
      text: 'Voluntary work',
    },
    {
      value: 'kids_family',
      text: 'Kids & Family',
    },
    {
      value: 'other',
      text: 'Other Activities',
    },
    {
      value: 'attractions',
      text: 'Attractions',
    },
    {
      value: 'food_and_restaurants',
      text: 'Food And Restaurants',
    },
    {
      value: 'shopping',
      text: 'Shopping',
    },
  ];

  const status = [
    {
      value: 'pending_approval',
      text: 'Pending approval',
    },
    {
      value: 'active',
      text: 'Active',
    },
    {
      value: 'paused',
      text: 'Paused',
    },
    {
      value: 'archived',
      text: 'Archived',
    },
  ];

  const [searchHood, setSearchHood] = useState<string>('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchHood) {
        dispatch(searchHoods(searchHood));
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchHood]);

  const [searchUser, setSearchUser] = useState<string>('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchUser) {
        dispatch(searchUsers(searchUser));
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchUser]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="headline"
                name="headline"
                label={t('activity_fields.headline')}
                onChange={handleChange}
                error={touched.headline && Boolean(errors.headline)}
                helperText={touched.headline && errors.headline}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl sx={{ m: 1, minWidth: '100%', margin: 0 }}>
                <InputLabel id="controlled-open-select-label">
                  {t('activity_fields.category')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-banner-id-label"
                  id="controlled-open-select-banner-id"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={category}
                  name="category"
                  label={t('activity_fields.category')}
                  onChange={handleChangeSelectCategory}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
                {touched.category && Boolean(errors.category) && (
                  <FormHelperText className="Mui-error">
                    {errors.category}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                id="combo-box-demo"
                options={listHood || []}
                onChange={(e, v) => {
                  setFieldValue('hoodId', v?.id);
                }}
                onInputChange={(e: any) => setSearchHood(e.target?.value)}
                loading={loadingHood}
                onOpen={() => setOpenHood(true)}
                onClose={() => setOpenHood(false)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    name="hoodId"
                    {...params}
                    label={t('activity_fields.hoodId')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingHood ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              {touched.hoodId && Boolean(errors.hoodId)}
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                id="combo-box-demo"
                options={listUser || []}
                onChange={(e, v) => {
                  setFieldValue('userId', v?.id);
                }}
                onInputChange={(e: any) => setSearchUser(e.target?.value)}
                loading={loadingUser}
                onOpen={() => setOpenUser(true)}
                onClose={() => setOpenUser(false)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    name="userId"
                    {...params}
                    label={t('activity_fields.userId')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingUser ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              {touched.userId && Boolean(errors.userId)}
            </Grid>
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="photo"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      photo.current = event.currentTarget.files[0];
                      setFieldValue('photo', event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {touched.photo && errors.photo && (
                <FormHelperText className="Mui-error">
                  {errors.photo}
                </FormHelperText>
              )}
              <img
                width={320}
                height="auto"
                src={
                  photo.current
                    ? URL.createObjectURL(photo.current)
                    : 'https://placehold.jp/320x50.png?text=320+x+50+~+320+x+300'
                }
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="time"
                name="time"
                label={t('activity_fields.time')}
                onChange={handleChange}
                error={touched.time && Boolean(errors.time)}
                helperText={touched.time && errors.time}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="place"
                name="place"
                label={t('activity_fields.place')}
                onChange={handleChange}
                error={touched.place && Boolean(errors.place)}
                helperText={touched.place && errors.place}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="latitude"
                name="latitude"
                label={t('activity_fields.latitude')}
                onChange={handleChange}
                error={touched.latitude && Boolean(errors.latitude)}
                helperText={touched.latitude && errors.latitude}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="longitude"
                name="longitude"
                label={t('activity_fields.longitude')}
                onChange={handleChange}
                error={touched.longitude && Boolean(errors.longitude)}
                helperText={touched.longitude && errors.longitude}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="contact"
                name="contact"
                label={t('activity_fields.contact')}
                onChange={handleChange}
                error={touched.contact && Boolean(errors.contact)}
                helperText={touched.contact && errors.contact}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="webLink"
                name="webLink"
                label={t('activity_fields.webLink')}
                onChange={handleChange}
                error={touched.webLink && Boolean(errors.webLink)}
                helperText={touched.webLink && errors.webLink}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl
                sx={{ m: 1, minWidth: '100%', margin: 0 }}
                error={touched.status && Boolean(errors.status)}
              >
                <InputLabel id="controlled-open-select-status-label">
                  {t('activity_fields.status')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-status-label"
                  id="controlled-open-select-status"
                  open={openStatus}
                  onClose={handleCloseStatus}
                  onOpen={handleOpenStatus}
                  value={getStatus}
                  name="status"
                  label={t('activity_fields.status')}
                  onChange={handleChangeSelectStatus}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {status.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
                {touched.status && Boolean(errors.status) && (
                  <FormHelperText>{errors.status}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="moreInfo"
                name="moreInfo"
                label={t('activity_fields.moreInfo')}
                onChange={handleChange}
                error={touched.moreInfo && Boolean(errors.moreInfo)}
                helperText={touched.moreInfo && errors.moreInfo}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="text"
                id="registerUrl"
                name="registerUrl"
                label={'Register Url'}
                onChange={handleChange}
                error={touched.registerUrl && Boolean(errors.registerUrl)}
                helperText={touched.registerUrl && errors.registerUrl}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                value={values.expirationDate}
                label="Expiration Date"
                onChange={(value) => {
                  setFieldValue('expirationDate', value?.toString(), true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="expirationDate"
                    name="expirationDate"
                    label="Expiration Date"
                    error={
                      touched.expirationDate && Boolean(errors.expirationDate)
                    }
                    helperText={touched.expirationDate && errors.expirationDate}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                style={{ marginTop: 22 }}
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Create
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormCreate;
