import _ from 'lodash';
import HTTPRequest from '../index';

const apiPath = '/v1/events';

export interface CreateEventPayload {
  name: string;
  url: string;
  category: string;
  icon: string;
  hoodId: string;
  city: string;
  country: string;
  state: string;
  status: string;
  latitude: string;
  longitude: string;
  startDate: string;
  endDate: string;
  image: string;
  description: string;
}

export interface UpdateEventPayload {
  params: CreateEventPayload;
  id: string;
}

class EventRequest extends HTTPRequest {
  getList(params: Payload.AdsList) {
    return this.get<any>(apiPath, { params });
  }

  create(data: CreateEventPayload) {
    let formData = new FormData();
    formData.append('icon', data.icon);
    formData.append('name', data.name);
    formData.append('url', data.url);
    formData.append('category', data.category);
    formData.append('hoodId', data.hoodId);
    formData.append('city', data.city);
    formData.append('country', data.country);
    formData.append('state', data.state);
    formData.append('status', data.status);
    formData.append('startDate', data.startDate);
    formData.append('image', data.image);
    formData.append('description', data.description);
    if (!_.isEmpty(data.latitude)) formData.append('latitude', data.latitude);
    if (!_.isEmpty(data.longitude))
      formData.append('longitude', data.longitude);
    if (!_.isEmpty(data.endDate)) formData.append('endDate', data.endDate);

    return this.post(`${apiPath}/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getDetailEvent(id: string) {
    return this.get<any>(`${apiPath}/${id}`);
  }

  deleteEvent(id: string) {
    return this.delete(`${apiPath}/${id}`);
  }

  updateEvent(data: UpdateEventPayload) {
    let id = data.id;
    let formData = data.params;

    return this.put(`${apiPath}/${id}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new EventRequest();
