import * as Yup from 'yup';
import { useFormik } from 'formik';
import qs from 'query-string';
import _ from 'lodash';

import { deepCloneObject } from 'src/utils/helpers';
import history from 'src/libs/history';
import useQueryUrl from 'src/hooks/useQueryUrl';

export type ISearchUser = {
  email: string;
  nickName: string;
};

const useForm = () => {
  const validationSchema = Yup.object().shape({});
  const query = useQueryUrl();

  const defaultValue: ISearchUser = {
    email: '',
    nickName: '',
  };

  const formik = useFormik<ISearchUser>({
    initialValues: {
      ...defaultValue,
      ...query,
    },
    validationSchema,
    async onSubmit(values, meta) {
      const payload: ISearchUser = deepCloneObject(values);

      history.push({
        search: qs.stringify({
          ..._.omitBy({ ...query, ...payload }, _.isEmpty),
        }),
      });
    },
  });

  return { formik };
};

export default useForm;
