import React from 'react';
import lodash from 'lodash';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import { useConfirm } from 'material-ui-confirm';
import { useParams } from 'react-router';
import { LoadingButton } from '@mui/lab';
import { FormHelperText, TextareaAutosize } from '@mui/material';

import useForm from './useForm';
import { Card, RowDetail } from 'src/components/common';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/store';
import { deletePost } from 'src/useCase/postDetail';
import Map from './Map';

type Props = {
  entity: Response.PostEntity | null;
  loading: boolean;
};

const Detail: React.FC<Props> = ({ entity, loading }) => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { formik } = useForm();
  const handleClick = () => {
    confirm({ description: 'This will permanently delete post' })
      .then(async () => {
        await dispatch(deletePost({ id: String(id) }));
      })
      .catch(() => {
        /* ... */
      });
  };
  const {
    handleSubmit,
    errors,
    handleChange,
    values: { content },
  } = formik;
  const coordinates = lodash.get(entity, 'location.coordinates', []);
  const location = {
    lat: lodash.get(coordinates, '[1]', 0),
    lng: lodash.get(coordinates, '[0]', 0),
  };
  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <RowDetail title={t('id')} value={entity?.id} />
        <RowDetail title={t('email')} value={entity?.user?.email ?? '-'} />
        <RowDetail
          title={t('username')}
          value={entity?.user?.nickName ?? '-'}
        />
        <RowDetail title={t('likeCount')} value={entity?.likeCount ?? '-'} />
        <RowDetail
          title={t('totalReports')}
          value={entity?.totalReports ?? '-'}
        />
        <RowDetail
          title={t('commentCount')}
          value={entity?.commentCount ?? '-'}
        />
        <RowDetail title={t('status')} value={entity?.status} />
        <RowDetail title={t('createdAt')} value={entity?.createdAt ?? '-'} />
        <RowDetail title={t('updatedAt')} value={entity?.updatedAt ?? '-'} />
        <TextareaAutosize
          style={{
            width: '100%',
          }}
          id="content"
          name="content"
          maxRows={5}
          minRows={5}
          onChange={handleChange}
          value={content}
        />
        {errors.content && (
          <FormHelperText style={{ color: 'red' }}>
            {errors.content}
          </FormHelperText>
        )}
        <ImageList sx={{ width: '100%' }} cols={3} gap={8}>
          {!!entity &&
            entity.postMedias.map((item) => (
              <ImageListItem key={item.id}>
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
        </ImageList>
        <div style={{ marginBottom: '20px' }}>
          <Map
            apiKey="AIzaSyBEKDomqoh74x6sz5zGYYS7GU2hY84dvFk"
            location={location}
          />
        </div>
        <Button variant="contained" color="error" onClick={handleClick}>
          Delete
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          style={{ marginLeft: 22 }}
          loading={loading}
        >
          Update
        </LoadingButton>
      </form>
    </Card>
  );
};

export default Detail;
