import {
  Grid,
  styled,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  FormControlLabel,
  SelectChangeEvent,
  Select,
  FormControl,
  FormHelperText,
  Switch,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'src/components/common';
import useForm from './useForm';
import history from 'src/libs/history';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';
import { searchUsers } from 'src/useCase/userList';

type Props = {
  hoods: {
    entities: Response.HoodEntity[];
    loading: boolean;
  };
  users: {
    entities: Response.UserEntity[];
    loading: boolean;
  };
};

const FormCreate: React.FC<Props> = ({ hoods, users }) => {
  const [getStatus, setStatus] = React.useState('');
  const dispatch = useAppDispatch();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const loadingHood = hoods.loading;
  const loadingUser = users.loading;
  const { formik } = useForm();
  const refMarker = useRef<File | null>(null);
  const [openStatus, setOpenStatus] = React.useState(false);
  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    isSubmitting,
  } = formik;
  const { t } = useTranslation();

  const handleChangeSelectStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setFieldValue('status', event.target.value);
  };

  const listHood = useMemo(() => {
    if (openHood) {
      return hoods.entities.map((v) => {
        return {
          label: v.name,
          id: v.id,
        };
      });
    }
  }, [hoods.entities, openHood]);

  const listUser = useMemo(() => {
    if (openUser && users.entities.length) {
      return users.entities.map((v) => {
        return {
          label: v.nickName,
          id: v.id,
        };
      });
    }
  }, [users.entities, openUser]);

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  const status = [
    {
      value: 'pending_approval',
      text: 'Pending approval',
    },
    {
      value: 'active',
      text: 'Active',
    },
    {
      value: 'paused',
      text: 'Paused',
    },
    {
      value: 'archived',
      text: 'Archived',
    },
  ];

  const [searchHood, setSearchHood] = useState<string>('');

  const [searchUser, setSearchUser] = useState<string>('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchHood) {
        dispatch(searchHoods(searchHood));
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchHood]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchUser) {
        dispatch(searchUsers(searchUser));
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchUser]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type="text"
                id="url"
                name="name"
                label={'Map name'}
                onChange={handleChange}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            {/* Image 320 x 50 ?��? Mobile Leaderboard */}
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="marker"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      refMarker.current = event.currentTarget.files[0];
                      setFieldValue('marker', event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {touched.marker && errors.marker && (
                <FormHelperText className="Mui-error">
                  {errors.marker}
                </FormHelperText>
              )}
              <img
                width={100}
                height={100}
                src={
                  refMarker.current
                    ? URL.createObjectURL(refMarker.current)
                    : 'https://placehold.jp/100x100.png'
                }
              />
            </Grid>

            <Grid item xs={8}>
              <FormControlLabel
                name="allowOtherUsers"
                control={
                  <Switch
                    size="medium"
                    defaultChecked={false}
                    onChange={handleChange}
                  />
                }
                label="Allow Other Users"
              />
            </Grid>

            <Grid item xs={8}>
              <FormControlLabel
                name="showOnlyToLocal"
                control={
                  <Switch
                    size="medium"
                    defaultChecked={false}
                    onChange={handleChange}
                  />
                }
                label="Show Only To Local"
              />
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                id="combo-box-demo"
                options={listUser || []}
                onChange={(e, v) => {
                  setFieldValue('userId', v?.id);
                }}
                onInputChange={(e: any) => setSearchUser(e.target?.value)}
                loading={loadingUser}
                onOpen={() => setOpenUser(true)}
                onClose={() => setOpenUser(false)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    name="userId"
                    {...params}
                    label={t('banner_fields.userId')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingUser ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              {touched.userId && Boolean(errors.userId)}
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                id="combo-box-demo"
                options={listHood || []}
                onChange={(e, v) => {
                  setFieldValue('hoodId', v?.id);
                }}
                onInputChange={(e: any) => setSearchHood(e.target?.value)}
                loading={loadingHood}
                onOpen={() => setOpenHood(true)}
                onClose={() => setOpenHood(false)}
                includeInputInList
                renderInput={(params) => (
                  <TextField
                    type="text"
                    name="hoodId"
                    {...params}
                    label={t('ads_fields.hood')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingHood ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              {touched.hoodId && Boolean(errors.hoodId)}
            </Grid>

            <Grid item xs={8}>
              <TextField
                fullWidth
                type="text"
                id="city"
                name="city"
                label={t('ads_fields.city')}
                onChange={handleChange}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type="text"
                id="country"
                name="country"
                label={t('ads_fields.country')}
                onChange={handleChange}
                error={touched.country && Boolean(errors.country)}
                helperText={touched.country && errors.country}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type="text"
                id="state"
                name="state"
                label={t('ads_fields.state')}
                onChange={handleChange}
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state && errors.state}
              />
            </Grid>
            <Grid item xs={8}>
              <FormControl
                sx={{ m: 1, minWidth: '100%', margin: 0 }}
                error={touched.status && Boolean(errors.status)}
              >
                <InputLabel id="controlled-open-select-status-label">
                  {t('ads_fields.status')}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="controlled-open-select-status-label"
                  id="controlled-open-select-status"
                  open={openStatus}
                  onClose={handleCloseStatus}
                  onOpen={handleOpenStatus}
                  value={getStatus}
                  name="status"
                  label={t('ads_fields.status')}
                  onChange={handleChangeSelectStatus}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {status.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
                {touched.status && Boolean(errors.status) && (
                  <FormHelperText>{errors.status}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                style={{ marginTop: 22 }}
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Create
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormCreate;
