import React from 'react';

import { PATH } from 'src/pages/user';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';

const Route: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH.USER_LIST,
        element: loadable(() => import('src/pages/user/UserList')),
      },
      {
        path: PATH.USER_DETAIL,
        element: loadable(() => import('src/pages/user/UserDetail')),
      },
      {
        path: PATH.USER_UPDATE,
        element: loadable(() => import('src/pages/user/UserUpdate')),
      },
    ],
  },
];

export default Route;
