import HTTPRequest from '../index';
import _ from 'lodash';

const apiPath = '/v1/datahub';

class DatahubRequest extends HTTPRequest {
  create() {
    return this.post(`${apiPath}`);
  }
}

export default new DatahubRequest();
