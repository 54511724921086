import React, { useEffect } from 'react';

import Container from 'src/containers/PostList';
import { ContentWrapper } from 'src/components/common';
import { t } from 'src/libs/i18n';
import { fetchResources } from 'src/useCase/postList';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { PER } from 'src/constants/app';

const PostListPage: React.FC = () => {
  const query = useQueryUrl();
  const dispatch = useAppDispatch();
  const { page, take } = query;

  useEffect(() => {
    dispatch(
      fetchResources({
        ...query,
        page: page,
        take: take || String(PER.DEFAULT),
      }),
    );
  }, [query]);
  return (
    <ContentWrapper title={t('title.post_list')}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/post_list' as const;
export default PostListPage;
