import {
  Grid,
  styled,
  CircularProgress,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { Card } from 'src/components/common';
import useForm, { FormValues } from './useForm';
import history from 'src/libs/history';
import {
  AutoCompleteField,
  DatePickerField,
  InputField,
} from 'src/components/form';
import SelectField from 'src/components/form/SelectField';
import { useAppDispatch, useAppSelector } from 'src/store';
// import { getHoods as getLisHood } from 'src/useCase/hoodList';
import { searchHoods } from 'src/useCase/hoodList';
import { PhotoCamera } from '@mui/icons-material';

type Props = {
  hoods: Response.HoodEntity[];
  entity: Response.AdsDetailEntity | null;
  loading: boolean;
};

const FormDetail: React.FC<Props> = (props) => {
  const { hoods, entity, loading } = props;

  const { form, submitForm } = useForm(entity, hoods);
  const { control, formState, setValue } = form;
  const { t } = useTranslation();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const valueHood = useAppSelector((state) => state.domain.hood.hoods);
  const [refBannerMobileLeaderBoard, setRefBannerMobileLeaderBoard] =
    React.useState<File | null>(null);

  const [refBannerWebLeaderBoard, setRefBannerWebLeaderBoard] =
    React.useState<File | null>(null);

  const [refSpotlightImage, setRefSpotlightImage] = React.useState<File | null>(
    null,
  );

  // const [refBannerLargeLeaderBoard, setRefBannerLargeLeaderBoard] =
  //   React.useState<File | null>(null);

  useEffect(() => {
    if (entity) {
      (async () => {
        if (entity.hood) {
          await dispatch(searchHoods(entity?.hood?.name));
        } else {
          await dispatch(searchHoods(''));
        }
        // @ts-ignore
      })();
    }
  }, [entity]);

  const getHoods = useMemo(() => {
    return valueHood.entities.map((v) => {
      return {
        label: String(v.name),
        id: String(v.id),
      };
    });
  }, [hoods]);

  const category = [
    {
      value: 'HOME',
      label: 'Home',
    },
    {
      value: 'WORK',
      label: 'Work',
    },
    {
      value: 'FAMILY',
      label: 'Family',
    },
    {
      value: 'HOLIDAY',
      label: 'Holiday',
    },
    {
      value: 'EVENT',
      label: 'Event',
    },
    {
      value: 'DREAM',
      label: 'Dream',
    },
    {
      value: 'OTHER',
      label: 'Other',
    },
  ];

  const adsStatus = [
    {
      value: 'pending_approval',
      label: 'Pending approval',
    },
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'paused',
      label: 'Paused',
    },
    {
      value: 'archived',
      label: 'Archived',
    },
  ];

  return (
    <Wrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'title' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={t('ads_fields.title')}
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'url' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.url,
                }}
                size="medium"
                label={t('ads_fields.url')}
              />
            </Grid>
            <Grid item xs={10}>
              <InputField<FormValues>
                controller={{ control, name: 'content' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.content,
                }}
                label={t('ads_fields.content')}
                size="medium"
              />
            </Grid>

            <Grid item xs={10}>
              <InputField<FormValues>
                controller={{ control, name: 'featureText' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.featureText,
                }}
                label={'Feature Text'}
                size="medium"
              />
            </Grid>

            <Grid item xs={7}>
              <Autocomplete
                multiple
                id="tags"
                options={[]}
                defaultValue={entity?.tags?.map((item: any) => item.name) || []}
                freeSolo
                onChange={(e, v) => {
                  // @ts-ignore
                  setValue('tags', v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label={'Hashtag'}
                    placeholder={'Please enter hashtag'}
                  />
                )}
              />
            </Grid>
            {/* Image 320 x 50 Mobile Leaderboard */}
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="bannerMobileLeaderBoard"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      setValue(
                        'bannerMobileLeaderBoard',
                        event.currentTarget.files[0],
                        { shouldDirty: true },
                      );
                      setRefBannerMobileLeaderBoard(
                        event.currentTarget.files[0],
                      );
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              <img
                width={320}
                height="auto"
                src={
                  refBannerMobileLeaderBoard
                    ? URL.createObjectURL(refBannerMobileLeaderBoard)
                    : `${process.env.REACT_APP_MEDIA_URL}${entity?.bannerMobileLeaderBoard}`
                }
                loading="lazy"
                onError={(e) => {
                  if (e.currentTarget.src) {
                    e.currentTarget.title = 'no image';
                  }
                }}
              />
            </Grid>
            {/* Image 728 x 90  Leaderboard Banner (Web) */}
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="bannerWebLeaderBoard"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      setValue(
                        'bannerWebLeaderBoard',
                        event.currentTarget.files[0],
                        { shouldDirty: true },
                      );
                      setRefBannerWebLeaderBoard(event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              <img
                width={728}
                height={90}
                src={
                  refBannerWebLeaderBoard
                    ? URL.createObjectURL(refBannerWebLeaderBoard)
                    : `${process.env.REACT_APP_MEDIA_URL}${entity?.bannerWebLeaderBoard}`
                }
                loading="lazy"
                onError={(e) => {
                  if (e.currentTarget.src) {
                    e.currentTarget.title = 'no image';
                  }
                }}
              />
            </Grid>

            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="spotlightImage"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      setValue('spotlightImage', event.currentTarget.files[0], {
                        shouldDirty: true,
                      });
                      setRefSpotlightImage(event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              <img
                width={300}
                height={300}
                src={
                  refSpotlightImage
                    ? URL.createObjectURL(refSpotlightImage)
                    : entity?.spotlightImage
                    ? `${process.env.REACT_APP_MEDIA_URL}${entity?.spotlightImage}`
                    : 'https://placehold.jp/300x300.png'
                }
                loading="lazy"
                onError={(e) => {
                  if (e.currentTarget.src) {
                    e.currentTarget.title = 'no image';
                  }
                }}
              />
            </Grid>

            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'language' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.language,
                }}
                label={t('ads_fields.language')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'buttonText' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.buttonText,
                }}
                label={t('ads_fields.buttonText')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <DatePickerField<FormValues>
                controller={{ control, name: 'dateFrom' }}
                label="Date From"
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <DatePickerField<FormValues>
                controller={{ control, name: 'dateTo' }}
                label="Date To"
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <AutoCompleteField<FormValues>
                controller={{ control, name: 'hoodId' }}
                options={getHoods || []}
                onOpen={() => setOpenHood(!openHood)}
                onClose={() => setOpenHood(!openHood)}
                loading={valueHood.loading}
                callbackOnchange={(v) => {
                  dispatch(searchHoods(v));
                }}
                label={t('ads_fields.hood')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField<FormValues>
                controller={{ control, name: 'hoodCategory' }}
                options={category}
                selectProps={{
                  defaultValue: entity?.hoodCategory,
                }}
                label={t('ads_fields.hoodCategory')}
                size="medium"
              />
            </Grid>
            <Grid item xs={4}>
              <InputField<FormValues>
                controller={{ control, name: 'city' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.city,
                }}
                label={t('ads_fields.city')}
                size="medium"
              />
            </Grid>
            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'country' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.country,
                }}
                label={t('ads_fields.country')}
                size="medium"
              />
            </Grid>
            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'state' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.state || '',
                }}
                label={t('ads_fields.state')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField<FormValues>
                controller={{ control, name: 'status' }}
                options={adsStatus}
                selectProps={{
                  defaultValue: entity?.status,
                }}
                label={t('ads_fields.status')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'officeName' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.officeName,
                }}
                label={t('ads_fields.officeName')}
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="button"
                variant="contained"
                size="small"
                disabled={!formState.isDirty}
                style={{ marginTop: 22 }}
                loading={formState.isSubmitting}
                loadingPosition="start"
                startIcon={<ModeIcon />}
                onClick={submitForm}
              >
                {t('button.update')}
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormDetail;
