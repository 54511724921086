import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'src/store';
import { updateApp } from 'src/useCase/appDetail';
import { useCallback, useEffect, useState } from 'react';

export type FormValues = {
  appDescription: string;
  appLabelText: string;
  appAndroidUrl: string;
  appIosUrl: string;
  appDesktopUrl: string;
  order: string;
  // hoodId: { label: string; id: string } | any;
  hoodId: [] | any;
  city: [];
  country: [];
  state: [];
  hoodCategory: string;
  appIcon: any;
  status: string;
  category: string;
  isBusinessOnly: string;
  latitude: string;
  longitude: string;
};

type Data = Response.AppDetailEntity | null;
type Hoods = Response.HoodEntity[];

const useFormDetail = (data: Data, hoods: Hoods) => {
  const [loadingHood, setLoadingHood] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // const getDefaultValueHood = useCallback(
  //   (data: Response.AppDetailEntity | null) => {
  //     const hood = hoods.find((v) => String(v.id) === data?.hoodId);
  //     if (!hood) return null;
  //
  //     return {
  //       label: String(hood?.name),
  //       id: String(hood?.id),
  //     };
  //   },
  //   [hoods],
  // );

  const validationSchema = Yup.object().shape({
    appLabelText: Yup.string().required(
      t('validated.required', { name: t('ads_fields.title') }),
    ),
    status: Yup.string().required(
      t('validated.required', { name: t('ads_fields.status') }),
    ),
    appDescription: Yup.string().required(
      t('validated.required', { name: 'App description' }),
    ),
    order: Yup.string().required(t('validated.required', { name: 'Order' })),
  });

  const form = useForm<FormValues>({
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    // if (!getDefaultValueHood(data)?.id) {
    //   setLoadingHood(true);
    // }
    setLoadingHood(false);
  }, [data, hoods]);

  useEffect(() => {
    // const labelHood = data?.hood
    //   ? {
    //       label: data?.hood?.name,
    //       id: data?.hoodId,
    //     }
    //   : '';
    const latitude =
      data?.exactLocation !== null ? data?.exactLocation.coordinates?.[1] : '';
    const longitude =
      data?.exactLocation !== null ? data?.exactLocation.coordinates?.[0] : '';
    form.reset({
      appDescription: data?.appDescription,
      appLabelText: data?.appLabelText,
      appAndroidUrl: data?.appAndroidUrl,
      appIosUrl: data?.appIosUrl,
      appDesktopUrl: data?.appDesktopUrl,
      order: data?.order,
      // hoodId: labelHood || '',
      hoodId: data?.hoodId,
      city: data?.city,
      country: data?.country,
      state: data?.state,
      hoodCategory: data?.hoodCategory,
      status: data?.status,
      category: data?.category,
      isBusinessOnly: data?.isBusinessOnly,
      latitude: latitude as string,
      longitude: longitude as string,
    });
  }, [data]);

  const submitForm = () => {
    form.handleSubmit(async (values) => {
      await dispatch(
        updateApp({
          id: data?.id as string,
          ...values,
        }),
      );
    })();
  };

  return { form, submitForm, loadingHood };
};

export default useFormDetail;
