import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import Container from 'src/containers/ActivityList';
import { ContentWrapper } from 'src/components/common';
import { t } from 'src/libs/i18n';
import { fetchResources } from 'src/useCase/activity';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { PER } from 'src/constants/app';
import { Button } from '@mui/material';

import { PATH as ACTIVITY_CREATE } from './ActivityCreate';
import Search from 'src/containers/ActivityList/Search';

const Page: React.FC = () => {
  const query = useQueryUrl();
  const dispatch = useAppDispatch();
  const { page, take, status } = query;

  useEffect(() => {
    dispatch(
      fetchResources({
        ...query,
        page: page,
        take: take || String(PER.DEFAULT),
        status: status === 'all' ? '' : status,
      }),
    );
  }, [query]);
  return (
    <>
      <Search />
      <ContentWrapper
        title={t('title.activity_list')}
        extra={
          <Button
            startIcon={<AddIcon />}
            component={Link}
            variant="contained"
            to={ACTIVITY_CREATE}
          >
            {t('button.create') as string}
          </Button>
        }
      >
        <Container />
      </ContentWrapper>
    </>
  );
};

export const PATH = '/activity_list' as const;
export default Page;
