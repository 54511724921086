import ActivityList, { PATH as ACTIVITY_LIST } from './ActivityList';
import { PATH as ACTIVITY_CREATE } from './ActivityCreate';
import { PATH as ACTIVITY_DETAIL } from './ActivityDetail';
import { PATH as ACTIVITY_SEARCH_LIST } from './SearchList';

export default { ActivityList };
export const PATH = {
  ACTIVITY_LIST,
  ACTIVITY_CREATE,
  ACTIVITY_DETAIL,
  ACTIVITY_SEARCH_LIST,
};
