import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import hoodActions from 'src/store/domain/hood/list';
import orderHoodActions from 'src/store/domain/hood/hoods';
import hoodRequest from 'src/api/requests/hood';
import appActions from 'src/store/ui/app';

interface FetchResources extends SearchParams.User {}

const getParams = (args: FetchResources) => {
  const { take, page } = args;

  const params: Payload.UserList = {
    take,
    page,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, FetchResources>(
  'get_hood_list',
  async (args, { dispatch }) => {
    try {
      dispatch(hoodActions.updateLoading(true));

      const params = getParams(args);
      const res = await hoodRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(hoodActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(hoodActions.updateLoading(false));
    } catch (err) {
      dispatch(hoodActions.updateLoading(false));
    }
  },
);

export const getHoods = createAsyncThunk<void, void>(
  'get_hoods',
  async (_, { dispatch }) => {
    try {
      dispatch(orderHoodActions.updateLoading(true));
      const res = await hoodRequest.getHoods();

      const { data } = res.data;

      if (data) {
        dispatch(orderHoodActions.updateEntities(data));
      }

      dispatch(orderHoodActions.updateLoading(false));
    } catch (err) {
      dispatch(orderHoodActions.updateLoading(false));
    }
  },
);

export const searchHoods = createAsyncThunk<void, string>(
  'search_hoods',
  async (search, { dispatch }) => {
    try {
      dispatch(orderHoodActions.updateLoading(true));
      const res = await hoodRequest.searchHoods(search);

      const data: any = res.data;
      if (data) {
        dispatch(orderHoodActions.updateEntities(data));
      }

      dispatch(orderHoodActions.updateLoading(false));
    } catch (err) {
      dispatch(orderHoodActions.updateLoading(false));
    }
  },
);
