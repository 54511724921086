import React, { PropsWithChildren } from 'react';
import { Card, CardContent, Divider, Typography } from '@mui/material';

type Props = {
  title?: string | React.ReactElement;
  extra?: React.ReactElement;
};

const CardCustom: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
  extra,
}) => {
  return (
    <Card>
      {title && (
        <React.Fragment>
          <Typography
            component="div"
            style={{
              padding: '16px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {title}
            {extra}
          </Typography>
          <Divider />
        </React.Fragment>
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardCustom;
