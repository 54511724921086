import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  loading: boolean;
  entities: any[];
};

const initialState: State = {
  loading: false,
  entities: [],
};

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'activity_search_history',
  initialState,
  reducers: {
    updateEntities: (state, action: PayloadAction<any[]>) => {
      state.entities = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export { reducer };
export default actions;
