import React, { useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import {
  Button,
  Typography,
  Popover,
  // Box,
  // Modal,
  // CircularProgress,
  LinearProgress,
  // Divider,
  // Avatar,
  // Grid,
  // Paper,
  // IconButton,
} from '@mui/material';
// import { Close } from '@mui/icons-material';

import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME_HOUR, PER } from 'src/constants/app';
import { useAppDispatch } from 'src/store';
import { deleteComment, fetchResources } from 'src/useCase/commentList';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { Link } from 'react-router-dom';
import { PATH } from '../../../../pages/post';

type Props = {
  loading: boolean;
  entities: Response.CommentEntity[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const query = useQueryUrl();
  const { page, take } = query;
  // const [open, setOpen] = useState(false);
  const [children, setChildren] = useState<any>([]);
  // const handleOpen = (children) => {
  //   setOpen(true);
  //   setChildren(children);
  // };
  // const handleClose = () => setOpen(false);

  const handleClick = (id, removeChildren = false) => {
    confirm({ description: 'This will permanently delete comment' }).then(
      async () => {
        await dispatch(deleteComment({ id: String(id) }));
        await dispatch(
          fetchResources({
            ...query,
            page: page,
            take: take || String(PER.DEFAULT),
          }),
        );
        if (removeChildren) {
          const data = _.filter(children, (c: any) => {
            return c.id != id;
          });
          if (data.length === 0) {
            // setOpen(false);
          }
          setChildren(data);
        }
      },
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 250,
    },
    {
      field: 'postId',
      headerName: 'Post Id',
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link to={PATH.POST_DETAIL.replace(':id', params.row.postId)}>
            {params.row.postId}
          </Link>
        );
      },
    },
    {
      field: 'nickname',
      headerName: 'Nickname',
      sortable: false,
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        return <p>{params.row.user?.nickName}</p>;
      },
    },
    {
      field: 'content',
      headerName: 'Content',
      sortable: false,
      width: 450,
    },
    {
      field: 'View',
      headerName: 'View',
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
          null,
        );

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
          <div>
            <Button
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
            >
              View
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 2 }}>{params.row.content}</Typography>
            </Popover>
          </div>
        );
      },
    },
    {
      field: 'likeCount',
      headerName: 'Like Count',
      sortable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      sortable: false,
      width: 250,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME_HOUR) || '-';
      },
    },
    // {
    //   field: 'children',
    //   headerName: 'Children',
    //   sortable: false,
    //   width: 150,
    //   renderCell: (params: GridRenderCellParams) => {
    //     return (
    //       <>
    //         {params.row.children.length > 0 ? (
    //           <Button
    //             onClick={() => {
    //               handleOpen(params.row.children);
    //             }}
    //           >
    //             children ({params.row.children.length})
    //           </Button>
    //         ) : (
    //           '-'
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleClick(params.row.id);
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  return (
    <Card>
      {/*<Modal*/}
      {/*  open={open}*/}
      {/*  onClose={handleClose}*/}
      {/*  aria-labelledby="modal-modal-title"*/}
      {/*  aria-describedby="modal-modal-description"*/}
      {/*>*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      position: 'absolute',*/}
      {/*      top: '50%',*/}
      {/*      left: '50%',*/}
      {/*      transform: 'translate(-50%, -50%)',*/}
      {/*      minWidth: '500px',*/}
      {/*      backgroundColor: '#fff',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Box display="flex" alignItems="center">*/}
      {/*      <Box flexGrow={1}>*/}
      {/*        <h2 style={{ marginLeft: '20px' }}>Comment</h2>*/}
      {/*      </Box>*/}
      {/*      <Box>*/}
      {/*        <IconButton onClick={() => setOpen(false)}>*/}
      {/*          <Close />*/}
      {/*        </IconButton>*/}
      {/*      </Box>*/}
      {/*    </Box>*/}
      {/*    <div style={{ width: '100%' }}>*/}
      {/*      <Paper*/}
      {/*        style={{*/}
      {/*          padding: '40px 20px',*/}
      {/*          borderRadius: 'unset',*/}
      {/*          boxShadow: 'unset',*/}
      {/*          overflow: 'auto',*/}
      {/*          height: '500px',*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        {loading ? (*/}
      {/*          <div*/}
      {/*            style={{*/}
      {/*              display: 'flex',*/}
      {/*              justifyContent: 'center',*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <CircularProgress />*/}
      {/*          </div>*/}
      {/*        ) : (*/}
      {/*          children.map((item: any, index) => {*/}
      {/*            return (*/}
      {/*              <div key={index}>*/}
      {/*                {index != 0 && (*/}
      {/*                  <Divider*/}
      {/*                    variant="fullWidth"*/}
      {/*                    style={{ margin: '30px 0' }}*/}
      {/*                  />*/}
      {/*                )}*/}
      {/*                <Grid container wrap="nowrap" spacing={2}>*/}
      {/*                  <Grid item>*/}
      {/*                    <Avatar*/}
      {/*                      alt={item.user.nickName}*/}
      {/*                      src={item.user.avatar}*/}
      {/*                    />*/}
      {/*                  </Grid>*/}
      {/*                  <Grid justifyContent="left" item xs zeroMinWidth>*/}
      {/*                    <h4 style={{ margin: 0, textAlign: 'left' }}>*/}
      {/*                      {item.user.nickName}*/}
      {/*                    </h4>*/}
      {/*                    <p style={{ textAlign: 'left' }}>{item.content}</p>*/}
      {/*                    <p*/}
      {/*                      style={{*/}
      {/*                        display: 'flex',*/}
      {/*                        justifyContent: 'space-between',*/}
      {/*                      }}*/}
      {/*                    >*/}
      {/*                      {formatDate(item.createdAt, DATE_TIME_HOUR)}*/}
      {/*                      <Button*/}
      {/*                        variant="contained"*/}
      {/*                        color="error"*/}
      {/*                        onClick={async () => {*/}
      {/*                          await handleClick(item.id, true);*/}
      {/*                        }}*/}
      {/*                      >*/}
      {/*                        Delete*/}
      {/*                      </Button>*/}
      {/*                    </p>*/}
      {/*                  </Grid>*/}
      {/*                </Grid>*/}
      {/*              </div>*/}
      {/*            );*/}
      {/*          })*/}
      {/*        )}*/}
      {/*      </Paper>*/}
      {/*    </div>*/}
      {/*  </Box>*/}
      {/*</Modal>*/}
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={entities}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default UserTable;
