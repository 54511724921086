import React from 'react';

import Table from './Table';

type MainProps = {
  loading: boolean;
  entities: Response.CommentEntity[];
};

const Main: React.FC<MainProps> = (props) => {
  return (
    <React.Fragment>
      <Table {...props} />
    </React.Fragment>
  );
};

export default Main;
