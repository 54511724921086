import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, alpha, lighten, useTheme, Typography } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import _ from 'lodash';

import Sidebar from './Sidebar';
import Header from './Header';
import LocalStorage, { AdminRole } from 'src/utils/LocalStorage';
import { PATH } from 'src/pages/auth';

interface SidebarLayoutProps {
  children?: ReactNode;
  roleAccept?: AdminRole[];
}

const SidebarLayout: FC<PropsWithChildren<SidebarLayoutProps>> = ({
  children,
  roleAccept = [AdminRole.SuperAdmin],
}) => {
  const theme = useTheme();
  const accessToken = LocalStorage.accessToken;
  const user = LocalStorage.getUserInfo();
  const isAccepted = _.includes(roleAccept, user?.role);

  if (!accessToken) {
    return <Navigate to={PATH.SIGN_IN} replace />;
  }

  if (!isAccepted) {
    return (
      <Box
        sx={{
          flex: 1,
          height: '100%',
          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15,
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1,
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05,
                  )}`,
          },
        }}
      >
        <Sidebar />
        <Header />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`,
            },
          }}
        >
          <Box
            display="block"
            justifyContent="center"
            textAlign="center"
            mt={20}
          >
            <Typography variant="h3">Permission Denied</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15,
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1,
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05,
                  )}`,
          },
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`,
            },
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
