import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { useAppDispatch } from 'src/store';
import { createResource } from 'src/useCase/activity';
import history from 'src/libs/history';
import { PATH } from 'src/pages/activity/ActivityList';

export type FormValue = {
  headline: string;
  category: string;
  photo: string;
  hoodId: string;
  time: string;
  place: string;
  status: string;
  latitude: string;
  longitude: string;
  userId: string;
  contact: string;
  webLink: string;
  moreInfo: string;
  expirationDate: string;
  registerUrl: string;
};

type Category = string;

const useForm = (category: Category) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const defaultValue: FormValue = {
    headline: '',
    category: '',
    photo: '',
    hoodId: '',
    time: '',
    place: '',
    status: '',
    latitude: '',
    longitude: '',
    userId: '',
    contact: '',
    webLink: '',
    moreInfo: '',
    expirationDate: '',
    registerUrl: '',
  };

  const validationSchema = Yup.object().shape({
    headline: Yup.string().required(
      t('validated.required', { name: t('activity_fields.headline') }),
    ),
    category: Yup.string().required(
      t('validated.required', { name: t('activity_fields.category') }),
    ),
    hoodId: Yup.string().required(
      t('validated.required', { name: t('activity_fields.hoodId') }),
    ),
    place: Yup.string().required(
      t('validated.required', { name: t('activity_fields.place') }),
    ),
    status: Yup.string().required(
      t('validated.required', { name: t('activity_fields.status') }),
    ),
    userId: Yup.string().required(
      t('validated.required', { name: t('activity_fields.userId') }),
    ),
    contact: Yup.string().required(
      t('validated.required', { name: t('activity_fields.contact') }),
    ),
    moreInfo: Yup.string().required(
      t('validated.required', { name: t('activity_fields.moreInfo') }),
    ),
  });

  const formik = useFormik<FormValue>({
    initialValues: {
      ...defaultValue,
    },
    validationSchema,
    async onSubmit(values) {
      await dispatch(createResource({ ...values }));
      history.push(PATH);
    },
    validateOnChange: true,
  });

  return { formik };
};

export default useForm;
