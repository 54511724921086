import React, { useEffect } from 'react';

import Container from 'src/containers/HoodImageListDetail';
import { ContentWrapper } from 'src/components/common';
import { fetchHoodImageDetailResources } from 'src/useCase/hoodImages';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { PER } from 'src/constants/app';
import { useParams } from 'react-router-dom';

const HoodImageListPage: React.FC = () => {
  const query = useQueryUrl();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { page, take } = query;

  useEffect(() => {
    dispatch(
      fetchHoodImageDetailResources({
        ...query,
        hoodId: id ?? '',
        page: page,
        take: take || String(PER.DEFAULT),
      }),
    );
  }, [query]);
  return (
    <ContentWrapper title={'Hood Image'}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/hood_images/:id' as const;
export default HoodImageListPage;
