import React from 'react';

import { useAppSelector } from 'src/store';
import Main from './Main';

const Container: React.FC = () => {
  const props = useAppSelector((state) => state.domain.user.detail);

  return <Main {...props} />;
};

export default Container;
