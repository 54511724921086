import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import shareListActions from 'src/store/domain/share/list';
import shareRequest from 'src/api/requests/share';
import appActions from 'src/store/ui/app';

interface FetchResources extends SearchParams.Share {}

const getParams = (args: FetchResources) => {
  const { type, take, page } = args;

  const params: Payload.ShareList = {
    take,
    page,
    type,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, FetchResources>(
  'get_share_list',
  async (args, { dispatch }) => {
    try {
      dispatch(shareListActions.updateLoading(true));

      const params = getParams(args);
      const res = await shareRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(shareListActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(shareListActions.updateLoading(false));
    } catch (err) {
      dispatch(shareListActions.updateLoading(false));
    }
  },
);
