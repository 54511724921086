import React from 'react';
import qs from 'query-string';

import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useTranslation } from 'react-i18next';
import history from 'src/libs/history';
import Table from './Table';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

type MainProps = {
  loading: boolean;
  entities: Response.PostEntity[];
};

type QueryType = {
  type?: any;
  exclude_internal?: any;
};

const Main: React.FC<MainProps> = (props) => {
  const query = useQueryUrl();
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    const queryParams = new URLSearchParams(window.location.search);
    const excludeInternal = queryParams.get('exclude_internal') as any;
    let query: QueryType = {
      type: newValue,
    };
    if (excludeInternal) {
      query = {
        ...query,
        exclude_internal: excludeInternal,
      };
    }
    history.push({
      search: qs.stringify(query),
    });
  };

  const handleChangeExcludeInternal = (event, newValue) => {
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type') as any;
    let query: QueryType = {
      exclude_internal: newValue,
    };
    if (type) {
      query = {
        ...query,
        type,
      };
    }
    history.push({
      search: qs.stringify(query),
    });
  };

  return (
    <React.Fragment>
      <Box sx={{ width: '100%' }}>
        <FormGroup>
          <FormControlLabel
            control={<Switch />}
            onChange={handleChangeExcludeInternal}
            label={'Exclude internal'}
          />
        </FormGroup>
        <TabContext value={String(query.type ?? 'point_post')}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label={t('pointPost')} value={'point_post'} />
              <Tab label={t('hoodPost')} value={'hood_post'} />
            </TabList>
          </Box>
          <TabPanel value={'point_post'} style={{ padding: 0 }}>
            <Table {...props} />
          </TabPanel>
          <TabPanel value={'hood_post'} style={{ padding: 0 }}>
            <Table {...props} />
          </TabPanel>
        </TabContext>
      </Box>
    </React.Fragment>
  );
};

export default Main;
