import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import userPostActions from 'src/store/domain/post/list';
import postRequest from 'src/api/requests/post';
import appActions from 'src/store/ui/app';

interface FetchResources extends SearchParams.Post {}

const getParams = (args: FetchResources) => {
  const { take, page, type, exclude_internal } = args;

  const params: Payload.PostList = {
    take,
    page,
    type,
    exclude_internal,
  };

  return params;
};

export const fetchResources = createAsyncThunk<void, FetchResources>(
  'get_post_list',
  async (args, { dispatch }) => {
    try {
      dispatch(userPostActions.updateLoading(true));

      const params = getParams(args);
      const res = await postRequest.getList(_.omitBy(params, _.isEmpty));

      const { data, meta } = res.data;

      if (data) {
        dispatch(userPostActions.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appActions.updatePagination({
            page: meta.page,
            take: meta.take,
            itemCount: meta.itemCount,
            pageCount: meta.pageCount,
          }),
        );
      }
      dispatch(userPostActions.updateLoading(false));
    } catch (err) {
      dispatch(userPostActions.updateLoading(false));
    }
  },
);
