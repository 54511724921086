import React, { useEffect } from 'react';

import Container from 'src/containers/UserList';
import { ContentWrapper } from 'src/components/common';
import { t } from 'src/libs/i18n';
import { fetchResources } from 'src/useCase/userList';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { useAppDispatch } from 'src/store';
import { PER } from 'src/constants/app';

const Page: React.FC = () => {
  const query = useQueryUrl();
  const dispatch = useAppDispatch();
  const { page, take } = query;

  useEffect(() => {
    dispatch(
      fetchResources({
        isBusiness: 'false',
        ...query,
        page: page,
        take: take || String(PER.DEFAULT),
      }),
    );
  }, [query]);
  return (
    <ContentWrapper title={t('title.user_list')}>
      <Container />
    </ContentWrapper>
  );
};

export const PATH = '/user_list' as const;
export default Page;
