import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  latitude: number | undefined;
  longitude: number | undefined;
}

const MapLink: FC<PropsWithChildren<Props>> = ({ latitude, longitude }) => {
  if (!latitude || !longitude) {
    return null;
  }
  const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
  return (
    <Wrapper>
      <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
        View On Google Map
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 8vh;
`;

export default MapLink;
