import { Grid, styled, IconButton, CircularProgress } from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import 'external-svg-loader';

import { Card } from 'src/components/common';
import useForm, { FormValues } from './useForm';
import history from 'src/libs/history';
import {
  AutoCompleteField,
  InputField,
  DateTimePickerField,
} from 'src/components/form';
import SelectField from 'src/components/form/SelectField';
import { useAppDispatch, useAppSelector } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';
import { PhotoCamera } from '@mui/icons-material';
import _ from 'lodash';

export const category = [
  {
    value: 'art',
    label: 'Art',
  },
  {
    value: 'movies',
    label: 'Movies',
  },
  {
    value: 'music',
    label: 'Music',
  },
  {
    value: 'sports',
    label: 'Sports',
  },
  {
    value: 'theater',
    label: 'Theater',
  },
  {
    value: 'trade_fair',
    label: 'Trade fair',
  },
  {
    value: 'dance',
    label: 'Dance',
  },
  {
    value: 'family_activities',
    label: 'Family activities',
  },
  {
    value: 'course',
    label: 'Course',
  },
  {
    value: 'religion',
    label: 'Religion',
  },
  {
    value: 'associations',
    label: 'Associations',
  },
  {
    value: 'authorities',
    label: 'Authorities',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const eventStatus = [
  {
    value: 'pending_approval',
    label: 'Pending approval',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'paused',
    label: 'Paused',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];

export type Props = {
  hoods: Response.HoodEntity[];
  loadingHoods: boolean;
  loading: boolean;
  entity: Response.EventDetailResponse | null;
};

const FormDetail: React.FC<Props> = (props) => {
  const { hoods, loadingHoods, entity, loading } = props;

  const { form, onSubmit } = useForm(entity);
  const { control, formState, setValue, handleSubmit } = form;
  const { t } = useTranslation();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const valueHood = useAppSelector((state) => state.domain.hood.hoods);
  const dispatch = useAppDispatch();
  const [refIcon, setRefIcon] = React.useState<File | null>(null);
  const [refImage, setRefImage] = React.useState<File | null>(null);

  useEffect(() => {
    if (entity) {
      (async () => {
        if (entity.hood) {
          await dispatch(searchHoods(entity?.hood?.name));
        } else {
          await dispatch(searchHoods(''));
        }
      })();
    }
  }, [entity]);

  const getHoods = useMemo(() => {
    return valueHood.entities.map((v) => {
      return {
        label: String(v.name),
        id: String(v.id),
      };
    });
  }, [hoods]);

  const showIconPreview = () => {
    if (refIcon) return URL.createObjectURL(refIcon);

    if (entity?.icon)
      return `${process.env.REACT_APP_MEDIA_URL}${entity?.icon}`;

    return 'https://placehold.jp/100x100.png';
  };

  const iconPreview = showIconPreview();

  const showImagePreview = () => {
    if (refImage) return URL.createObjectURL(refImage);

    if (entity?.image)
      return `${process.env.REACT_APP_MEDIA_URL}${entity?.image}`;

    return 'https://placehold.jp/300x300.png';
  };

  const imagePreview = showImagePreview();

  return (
    <Wrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            {entity?.location && (
              <Grid item xs={10}>
                <a
                  href={`http://maps.google.com/maps?q=${_.get(
                    entity,
                    'location.coordinates.[1]',
                    '',
                  )},${_.get(entity, 'location.coordinates.[0]', '')}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Maps Location
                </a>
              </Grid>
            )}
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'name' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={'Name'}
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'url' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={'URL'}
              />
            </Grid>
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="icon"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      // @ts-ignore
                      setValue('icon', event.currentTarget.files[0], {
                        shouldDirty: true,
                      });
                      setRefIcon(event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              <img width={100} height={100} src={iconPreview} alt={''} />
            </Grid>

            <Grid item xs={5}>
              <AutoCompleteField<FormValues>
                controller={{ control, name: 'hoodId' }}
                options={getHoods || []}
                onOpen={() => setOpenHood(!openHood)}
                callbackOnchange={(v) => {
                  dispatch(searchHoods(v));
                }}
                onClose={() => setOpenHood(!openHood)}
                loading={loadingHoods}
                label={t('ads_fields.hood')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField<FormValues>
                controller={{ control, name: 'category' }}
                options={category}
                label={'Category'}
                selectProps={{
                  defaultValue: entity?.category,
                }}
                size="medium"
              />
            </Grid>
            <Grid item xs={4}>
              <InputField<FormValues>
                controller={{ control, name: 'city' }}
                inputProps={{
                  fullWidth: true,
                }}
                label={t('ads_fields.city')}
                size="medium"
              />
            </Grid>
            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'country' }}
                inputProps={{
                  fullWidth: true,
                }}
                label={t('ads_fields.country')}
                size="medium"
              />
            </Grid>
            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'state' }}
                inputProps={{
                  fullWidth: true,
                }}
                label={t('ads_fields.state')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <DateTimePickerField<FormValues>
                controller={{ control, name: 'startDate' }}
                label={'Start Date'}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <DateTimePickerField<FormValues>
                controller={{ control, name: 'endDate' }}
                label={'End Date'}
                size="medium"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectField<FormValues>
                controller={{ control, name: 'status' }}
                options={eventStatus}
                selectProps={{
                  defaultValue: entity?.status,
                }}
                label={t('ads_fields.status')}
                size="medium"
              />
            </Grid>
            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'longitude' }}
                size="medium"
                label={'Longitude'}
              />
            </Grid>
            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'latitude' }}
                size="medium"
                label={'Latitude'}
              />
            </Grid>
            <Grid item xs={12}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="image"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      // @ts-ignore
                      setValue('image', event.currentTarget.files[0], {
                        shouldDirty: true,
                      });
                      setRefImage(event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              <img width={300} height={300} src={imagePreview} alt={''} />
            </Grid>
            <Grid item xs={12}>
              <InputField<FormValues>
                controller={{ control, name: 'description' }}
                inputProps={{
                  fullWidth: true,
                }}
                label={'description'}
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="button"
                variant="contained"
                size="small"
                disabled={!formState.isDirty}
                style={{ marginTop: 22 }}
                loading={formState.isSubmitting}
                loadingPosition="start"
                startIcon={<ModeIcon />}
                onClick={handleSubmit(onSubmit)}
              >
                {t('button.update')}
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormDetail;
