import {
  Grid,
  styled,
  CircularProgress,
  IconButton,
  Button,
  Stack,
  Autocomplete,
  TextField,
} from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import 'external-svg-loader';

import { Card } from 'src/components/common';
import useForm, { FormValues } from './useForm';
import history from 'src/libs/history';
import { AutoCompleteField, InputField } from 'src/components/form';
import SelectField from 'src/components/form/SelectField';
import { useAppDispatch, useAppSelector } from 'src/store';
import { searchHoods } from 'src/useCase/hoodList';
import { PhotoCamera, Link as LinkIcon } from '@mui/icons-material';
import _ from 'lodash';
import { getCustomMapWebUrl } from 'src/utils/helpers';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import customMapRequest from 'src/api/requests/customMaps';
import { attachCustomMap, detachCustomMap } from 'src/useCase/linkDetail';
import { map_icons } from 'src/constants/optionsSelect/common';
import { searchUsers } from 'src/useCase/userList';

type Props = {
  hoods: Response.HoodEntity[];
  users: Response.UserEntity[];
  entity: any | null;
  loading: boolean;
};

const FormDetail: React.FC<Props> = (props) => {
  const { hoods, users, entity, loading } = props;

  const { form, onSubmit } = useForm(entity, hoods, users);
  const { control, formState, setValue, handleSubmit } = form;
  const { t } = useTranslation();
  const [openHood, setOpenHood] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const valueHood = useAppSelector((state) => state.domain.hood.hoods);
  const valueUser = useAppSelector((state) => state.domain.user.list);
  const [refIcon, setRefIcon] = React.useState<File | null>(null);
  const [refPhoto, setRefPhoto] = React.useState<File | null>(null);

  const isSvgImage =
    entity?.icon && entity.icon.includes('.svg') && refIcon === null;

  useEffect(() => {
    if (entity) {
      (async () => {
        if (entity.hood) {
          await dispatch(searchHoods(entity?.hood?.name));
        } else {
          await dispatch(searchHoods(''));
        }
        if (entity.user) {
          await dispatch(searchUsers(entity.user?.nick_name));
        } else {
          await dispatch(searchUsers(''));
        }
      })();
    }
  }, [entity]);

  const getHoods = useMemo(() => {
    return valueHood.entities.map((v) => {
      return {
        label: String(v.name),
        id: String(v.id),
      };
    });
  }, [hoods]);

  const getUsers = useMemo(() => {
    return valueUser.entities.map((v) => {
      return {
        label: String(v.nickName),
        id: String(v.id),
      };
    });
  }, [users]);

  const category = [
    {
      value: 'authorities_and_city',
      label: 'Authorities & City',
    },
    {
      value: 'public_services',
      label: 'Public services',
    },
    {
      value: 'associations',
      label: 'Associations',
    },
    {
      value: 'commercial_services',
      label: 'Commercial services',
    },
    {
      value: 'custom',
      label: 'Custom',
    },
  ];

  const adsStatus = [
    {
      value: 'pending_approval',
      label: 'Pending approval',
    },
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'paused',
      label: 'Paused',
    },
    {
      value: 'archived',
      label: 'Archived',
    },
  ];

  const confirm = [
    {
      value: 'true',
      label: 'YES',
    },
    {
      value: 'false',
      label: 'NO',
    },
  ];

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [mapOptions, setMapOption] = useState<any>([]);
  const [searchMap, setSearchMap] = useState<string>('');
  const [mapSelected, setMapSelected] = useState<any>(null);
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchMap) {
        try {
          const { data } = await customMapRequest.getList({
            name: searchMap,
            limit: 20,
          });
          setMapOption(data?.data);
        } catch (e) {
          setMapOption([]);
        }
      }
    }, 1200);

    return () => clearTimeout(delayDebounceFn);
  }, [searchMap]);

  return (
    <Wrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card>
          <Grid container marginLeft={0} spacing={2}>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'name' }}
                inputProps={{
                  fullWidth: true,
                }}
                size="medium"
                label={'Name'}
              />
            </Grid>
            <Grid item xs={5}>
              <AutoCompleteField<FormValues>
                controller={{ control, name: 'userId' }}
                options={getUsers || []}
                onOpen={() => setOpenUser(!openUser)}
                onClose={() => setOpenUser(!openUser)}
                callbackOnchange={(v) => {
                  dispatch(searchUsers(v));
                }}
                loading={valueUser.loading}
                label={t('banner_fields.userId')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'url' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.url,
                }}
                size="medium"
                label={'URL'}
              />
            </Grid>
            <Grid item xs={10}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="icon"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      // @ts-ignore
                      setValue('icon', event.currentTarget.files[0], {
                        shouldDirty: true,
                      });
                      setRefIcon(event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              {!isSvgImage && (
                <img
                  width={100}
                  height={100}
                  src={
                    refIcon
                      ? URL.createObjectURL(refIcon)
                      : `${process.env.REACT_APP_MEDIA_URL}${entity?.icon}`
                  }
                  loading="lazy"
                  onError={(e) => {
                    if (e.currentTarget.src) {
                      e.currentTarget.title = 'no image';
                    }
                  }}
                />
              )}

              {isSvgImage && (
                <svg
                  data-src={`${process.env.REACT_APP_MEDIA_URL}${entity?.icon}`}
                  fill="currentColor"
                  width="100px"
                  height="100px"
                />
              )}
            </Grid>
            <Grid item xs={5}>
              <SelectField<FormValues>
                controller={{ control, name: 'iconPath' }}
                options={map_icons}
                selectProps={{
                  defaultValue: entity?.icon,
                }}
                label={'Map icon'}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <InputField<FormValues>
                controller={{ control, name: 'offer' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.offer,
                }}
                label={t('ads_fields.offer')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <AutoCompleteField<FormValues>
                controller={{ control, name: 'hoodId' }}
                options={getHoods || []}
                onOpen={() => setOpenHood(!openHood)}
                callbackOnchange={(v) => {
                  dispatch(searchHoods(v));
                }}
                onClose={() => setOpenHood(!openHood)}
                loading={valueHood.loading}
                label={t('ads_fields.hood')}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField<FormValues>
                controller={{ control, name: 'category' }}
                options={category}
                selectProps={{
                  defaultValue: entity?.category,
                }}
                label={'Category'}
                size="medium"
              />
            </Grid>
            <Grid item xs={4}>
              <InputField<FormValues>
                controller={{ control, name: 'city' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.city,
                }}
                label={t('ads_fields.city')}
                size="medium"
              />
            </Grid>
            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'country' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.country,
                }}
                label={t('ads_fields.country')}
                size="medium"
              />
            </Grid>
            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'state' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.state || '',
                }}
                label={t('ads_fields.state')}
                size="medium"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectField<FormValues>
                controller={{ control, name: 'status' }}
                options={adsStatus}
                selectProps={{
                  defaultValue: entity?.status,
                }}
                label={t('ads_fields.status')}
                size="medium"
              />
            </Grid>
            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'latitude' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.latitude || '',
                }}
                label={'latitude'}
                size="medium"
              />
            </Grid>

            <Grid item xs={3}>
              <InputField<FormValues>
                controller={{ control, name: 'longitude' }}
                inputProps={{
                  fullWidth: true,
                  defaultValue: entity?.longitude || '',
                }}
                label={'longitude'}
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  name="photo"
                  onChange={(event) => {
                    if (
                      event &&
                      event.currentTarget &&
                      event.currentTarget.files
                    ) {
                      // @ts-ignore
                      setValue('photo', event.currentTarget.files[0], {
                        shouldDirty: true,
                      });
                      setRefPhoto(event.currentTarget.files[0]);
                    }
                  }}
                />
                <PhotoCamera />
              </IconButton>
              <img
                width={100}
                height={100}
                src={
                  refPhoto
                    ? URL.createObjectURL(refPhoto)
                    : entity?.photo
                    ? `${process.env.REACT_APP_MEDIA_URL}${entity?.photo}`
                    : 'https://placehold.jp/300x300.png'
                }
                loading="lazy"
                onError={(e) => {
                  if (e.currentTarget.src) {
                    e.currentTarget.title = 'no image';
                  }
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField<FormValues>
                controller={{ control, name: 'isSuperClub' }}
                options={confirm}
                selectProps={{
                  defaultValue: entity?.isSuperClub,
                }}
                label={'Super Club'}
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <SelectField<FormValues>
                controller={{ control, name: 'isLoyaltyTool' }}
                options={confirm}
                selectProps={{
                  defaultValue: entity?.isLoyaltyTool,
                }}
                label={'Loyalty Tool'}
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="button"
                variant="contained"
                size="small"
                disabled={!formState.isDirty}
                style={{ marginTop: 22 }}
                loading={formState.isSubmitting}
                loadingPosition="start"
                startIcon={<ModeIcon />}
                onClick={handleSubmit(onSubmit)}
              >
                {t('button.update')}
              </LoadingButton>

              <LoadingButton
                variant="contained"
                size="small"
                style={{ marginTop: 22, marginLeft: 10 }}
                onClick={history.back}
              >
                Back
              </LoadingButton>
              {_.isEmpty(entity?.CustomMap) && (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<LinkIcon />}
                  style={{ marginTop: 22, marginLeft: 10 }}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Link Custom Map
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid container marginLeft={0} spacing={2}>
            {entity?.CustomMap && (
              <>
                <Grid item xs={12}>
                  <h2>Custom Map</h2>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    <strong>Map Name: </strong>
                    {_.get(entity?.CustomMap, 'name')}
                  </p>
                  <p>
                    <strong>Link: </strong>
                    <a
                      href={getCustomMapWebUrl(entity?.CustomMap?.mapCode)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getCustomMapWebUrl(entity?.CustomMap?.mapCode)}
                    </a>
                  </p>
                  <p>
                    <strong>Allow Other Users:</strong>{' '}
                    {String(entity?.CustomMap?.allowOtherUsers)}
                  </p>
                  <p>
                    <strong>Show Only To Local:</strong>{' '}
                    {String(entity?.CustomMap?.showOnlyToLocal)}
                  </p>
                  <div>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      startIcon={<EditIcon />}
                      to={`/custom-maps/${entity?.CustomMap?.id}`}
                      component={Link}
                    >
                      Detail
                    </Button>

                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      startIcon={<LinkIcon />}
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        dispatch(
                          detachCustomMap({
                            linkId: entity?.id,
                            customMapId: entity?.customMapId,
                          }),
                        );
                      }}
                    >
                      Unlink
                    </Button>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      )}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
      >
        <DialogTitle>Link Custom Map</DialogTitle>
        <DialogContent>
          <Stack spacing={2} style={{ padding: 10 }}>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={mapOptions.map((option) => {
                return {
                  id: option.id,
                  label: option.name,
                };
              })}
              onChange={(e, v) => {
                setMapSelected(v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search custom map"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  onChange={(event) => {
                    setMapSelected(null);
                    setSearchMap(event.target.value);
                  }}
                />
              )}
            />
          </Stack>

          <LoadingButton
            type="button"
            variant="contained"
            size="small"
            disabled={!mapSelected}
            style={{ marginTop: 22 }}
            loadingPosition="start"
            startIcon={<LinkIcon />}
            onClick={() => {
              dispatch(
                attachCustomMap({
                  linkId: entity?.id,
                  customMapId: mapSelected.id,
                }),
              );
              setIsOpen(false);
              setMapSelected(null);
            }}
          >
            Link Custom Map
          </LoadingButton>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(!isOpen)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};

const Wrapper = styled(Grid)(
  ({ theme }) => `
    .MuiGrid-container {
      margin-top: 0;
    }
  `,
);

export default FormDetail;
