import HTTPRequest from '../index';

const apiPath = '/v1/hoods';
const apiSearchHoodPath = '/v1/hoods/search';

const DEFAULT_TAKE = '20';

class HoodRequest extends HTTPRequest {
  getList(params: Payload.HoodList) {
    return this.get<Response.HoodListResponse>(apiPath, { params });
  }

  getHoods() {
    return this.get<Response.HoodListResponse>(apiPath, {
      params: {
        take: DEFAULT_TAKE,
      },
    });
  }

  searchHoods(name: string = '') {
    if (name) {
      return this.get<Response.HoodListResponse>(apiSearchHoodPath, {
        params: {
          name,
        },
      });
    }

    return this.get<Response.HoodListResponse>(apiSearchHoodPath);
  }
}

export default new HoodRequest();
