import React from 'react';

import Main from './Main';

type MainProps = {
  entities: any[] | any;
};

const Container: React.FC<MainProps> = ({ entities }) => {
  return <Main entities={entities} />;
};

export default Container;
