import HTTPRequest from '../index';

const apiPath = '/v1/statistics';
const userAnalyticsApiPath = '/v1/users/analytics';
const hoodAnalyticsApiPath = '/v1/ads/hood/analytics';
const hoodAnalyticsDetailApiPath = '/v1/ads/hood/detail-analytics';

class StatisticsRequest extends HTTPRequest {
  getStatistics() {
    return this.get<Response.StatisticsEntity>(apiPath);
  }

  getUserAnalytics(params: Payload.UserAnalytics) {
    return this.get<Response.UserAnalyticsEntity[]>(userAnalyticsApiPath, {
      params,
    });
  }

  getHoodAnalytics(params: Payload.UserAnalytics) {
    return this.get<Response.HoodAnalyticsEntity[]>(hoodAnalyticsApiPath, {
      params,
    });
  }

  getHoodDetailAnalytics(params: Payload.UserAnalytics) {
    return this.get<any>(hoodAnalyticsDetailApiPath, {
      params,
    });
  }
}

export default new StatisticsRequest();
