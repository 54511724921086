import HTTPRequest from '../index';
import _ from 'lodash';

const apiPath = '/v1/custom-maps';

class CustomMapsRequest extends HTTPRequest {
  getList(params: any) {
    return this.get<any>(apiPath, { params });
  }

  create(data: any) {
    const formData = new FormData();
    formData.append('marker', data.marker);
    formData.append('name', data.name);
    formData.append('allowOtherUsers', data.allowOtherUsers);
    formData.append('showOnlyToLocal', data.showOnlyToLocal);
    formData.append('userId', data.userId);
    formData.append('hoodId', data.hoodId);
    formData.append('city', data.city);
    formData.append('country', data.country);
    formData.append('state', data.state);
    formData.append('status', data.status);
    return this.post(`${apiPath}/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  remove(id: string) {
    return this.delete(`${apiPath}/${id}`);
  }

  detail(id: string) {
    return this.get<any>(`${apiPath}/${id}`);
  }

  update(data: any) {
    const formData = new FormData();
    formData.append('marker', data.marker);
    formData.append('name', data.name);
    formData.append('allowOtherUsers', data.allowOtherUsers);
    formData.append('showOnlyToLocal', data.showOnlyToLocal);
    if (data?.hoodId?.id) {
      formData.append('hoodId', _.get(data, 'hoodId.id', null));
    }
    if (data?.userId?.id) {
      formData.append('userId', _.get(data, 'userId.id', null));
    }
    formData.append('city', data.city);
    formData.append('country', data.country);
    formData.append('state', data.state);
    formData.append('status', data.status);

    return this.put(`${apiPath}/${data.id}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new CustomMapsRequest();
