import React from 'react';
import Box from '@mui/material/Box';
import Select, { SelectProps } from '@mui/material/Select';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormHelperText, Grid, InputLabel, MenuItem } from '@mui/material';

type Props<T> = {
  selectProps?: SelectProps;
  controller: UseControllerProps<T>;
  label?: string;
  errorCol?: 12 | 24;
  options: {
    label: string | React.ReactElement;
    value: string | number;
  }[];
  size?: 'small' | 'medium';
};

function SelectField<T extends Record<string, any>>(props: Props<T>) {
  const { selectProps, controller, label, options, size = 'small' } = props;
  const { fieldState, field } = useController<T>(controller);
  const { error } = fieldState;
  return (
    <Box
      component="div"
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
    >
      <Grid container spacing={1}>
        {label && <InputLabel>{label}</InputLabel>}
        <Select
          id="component-error"
          style={{ width: '100%' }}
          {...field}
          {...selectProps}
          size={size}
        >
          {options.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
        {error && (
          <FormHelperText style={{ color: 'red' }}>
            {error.message}
          </FormHelperText>
        )}
      </Grid>
    </Box>
  );
}

export default SelectField;
