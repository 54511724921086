import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch } from 'src/store';
import { updateLink } from 'src/useCase/linkDetail';

export type FormValues = {
  name: string;
  url: string;
  category: string;
  icon: string;
  city: string;
  country: string;
  state: string;
  status: string;
  latitude: string | number;
  longitude: string | number;
  hoodId: { label: string; id: string } | any;
  iconPath: string;
  photo: string;
  offer: string;
  userId: { label: string; id: string } | any;
  isSuperClub: string;
  isLoyaltyTool: string;
};

type Data = Response.LinkDetailEntity | null;
type Hoods = Response.HoodEntity[];
type Users = Response.UserEntity[];

const useFormDetail = (data: Data, hoods: Hoods, users: Users) => {
  const [loadingHood, setLoadingHood] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getDefaultValueHood = useCallback(
    (data: any | null) => {
      const hood = hoods.find((v) => String(v.id) === data?.hoodId);
      if (!hood) return null;

      return {
        label: String(hood?.name),
        id: String(hood?.id),
      };
    },
    [hoods],
  );

  const getDefaultValueUser = useCallback(
    (data: any | null) => {
      const user = users.find((v) => String(v.id) === data?.userId);
      if (!user) return null;
      return {
        label: String(user?.nickName),
        id: String(user?.id),
      };
    },
    [users],
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validated.required', { name: 'Link Name' })),
    icon: Yup.string().required(t('validated.required', { name: 'Link Icon' })),
    category: Yup.string().required(
      t('validated.required', { name: 'Category' }),
    ),
    url: Yup.string()
      .required(t('validated.required', { name: 'Link URL' }))
      .url(),
    status: Yup.mixed().required(t('validated.required', { name: 'Status' })),
  });

  const form = useForm<FormValues>({
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!getDefaultValueHood(data)?.id) {
      setLoadingHood(true);
    }
    setLoadingHood(false);
  }, [data, hoods]);

  useEffect(() => {
    if (!getDefaultValueUser(data)?.id) {
      setLoadingUser(true);
    }
    setLoadingUser(false);
  }, [data, users]);

  const onSubmit: SubmitHandler<FormValues> = async (values: FormValues) => {
    const newHoodId = form.watch('hoodId') === null ? '' : values.hoodId.id;
    const newUserId = form.watch('userId') === null ? '' : values.userId.id;

    await dispatch(
      updateLink({
        id: data?.id as string,
        params: {
          name: values.name,
          url: values.url,
          category: values.category,
          icon: form.watch('icon'),
          status: values.status,
          hoodId: newHoodId,
          latitude: values.latitude as string,
          longitude: values.longitude as string,
          state: values.state,
          city: values.city,
          country: values.country,
          photo: values.photo,
          iconPath: values.iconPath,
          offer: values.offer,
          userId: newUserId,
          isSuperClub: values.isSuperClub,
          isLoyaltyTool: values.isLoyaltyTool,
        },
      }),
    );
  };

  useEffect(() => {
    const latitude =
      data?.serviceLocation !== null
        ? data?.serviceLocation.coordinates?.[1]
        : '';
    const longitude =
      data?.serviceLocation !== null
        ? data?.serviceLocation.coordinates?.[0]
        : '';

    const labelHood = data?.hood
      ? {
          label: data?.hood?.name,
          id: data?.hoodId,
        }
      : '';

    const labelUser = data?.user
      ? {
          label: data?.user?.nick_name,
          id: data?.userId,
        }
      : '';

    form.reset({
      name: data?.name || '',
      url: data?.url || '',
      city: data?.city || '',
      country: data?.country || '',
      state: data?.state || '',
      category: data?.category,
      icon: data?.icon || '',
      status: data?.status,
      hoodId: labelHood || '',
      latitude: latitude,
      longitude: longitude,
      photo: data?.photo || '',
      offer: data?.offer || '',
      userId: labelUser,
      isSuperClub: data?.isSuperClub || 'false',
      isLoyaltyTool: data?.isLoyaltyTool || 'false',
    });
  }, [data]);

  return { form, onSubmit, loadingHood, loadingUser };
};

export default useFormDetail;
