import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import useTable from 'src/hooks/useTable';
import { Card } from 'src/components/common';
import { LinearProgress } from '@mui/material';
import { formatDate } from 'src/utils/helpers';
import { DATE_TIME } from 'src/constants/app';

type Props = {
  loading: boolean;
  entities: Response.ReportEntity[];
};

const UserTable: React.FC<Props> = ({ entities, loading }) => {
  const { tableProps } = useTable();
  const { t } = useTranslation();

  const dataSource = entities.map((entity, index) => {
    return {
      id: index,
      content: entity?.post?.content === '' ? '-' : entity?.post?.content,
      ...entity,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 400,
    },
    {
      field: 'searchString',
      headerName: 'Keyword',
      sortable: false,
      minWidth: 300,
    },
    {
      field: 'Hood',
      headerName: 'Hood Name',
      sortable: false,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams) => {
        return <div>{params.row.hood && params.row.hood?.name}</div>;
      },
    },
    {
      field: 'createdAt',
      headerName: t('createdAt'),
      sortable: false,
      width: 150,
      valueGetter: ({ value }) => {
        return formatDate(value, DATE_TIME) || '-';
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={dataSource}
          columns={columns}
          paginationMode={'server'}
          loading={loading}
          disableColumnMenu={true}
          disableColumnSelector={true}
          {...tableProps}
        />
      </div>
    </Card>
  );
};

export default UserTable;
