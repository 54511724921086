import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { useAppDispatch } from 'src/store';
import { createResource } from 'src/useCase/ads';
import history from 'src/libs/history';
import { PATH } from 'src/pages/ads/AdsList';

export type FormValue = {
  title: string;
  content: string;
  language: string;
  buttonText: string;
  url: string;
  dateFrom: string;
  dateTo: string;
  hoodId: string;
  city: string;
  country: string;
  state: string;
  hoodCategory: string;
  bannerMobileLeaderBoard: string;
  bannerWebLeaderBoard: string;
  bannerLargeLeaderBoard: string;
  spotlightImage: string;
  status: string;
  officeName: string;
  featureText: string;
  tags: [];
};

type Category = string;

const useForm = (category: Category) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const defaultValue: FormValue = {
    title: '',
    content: '',
    language: '',
    buttonText: '',
    url: '',
    dateFrom: new Date().toDateString(),
    dateTo: new Date().toDateString(),
    hoodId: '',
    city: '',
    country: '',
    state: '',
    hoodCategory: '',
    bannerMobileLeaderBoard: '',
    bannerWebLeaderBoard: '',
    bannerLargeLeaderBoard: '',
    spotlightImage: '',
    status: '',
    officeName: '',
    featureText: '',
    tags: [],
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(
      t('validated.required', { name: t('ads_fields.title') }),
    ),
    content: Yup.string().required(
      t('validated.required', { name: t('ads_fields.content') }),
    ),
    buttonText: Yup.string().required(
      t('validated.required', { name: t('ads_fields.buttonText') }),
    ),
    url: Yup.string()
      .required(t('validated.required', { name: t('ads_fields.url') }))
      .url(),
    dateFrom: Yup.date().required(
      t('validated.required', { name: t('ads_fields.dateFrom') }),
    ),
    dateTo: Yup.date()
      .required(t('validated.required', { name: t('ads_fields.dateTo') }))
      .when('dateFrom', (dateFrom, schema) => dateFrom && schema.min(dateFrom)),
    bannerMobileLeaderBoard: Yup.mixed().required(
      t('validated.required', { name: t('ads_fields.banner') }),
    ),
    bannerWebLeaderBoard: Yup.mixed().required(
      t('validated.required', { name: t('ads_fields.banner') }),
    ),
    // bannerLargeLeaderBoard: Yup.mixed().required(
    //   t('validated.required', { name: t('ads_fields.banner') }),
    // ),
    status: Yup.mixed().required(
      t('validated.required', { name: t('ads_fields.status') }),
    ),
  });

  const formik = useFormik<FormValue>({
    initialValues: {
      ...defaultValue,
    },
    validationSchema,
    async onSubmit(values) {
      await dispatch(createResource({ ...values, hoodCategory: category }));
      history.push(PATH);
    },
    validateOnChange: true,
  });

  return { formik };
};

export default useForm;
